import React from 'react';

import { logScreen } from 'core/analytics';
import { Button } from 'core/components/Button';
import i18n from 'core/i18n';
import { IInvitation } from 'core/stores/invitation';
import '../styles.scss';

interface Props {
  onAccept(): void;
  invitation: IInvitation;
}

class ShowInvitation extends React.Component<Props, {}> {
  componentDidMount() {
    logScreen('INVITATION');
  }

  render() {
    const {
      invitation: { enrollment, user, unitName },
      onAccept,
    } = this.props;

    if (enrollment && user) {
      return (
        <div className="show-invitation">
          <p>
            {i18n.t('auth.invite.main.intro', {
              firstname: user.firstname,
              lastname: user.lastname,
            })}
          </p>
          <p>{i18n.t('auth.invite.main.body', { unit: unitName })}</p>
          <ul>
            {enrollment.roles.map((r, idx) => (
              <li key={idx}>{i18n.t(`roles.${r}`)}</li>
            ))}
          </ul>
          <Button block content={i18n.t('auth.invite.main.button')} onClick={onAccept} />
        </div>
      );
    }

    return null;
  }
}

export default ShowInvitation;
