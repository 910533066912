import maxDate from 'date-fns/max';
import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import { IStore } from 'core/stores';
import { IEnrollmentUnit, IUnit } from 'core/stores/data/units/unit';

import { Enrollment, IEnrollment } from './enrollment';

export const Enrollments = types
  .model('Enrollments', {
    enrollments: types.array(Enrollment),
    initialized: false,
    initializing: false,
  })
  .views(self => ({
    findUnit(id: string): IEnrollmentUnit | undefined {
      const enrollment = self.enrollments.find(e => e.unit.id === id);
      return enrollment?.unit;
    },
    get mostRecentUpdate(): Date | null {
      return maxDate(self.enrollments.map((e: IEnrollment) => e.updatedAt)) || null;
    },
  }))
  .actions(self => ({
    initialize: flow(function* initialize() {
      self.initializing = true;
      const { api } = getEnv(self);
      const { auth } = getRoot<IStore>(self);
      const units = Array.from(getRoot<IStore>(self).data.units.units.values()).map((u: IUnit) => u.id);
      self.enrollments = yield api.enrollments.getUserEnrollments(auth.user.id, units);
      self.initializing = false;
      self.initialized = true;
    }),
    refresh: flow(function* refresh() {
      const { api } = getEnv(self);
      const { auth } = getRoot<IStore>(self);
      const units = Array.from(getRoot<IStore>(self).data.units.units.values()).map((u: IUnit) => u.id);
      const enrollments = yield api.enrollments.getUserEnrollments(auth.user.id, units, self.mostRecentUpdate);
      enrollments.forEach((enrollment: IEnrollment) => {
        const enrollmentIndex = self.enrollments.findIndex((e: IEnrollment) => e.id === enrollment.id);
        if (enrollmentIndex >= 0) {
          self.enrollments[enrollmentIndex] = enrollment;
        } else {
          self.enrollments.push(enrollment);
        }
      });
    }),
    addEnrollment(enrollmentProps: IEnrollment): void {
      self.enrollments.push(enrollmentProps);
    },
  }));

export interface IEnrollmentsStore extends Instance<typeof Enrollments> {}
