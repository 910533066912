import { getEnv, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { Report } from './report';

export const RecordingReport = types
  .compose(
    'RecordingReport',
    Report,
    types.model({
      recording: types.string,
    }),
  )
  .views(self => ({
    get api() {
      return getEnv(self).api.reports.recordingReports;
    },
  }));

export interface IRecordingReport extends Instance<typeof RecordingReport> {}
export interface IRecordingReportSnapshotIn extends SnapshotIn<typeof RecordingReport> {}
export interface IRecordingReportSnapshotOut extends SnapshotOut<typeof RecordingReport> {}
