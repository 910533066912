import cn from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import Select from 'react-select';

import { getScopedI18n } from 'core/i18n';
import { reactSelectTheme } from 'core/theme';

const tlabel = getScopedI18n('input_labels');
const tmonth = getScopedI18n('months');
const tgeneral = getScopedI18n('general');

const monthOptions = [
  {
    value: '01',
    label: tmonth('jan'),
  },
  {
    value: '02',
    label: tmonth('feb'),
  },
  {
    value: '03',
    label: tmonth('mar'),
  },
  {
    value: '04',
    label: tmonth('apr'),
  },
  {
    value: '05',
    label: tmonth('may'),
  },
  {
    value: '06',
    label: tmonth('jun'),
  },
  {
    value: '07',
    label: tmonth('jul'),
  },
  {
    value: '08',
    label: tmonth('aug'),
  },
  {
    value: '09',
    label: tmonth('sep'),
  },
  {
    value: '10',
    label: tmonth('oct'),
  },
  {
    value: '11',
    label: tmonth('nov'),
  },
  {
    value: '12',
    label: tmonth('dec'),
  },
];

interface Props {
  asCol?: boolean;
  name?: string;
  value?: Date;
  required?: boolean;
  isInvalid: boolean;
  label?: string;
  onChange: (e: any) => void;
}

@observer
export class BirthdayInputControlled extends React.Component<Props, {}> {
  @observable
  observables = {
    day: undefined,
    month: undefined,
    year: undefined,
  };

  componentDidMount() {
    if (this.props.value) {
      const momentObject = moment.utc(this.props.value);
      this.observables.day = `${momentObject.date()}`;
      this.observables.month = momentObject.format('MM');
      this.observables.year = `${momentObject.year()}`;
    }
  }

  handleChange = (type: 'year' | 'month' | 'day', value) => {
    this.observables[type] = value;
    const { day, month, year } = this.observables;
    let targetValue = null;
    if (day && month && year) {
      targetValue = moment
        .utc({ year: parseInt(year), month: parseInt(month) - 1, day: parseInt(day), hour: 0, minute: 0, second: 0 })
        .toDate();
    }
    this.props.onChange({
      target: {
        name: this.props.name,
        type: 'text',
        value: targetValue,
      },
    });
  };

  render() {
    const { asCol, required, isInvalid, label } = this.props;
    const { year, month, day } = this.observables;

    return (
      <div className={cn({ col: asCol })}>
        <label className="form-label">
          {label || tlabel('birthday_and_format')}
          {required ? ' *' : null}
        </label>
        <div className="date-input">
          <input
            className={cn('date-input-year form-control', { 'is-invalid': isInvalid })}
            type="text"
            placeholder={tgeneral('year')}
            maxLength={4}
            size={Math.max(4, tgeneral('year').length)}
            value={year}
            onChange={(e: any) => {
              this.handleChange('year', e.target.value.replace(/[^0-9]/gm, ''));
            }}
          />
          <Select
            className={cn('date-input-month', { 'is-invalid': isInvalid })}
            placeholder={tgeneral('month')}
            styles={{
              control: provided => ({
                ...provided,
                'border-radius': 0,
              }),
            }}
            theme={reactSelectTheme}
            value={monthOptions.find(m => m.value === month)}
            options={monthOptions}
            onChange={(data: any) => {
              this.handleChange('month', data.value);
            }}
            error={isInvalid && !month}
          />
          <input
            className={cn('date-input-day form-control', { 'is-invalid': isInvalid })}
            type="text"
            placeholder={tgeneral('day')}
            maxLength={2}
            size={Math.max(2, tgeneral('day').length)}
            value={day}
            onChange={(e: any) => {
              this.handleChange('day', e.target.value.replace(/[^0-9]/gm, ''));
            }}
          />
        </div>
      </div>
    );
  }
}
