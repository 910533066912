import React from 'react';
import { RouteComponentProps } from 'react-router';

import { getScopedI18n, getSurveyLanguage } from 'core/i18n';

interface Props extends RouteComponentProps {}

export const Finish: React.FC<Props> = ({ location, history }) => {
  const t = getScopedI18n('survey_assessment.finish', { locale: getSurveyLanguage() });

  return (
    <div className="assessment_questionaire main container">
      <div className="row mt-5">
        <h1 className="col">{t('title')}</h1>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>{t('subtitle')}</h5>
          <button
            className="btn btn-primary btn-lg mt-4"
            onClick={() => {
              window.location.replace('/survey/finish');
            }}
          >
            {t('button')}
          </button>
        </div>
      </div>
    </div>
  );
};
