import { upperCase } from 'lodash';
import moment from 'moment';

import { IRecordingSnapshotIn } from 'core/stores/data/recordings/recording';

export function convertRecording(recording: RecordingAPI): IRecordingSnapshotIn {
  const endMoment = moment(recording.end, moment.ISO_8601);
  return {
    id: recording._id,
    sequenceNumber: recording.sequence_number,
    comment: recording.comment || '',
    // @ts-ignore
    status: upperCase(recording.status),
    updatedAt: moment(recording.modified_at, moment.ISO_8601).toDate(),
    date: endMoment.isValid() ? endMoment.toDate() : null,
    dateUtcOffset: recording.end_utc_offset,
  };
}
