import moment from 'moment';

import { ITestSnapshotIn } from 'core/stores/data/tests/test';

import { convertPatientBirthday } from '../util.api';

import { convertRecording } from './recordingTransformer';

export function convertTest(test: TestAPI): ITestSnapshotIn {
  return {
    id: test._id,
    patientId: test.patient._id,
    patientFirstname: test.patient.first_name??'',
    patientLastname: test.patient.last_name??'',
    patientEmail: test.patient.email,
    patientBirthday: convertPatientBirthday(test.patient.dob),
    patientPhone: test.patient.phone,
    numberOfRecordings: test.number_of_recordings,
    unitId: test.unit?._id || test.unit,
    recordings: test.recordings.map(convertRecording).reverse(),
    // offline: test.offline,
    updatedAt: moment(test.modified_at, moment.ISO_8601).toDate(),
    createdAt: moment(test.created_at, moment.ISO_8601).toDate(),
    isResmedAustraliaTest: (test.custom_flags && test.custom_flags.resmed_australia) || test.service === 'RESMED_AUS',
    isResmedFranceTest: (test.custom_flags && test.custom_flags.resmed_france) || test.service === 'RESMED_FR',
  };
}
