import i18n from 'i18n-js';
import moment from 'moment';
import uuid from 'uuid/v4';
import 'moment/locale/fr';
import 'moment/locale/de';

import de from './locales/de';
import en from './locales/en';
import es from './locales/es';
import fr from './locales/fr';
import nl from './locales/nl';
import pt from './locales/pt';

i18n.translations = {
  de,
  en,
  fr,
  nl,
  pt,
  es,
};

i18n.defaultLocale = 'en';

const supportedSurveyLocales = ['en', 'nl'];

export const getSurveyLanguage = () => {
  const browserLanguage = getBrowserLanguage();
  if (supportedSurveyLocales.includes(browserLanguage)) {
    return browserLanguage;
  }
  return 'en';
};

export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  if (browserLanguage) {
    return browserLanguage.substring(0, 2);
  }
  return 'en';
};

const onLanguageChangeSubscriptions: { [key: string]: (newLocale: string, oldLocale: string) => void } = {};

function notifyLanguageChange(newLocale: string, oldLocale: string) {
  for (const sub of Object.values(onLanguageChangeSubscriptions)) {
    sub(newLocale, oldLocale);
  }
}

export function setLanguage(newLocale: string, withoutNotification = false) {
  const slicedLocale = newLocale.substring(0, 2);
  if (SUPPORTED_DASHBOARD_LOCALES()[slicedLocale]) {
    const oldLocale = i18n.currentLocale();
    i18n.locale = slicedLocale;
    moment.locale(newLocale);
    if (!withoutNotification && oldLocale !== newLocale) {
      notifyLanguageChange(slicedLocale, oldLocale);
    }
  }
}

export function onLanguageChange(fn: (newLocale: string, oldLocale: string) => void) {
  const key = uuid();
  onLanguageChangeSubscriptions[key] = fn;
  return {
    key,
    remove: () => {
      delete onLanguageChangeSubscriptions[key];
    },
  };
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getScopedI18n(scope: string, defaultOptions: any = {}) {
  return (key: string, options = {}) => i18n.t(`${scope}.${key}`, { ...defaultOptions, ...options });
}

export const tgeneral = getScopedI18n('general');
export const tlabels = getScopedI18n('input_labels');
export const tvalid = getScopedI18n('validation_errors');

export const SUPPORTED_SURVEY_EVALUATION_LOCALES = (): { [key: string]: Language } => {
  return {
    en: {
      value: 'en',
      label: i18n.t('locales.english'),
    },
    nl: {
      value: 'nl',
      label: i18n.t('locales.dutch'),
    },
  };
};

export const SUPPORTED_DOCUMENTS_LOCALES = (): { [key: string]: Language } => {
  return {
    de: {
      value: 'de',
      label: i18n.t('locales.german'),
    },
    en: {
      value: 'en',
      label: i18n.t('locales.english'),
    },
    fr: {
      value: 'fr',
      label: i18n.t('locales.french'),
    },
    nl: {
      value: 'nl',
      label: i18n.t('locales.dutch'),
    },
    pt: {
      value: 'pt',
      label: i18n.t('locales.portuguese'),
    },
    es: {
      value: 'es',
      label: i18n.t('locales.spanish'),
    },
  };
};

export const SUPPORTED_DASHBOARD_LOCALES = (): { [key: string]: Language } => {
  return {
    de: {
      value: 'de',
      label: i18n.t('locales.german'),
    },
    en: {
      value: 'en',
      label: i18n.t('locales.english'),
    },
    fr: {
      value: 'fr',
      label: i18n.t('locales.french'),
    },
  };
};

// @ts-ignore
const language = window.navigator.userLanguage || window.navigator.language;
if (language) {
  setLanguage(language);
}

export default i18n;
