import { omit } from 'lodash';

import { captureException } from 'core/analytics';
import { IEnrollment } from 'core/stores/data/enrollments/enrollment';
import { IActivationCodeSnapshotIn, ITest, ITestSnapshotOut } from 'core/stores/data/tests/test';
import { IUnit } from 'core/stores/data/units/unit';
import { mapObjectKeys } from 'core/util/objects';

import { apiInstance } from './axios';
import { convertActivationCode } from './transformers/activationCodeTransformer';
import { convertTest } from './transformers/testTransformer';
import { convertToPatientBirthday } from './util.api';

// Used for updating. Does not contain unit and recording information
function convertLocalTestToBackend(scr: ITestSnapshotOut): any {
  return {
    number_of_recordings: scr.numberOfRecordings,
  };
}

function convertLocalPatientToBackend(scr: ITestSnapshotOut): any {
  return {
    first_name: scr.patientFirstname,
    last_name: scr.patientLastname,
    dob: convertToPatientBirthday(scr.patientBirthday),
    email: scr.patientEmail,
    phone: scr.patientPhone,
  };
}

export function sendMail(test: ITest, language: string) {
  return apiInstance.post(`activation/resend/${test.id}`, { language });
}

export function fetchActivationCode(test: ITest): Promise<IActivationCodeSnapshotIn> {
  return apiInstance
    .get('activation/find', {
      params: {
        query: JSON.stringify({
          test: test.id,
        }),
      },
    })
    .then(res => {
      if (res.data.AC.length === 0) {
        throw new Error(`No activation code found for test ${test.id}`);
      }
      return convertActivationCode(res.data.AC[0]);
    });
}

export function getTests(units?: string[], updatedSince: Date = null, query = {}, filter = {}, sort = { recent_recording_time: -1 }) {
  return apiInstance
    .get<ListTestAPI>('tests', {
      params: {
        with_related: true,
        query: {
          // unit: { $in: units },
          deleted_at: null,
          // removed modified_at from the query and added modified_at to sort property
          // modified_at: updatedSince ? { $gt: updatedSince } : undefined,
          ...filter,
          // recordings: { $gt: 1 },
        },
        // sort: { created_at: -1 },
        sort,
        page: 1,
        page_size: 50,
        ...query,
      },
    })
    .then(res => {
      return res.data.tests.map(convertTest);
    });
}

export function getTest(id: string) {
  return apiInstance
    .get<ListTestAPI>(`tests/${id}`)
    .then(res => {
      return res.data.test;
    })
    .catch(err => ({
      error: true,
    }));
}

export function archive(id: string): Promise<any> {
  return apiInstance.patch(`tests/${id}`, { deleted_at: new Date() });
}

export async function update(newTest: ITestSnapshotOut) {
  const patientResponse = await apiInstance.patch(`patients/${newTest.patientId}`, convertLocalPatientToBackend(newTest));
  const testResponse = await apiInstance.patch<createTestAPI>(`tests/${newTest.id}`, convertLocalTestToBackend(newTest));
  return omit(
    convertTest({
      ...testResponse.data.test,
      patient: {
        ...patientResponse.data.patient,
      },
    }),
    ['recordings', 'id', 'patientId', 'unitId', 'recordings'],
  );
}

export function createActivationCode(email: string, unit: IUnit, enrollment: IEnrollment, meta: CreateTestMeta): Promise<any> {
  return apiInstance
    .post<PostActivationCodeAPI>('activation', {
      email,
      enrollment: enrollment.id,
      unit: unit.id,
      fulfillment: meta.fulfillment,
      language: meta.language,
      number_of_recordings: meta.recordings,
    })
    .then(response => response.data.activation_code);
}

export function createSimpleTest(
  email: string,
  fulfillment: boolean,
  nights: number,
  unit: IUnit,
  enrollment: IEnrollment,
  language: string,
  config: any,
): Promise<any> {
  const params = {
    email,
    fulfillment,
    language,
    enrollment: enrollment.id,
    unit: unit.id,
    number_of_recordings: nights,
    configs: config,
    activation_type: 'Email'
  };
  return apiInstance.post('activation', params);
}

export function createTest(
  unit: IUnit,
  enrollment: IEnrollment,
  offline: boolean,
  numberOfRecordings: number,
  language: string,
  patient: PatientInfo,
  sensor_type: any,
  allowed_extensions: any,
  diagnostic_nights_needed: any,
  surveys,
  // consents_requested,
  fulfillment,
  address?: Address,
  protocol?: any,
): Promise<any> {
  const testProps = {
    offline,
    language,
    number_of_recordings: numberOfRecordings,
    unit: unit.id,
    enrollment: enrollment.id,
    sensor_type,
    allowed_extensions,
    surveys,
    // consents_requested,
    fulfillment,
    diagnostic_nights_needed,
    protocol,
    activation_type: 'Email'
  };

  const params = {
    patient: { ...mapObjectKeys(patient, { firstname: 'first_name', lastname: 'last_name' }), address },
    ...testProps,
  };

  return apiInstance.post<createTestAPI>('tests', params).then(response => {
    try {
      return Promise.resolve(convertTest(response.data.test));
    } catch (e) {
      captureException(e);
      return Promise.reject(new Error('Could not create test'));
    }
  });
}

export function mailReport(testId: id, email: string): Promise<any> {
  return apiInstance.post<createTestAPI>('resmed/patient/report', {
    test_id: testId,
    email,
  });
}

export async function downloadEDF(recordingId: id): Promise<any> {
  const result = await apiInstance.get(`edf_exports?recording_id=${recordingId}`);
  return apiInstance.get<FileAPI>(`files/url/${result.data.edfExport.file}`).then(op => op.data.file_url);
}
