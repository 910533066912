import { brandPrimary } from './colors';

export const reactSelectTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: brandPrimary,
    primary25: '#CCEEE8',
  },
});
