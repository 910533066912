import { apiInstance } from './axios';
import { convertEnrollment } from './transformers/enrollmentTransformer';
import { convertUnit } from './transformers/unitTransformer';
import { convertUser } from './transformers/userTransformer';

export function load(enrollment: id, token: string) {
  return apiInstance.get(`enrollments/activate/${enrollment}/${token}`)
    .then((response: any) => {
      return {
        passwordToken: response.data['password_reset_token'],
        user: convertUser(response.data.user),
        enrollment: convertEnrollment(response.data.enrollment),
        unit: convertUnit(response.data.unit),
      };
    });
}

export function accept(enrollment: id, token: string): Promise<any> {
  return apiInstance.patch(`enrollments/activate/${enrollment}/${token}`);
}

// tslint:disable: variable-name
export function activate(
  token: string,
  new_password: string,
  confirm_password: string,
): Promise<any> {
  return apiInstance
    .post('users/password/reset', { new_password, confirm_password, token });
}
