import { some } from 'lodash';
import { flow, getEnv, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { captureException, logEvent } from 'core/analytics';

import { EnrollmentUnit } from '../units/unit';
import { User } from '../users/user';

export const Enrollment = types
  .model('Enrollment', {
    id: types.identifier,
    user: types.maybe(types.compose(User, types.model({}))),
    unit: EnrollmentUnit,
    status: types.enumeration(['active', 'invited', 'disabled']),
    roles: types.array(types.enumeration(['PHYSICIAN', 'ADMIN'])),
    createdAt: types.Date,
    acceptedAt: types.maybeNull(types.Date),
    fees: types.number,
    code: types.maybe(types.string),
    updatedAt: types.Date,
    error: '',
  })
  .views(self => ({
    get isAdmin(): boolean {
      return some(self.roles, r => r === 'ADMIN');
    },
    get isPhysician(): boolean {
      return some(self.roles, r => r === 'PHYSICIAN');
    },
    get firstname(): string {
      return self.user?.firstname || '';
    },
    get lastname(): string {
      return self.user?.lastname || '';
    },
    get email(): string {
      return self.user?.email || '';
    },
  }))
  .actions(self => ({
    toggleStatus: flow(function* toggleStatus() {
      const currentStatus = self.status;
      if (currentStatus === 'invited') {
        self.error = 'Can not change the status of an invited member';
        return;
      }
      let newStatus: typeof self.status = 'disabled';
      if (self.status === 'disabled') {
        newStatus = 'active';
      }
      logEvent('ENROLLMENT_TOGGLE_STATUS', { new_status: newStatus, old_status: self.status });

      self.status = newStatus;

      try {
        const { api } = getEnv(self);
        yield api.enrollments.setStatus(self.id, self.status);
        self.error = '';
      } catch (err) {
        captureException(err);
        if (newStatus === 'disabled') {
          self.error = 'Error while disabling member';
        } else {
          self.error = 'Error while reactivating member';
        }
        logEvent('ENROLLMENT_TOGGLE_STATUS_ERROR', { error: self.error });
        self.status = currentStatus;
      }
    }),
    resendInvite: flow(function* resendInvite() {
      try {
        const { api } = getEnv(self);
        yield api.enrollments.resendInvite(self.id);
        self.error = '';
      } catch (err) {
        captureException(err);
        self.error = 'Error while resending invite';
        logEvent('ENROLLMENT_RESEND_INVITE_ERROR', { error: self.error });
      }
    }),
  }));

export interface IEnrollment extends Instance<typeof Enrollment> {}
export interface IEnrollmentSnapshotIn extends SnapshotIn<typeof Enrollment> {}
export interface IEnrollmentSnapshotOut extends SnapshotOut<typeof Enrollment> {}
