import * as Sentry from '@sentry/browser';
import React from 'react';
import { Button, Container } from 'react-bootstrap';

import { captureException } from 'core/analytics';

interface State {
  hasError: boolean;
  eventId: string;
}

export class ErrorBoundary extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <h1>Something went wrong</h1>
          <p>Try reloading the page. The Ectosense team has been notified of this problem.</p>
          {this.state.eventId && <Button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button>}
        </Container>
      );
    }
    return this.props.children;
  }
}
