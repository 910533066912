import { compact } from 'lodash';

import { convertRecordingReport, convertTestReport } from 'api/transformers/reportTransformer';

import { apiInstance } from './axios';

export function getReportForRecording(recording: id) {
  return apiInstance.get(`reports/recording/${recording}`).then(res => {
    if (res.data.report) {
      return convertRecordingReport(res.data.report);
    }
    return null;
  });
}

export function fetchRecordingReports(query, sort, page, page_size) {
  return apiInstance
    .get('reports/pdf', {
      params: {
        query,
        page,
        page_size,
        sort,
      },
    })
    .then(res => {
      return res.data.reports.map(convertRecordingReport);
    });
}

export function getReportForTest(test: id) {
  return apiInstance.get(`reports/test/${test}`).then(res => {
    if (res.data.report) {
      return convertTestReport(res.data.report);
    }
    return null;
  });
}

export function fetchTestReports(query, sort, page, page_size) {
  return apiInstance
    .get('reports/summary', {
      params: {
        query,
        page,
        page_size,
        sort,
      },
    })
    .then(res => {
      return compact(res.data.reports.map(convertTestReport));
    });
}

function file(f: string): Promise<url> {
  return apiInstance.get<FileAPI>(`files/url/${f}`).then(result => result.data.file_url);
}

function markReadGenerator(subject: string) {
  return function markRead(id: id) {
    return apiInstance.patch(`reports/${subject}/${id}`, { read: true });
  };
}

export const recordingReports = {
  file,
  markRead: markReadGenerator('pdf'),
};

export const testReports = {
  file,
  markRead: markReadGenerator('summary'),
};
