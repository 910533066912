import { compareAsc } from 'date-fns';
import { getParent, getRoot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { FILTER_STATES } from 'containers/Dashboard/constants';
import { ITest } from 'core/stores/data/tests/test';
import { IStore } from 'core/stores/index';

const PAGE_SIZE = 50;

export const ResultsUIStore = types
  .model('ResultsUIStore', {
    page: 0,
    currentSort: '',
    fieldSort: '',
    currentNameFilter: '',
    currentUnitFilter: 'all',
    currentStateFilter: FILTER_STATES.ALL.value,
  })
  .views(self => ({
    get sortedFilteredTests(): ITest[] {
      return Array.from(getRoot<IStore>(self).data.tests.tests.values())
        .filter((s: ITest) => {
          if (self.currentUnitFilter !== 'all' && s.unit && s.unit.id !== self.currentUnitFilter) {
            return false;
          }

          if (self.currentUnitFilter === 'all' && s.unit && s.unit.id !== self.currentUnitFilter) {
            const { auth } = getRoot<IStore>(self);
            if (auth.user && auth.user.enrollmentsPhysician && !auth.user.enrollmentsPhysician.map(e => e.unit.id).includes(s.unit.id)) {
              return false;
            }
          }

          return s.fullName.toLowerCase().includes(self.currentNameFilter.toLowerCase());
        })
        .sort((first: ITest, second: ITest) => {
          let sortReturn = 1;
          if (self.currentSort === 'desc') {
            sortReturn = -1;
          }

          if (self.fieldSort === 'name') {
            if (first.patientLastname.toLowerCase() > second.patientLastname.toLowerCase()) {
              return sortReturn;
            }
            if (first.patientLastname.toLowerCase() === second.patientLastname.toLowerCase()) {
              if (first.patientFirstname.toLowerCase() >= second.patientFirstname.toLowerCase()) {
                return sortReturn;
              }
              return sortReturn * -1;
            }
            return sortReturn * -1;
          }
          if (self.fieldSort === 'date') {
            if (first.completedNights === 0 && second.completedNights !== 0) {
              return 1;
            }
            if (first.completedNights !== 0 && second.completedNights === 0) {
              return -1;
            }
            if (first.completedNights === 0 && second.completedNights === 0) {
              return sortReturn * compareAsc(first.createdAt || new Date(0), second.createdAt || new Date(0));
            }
            return sortReturn * compareAsc(first.lastRecordingDate || new Date(0), second.lastRecordingDate || new Date(0));
          }

          return sortReturn;
        });
    },
  }))
  .views(self => ({
    get totalPages(): number {
      return Math.ceil(self.sortedFilteredTests.length / PAGE_SIZE);
    },
    get pending(): ITest[] {
      return self.sortedFilteredTests.filter(s => s.status === 'IN_PROGRESS');
    },
    get analysed(): ITest[] {
      return self.sortedFilteredTests.filter(s => s.status === 'COMPLETED');
    },
    get newTests(): ITest[] {
      return self.sortedFilteredTests
        .filter(s => s.status === 'NEW')
        .sort((first, second) => {
          let sortReturn = 1;
          if (self.currentSort === 'desc') {
            sortReturn = -1;
          }

          if (self.fieldSort === 'date') {
            return sortReturn * compareAsc(first.createdAt, second.createdAt);
          }

          return sortReturn;
        });
    },
    get unreadCount(): number {
      return self.sortedFilteredTests.reduce((sum, test) => sum + test.unreadCount, 0);
    },
    get pendingUnreadCount(): number {
      return this.pending.reduce((sum, test) => sum + test.unreadCount, 0);
    },
    get analysedUnreadCount(): number {
      return this.analysed.reduce((sum, test) => sum + test.unreadCount, 0);
    },
    get newUnreadCount(): number {
      return this.newTests.reduce((sum, test) => sum + test.unreadCount, 0);
    },
    get visibleTests(): ITest[] {
      let testsInState = this.sortedFilteredTests;
      switch (self.currentStateFilter) {
        case FILTER_STATES.IN_PROGRESS.value:
          testsInState = this.pending;
          break;
        case FILTER_STATES.COMPLETED.value:
          testsInState = this.analysed;
          break;
        case FILTER_STATES.NEW.value:
          testsInState = this.newTests;
          break;
        case FILTER_STATES.ALL.value:
        default:
          testsInState = this.sortedFilteredTests;
      }
      return testsInState.slice(PAGE_SIZE * self.page, PAGE_SIZE * (self.page + 1));
    },
  }))
  .actions(self => ({
    goToPage: (page: number): void => {
      self.page = page;
    },
    toggleNameSort: (): void => {
      self.fieldSort = 'name';
      if (self.currentSort === 'desc') {
        self.currentSort = 'asc';
      } else {
        self.currentSort = 'desc';
      }
    },
    toggleDateSort: (): void => {
      self.fieldSort = 'date';
      if (self.currentSort === 'desc') {
        self.currentSort = 'asc';
      } else {
        self.currentSort = 'desc';
      }
    },
    setNameFilter: (filter: string): void => {
      self.page = 0;
      self.currentNameFilter = filter;
    },
    setUnitFilter: (filter: string): void => {
      self.currentUnitFilter = filter;
      self.page = 0;
    },
    setStateFilter: (filter: string): void => {
      self.currentStateFilter = filter;
      self.page = 0;
    },
  }));

export interface IResultsUI extends Instance<typeof ResultsUIStore> {}
export interface IResultsUISnapshotIn extends SnapshotIn<typeof ResultsUIStore> {}
export interface IResultsUISnapshotOut extends SnapshotOut<typeof ResultsUIStore> {}
