import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import moment from 'moment';

export const User = types
  .model('User', {
    id: types.identifier,
    firstname: types.string,
    lastname: types.string,
    email: types.string,
    updatedAt: types.Date,
    createdAt: types.Date,
    passwordUpdatedAt: types.Date,
  })
  .views(self => ({
    get name(): string {
      return `${self.firstname} ${self.lastname}`;
    },
    get isNewUser(): boolean {
      return moment(self.createdAt).isSame(self.passwordUpdatedAt, 'm');
    },
  }));

export interface IUser extends Instance<typeof User> {}
export interface IUserSnapshotIn extends SnapshotIn<typeof User> {}
export interface IUserSnapshotOut extends SnapshotOut<typeof User> {}
