import { getScopedI18n } from '../i18n';

const t = getScopedI18n('password.requirements');

export function isValidPassword(password: string): boolean {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}/.test(password);
}

const numCharacters = 8;
const numLowercase = 1;
const numUppercase = 1;
const numNumber = 1;

export const getPasswordRequirements = () => [
  t('num_characters', { count: numCharacters, amount: numCharacters }),
  t('num_lowercase', { count: numLowercase, amount: numLowercase }),
  t('num_uppercase', { count: numUppercase, amount: numUppercase }),
  t('num_number', { count: numNumber, amount: numNumber }),
  // 'Contain at least 1 symbol from the set \'!@#$%^&*()\'',
];
