import { flow } from 'mobx';
import { getEnv, Instance, isAlive, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { RecordingReport } from '../reports/recording_report';

export const Recording = types
  .model('recording', {
    id: types.identifier,
    sequenceNumber: types.number,
    comment: types.string,
    date: types.maybeNull(types.Date),
    dateUtcOffset: types.maybeNull(types.number),
    updatedAt: types.Date,
    status: types.optional(types.enumeration(['QUEUEING', 'ANALYSED', 'ERROR']), 'QUEUEING'),
    report: types.maybeNull(RecordingReport),
    fetchingReport: false,
  })
  .views(self => ({
    get unreadCount() {
      if (self.report && !self.report.read) {
        return 1;
      }
      return 0;
    },
  }))
  .actions(self => ({
    updateComment: flow(function* update(comment: string) {
      const { api } = getEnv(self);
      yield api.recordings.patchRecording(self.id, { comment });
      self.comment = comment;
    }),
    setReport(report): void {
      if (isAlive(self)) {
        self.report = RecordingReport.create(report);
      }
    },
  }));

export interface IRecording extends Instance<typeof Recording> {}
export interface IRecordingSnapshotIn extends SnapshotIn<typeof Recording> {}
export interface IRecordingSnapshotOut extends SnapshotOut<typeof Recording> {}
