import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import api from 'api';
import { RouteConstants } from 'containers/Survey/routes';

import { UserForm } from '../components/UserDetail/UserForm';

import '../styles/styles.scss';

interface Props extends RouteComponentProps {}

interface State {
  submitting: boolean;
}

class Home extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }

  submitFormHandler = async values => {
    this.setState({
      submitting: true,
    });
    try {
      const patient = await api.surveys.createSurveyPatient(values);
      this.props.history.push({
        pathname: RouteConstants.ASSESSMENT_SURVEY,
        state: { user: { ...patient } },
        search: this.props.location.search,
      });
    } catch (e) {
      console.error(e);
    }
    this.setState({
      submitting: false,
    });
  };

  render() {
    return (
      <div className="container assessment_home">
        <article className="intro">
          <h1 className="theme_title1">Sleep Assessment</h1>
          {/* <h4 className="theme_title4">For more than 30 years we've been helping people get better sleep. <br/>Together, */}
          {/*  let's figure out how to improve yours. Your results will be emailed to address provided below.</h4> */}
        </article>
        <UserForm submitting={this.state.submitting} onSubmit={this.submitFormHandler} />
      </div>
    );
  }
}

export default Home;
