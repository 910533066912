import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { Survey } from './survey';

export const SurveyResponse = types
  .model('response', {
    id: types.identifier,
    patient: types.maybeNull(
      types.model({
        id: types.identifier,
        firstname: types.string,
        lastname: types.string,
        birthday: types.maybeNull(types.Date),
        email: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
      }),
    ),
    test: types.maybeNull(
      types.model({
        id: types.identifier,
      }),
    ),
    unit: types.string,
    recording: types.maybeNull(types.string),
    survey: types.reference(Survey),
    response: types.string,
    createdAt: types.maybe(types.Date),
  })
  .views(self => ({
    get parsedResponse() {
      return JSON.parse(self.response);
    },
  }));

export interface ISurveyResponse extends Instance<typeof SurveyResponse> {}
export interface ISurveyResponseSnapshotIn extends SnapshotIn<typeof SurveyResponse> {}
export interface ISurveyResponseSnapshotOut extends SnapshotOut<typeof SurveyResponse> {}
