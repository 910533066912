import { convertPatientBirthday } from 'api/util.api';
import { ISurveySnapshotIn } from 'core/stores/data/surveys/survey';
import { ISurveyResponseSnapshotIn } from 'core/stores/data/surveys/surveyResponse';
import { parseISO } from 'core/util/date';

export const convertSurveyResponse = (response: SurveyResponseAPI): ISurveyResponseSnapshotIn => {
  let test = null;
  let patient = null;
  if (response.test) {
    test = {
      id: response.test._id,
    };
  }

  if (response.patient) {
    patient = {
      id: response.patient._id,
      firstname: response.patient.first_name,
      lastname: response.patient.last_name,
      email: response.patient.email,
      phone: response.patient.phone,
      birthday: convertPatientBirthday(response.patient.dob),
    };
  }
  return {
    id: response._id,
    patient,
    test,
    unit: response.unit,
    survey: response.survey,
    recording: response.recording,
    response: JSON.stringify(response.response),
    createdAt: parseISO(response.created_at),
  };
};

export const convertSurvey = (response: SurveyAPI): ISurveySnapshotIn => {
  return {
    id: response._id,
    name: response.name,
    type: response.type,
  };
};
