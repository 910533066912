import { flow, Instance, types } from 'mobx-state-tree';

import { Enrollments } from './enrollments';
import { Statistics } from './statistics';
import { Surveys } from './surveys';
import { Tests } from './tests';
import Units from './units';

export const Data = types
  .model('data', {
    units: types.optional(Units, () => Units.create({})),
    tests: types.optional(Tests, () => Tests.create({})),
    enrollments: types.optional(Enrollments, () => Enrollments.create({})),
    statistics: types.optional(Statistics, () => Statistics.create({})),
    surveys: types.optional(Surveys, () => Surveys.create({})),
    refreshing: false,
    lastRefreshed: Date.now(),
    fetching: false,
    lastFetched: Date.now(),
  })
  .views(self => ({
    get initialized(): boolean {
      return self.units.initialized && self.tests.initialized && self.enrollments.initialized;
    },
    get initializing(): boolean {
      return self.units.initializing || self.tests.initializing || self.enrollments.initializing;
    },
  }))
  .actions(self => ({
    initialize: flow(function* initialize() {
      yield self.units.initialize();
      yield self.enrollments.initialize();
      yield self.tests.initialize();
    }),
    refresh: flow(function* refresh() {
      self.refreshing = true;

      yield self.units.refresh();
      yield self.enrollments.refresh();
      yield self.tests.refresh();

      self.lastRefreshed = Date.now();
      self.refreshing = false;
    }),
    loadMore: flow(function* loadMore(query) {
      self.fetching = true;

      yield self.tests.loadMore(query);

      self.lastFetched = Date.now();
      self.fetching = false;
    }),
  }));

export default Data;
export interface IDataStore extends Instance<typeof Data> {}
