import cn from 'classnames';
import { observable } from 'mobx';
import { observer, Observer } from 'mobx-react';
import React, { Component } from 'react';
import { Form, Alert } from 'react-bootstrap';

import { logScreen } from 'core/analytics';
import { Button } from 'core/components/Button';
import { FormInput } from 'core/components/FormInput';
import { PasswordMismatch, PasswordRequirements } from 'core/components/messages';
import { getInformedConsentUrl } from 'core/constants';
import i18n from 'core/i18n';
import { IInvitation } from 'core/stores/invitation';
import { isValidPassword } from 'core/util/password';

import '../styles.scss';

const ERROR_TYPES = {
  PASSWORD_TOO_WEAK: 'PasswordTooWeak',
  PASSWORD_MISMATCH: 'PasswordMismatch',
  REQUEST_FAILURE: 'RequestFailure',
};

interface Props {
  invitation: IInvitation;
  onActivate(password: string, passwordConfirm: string): void;
}

class Activate extends Component<Props, {}> {
  @observable password = '';
  @observable passwordConfirm = '';
  @observable informedConsentAccepted = false;
  @observable language = i18n.currentLocale();
  @observable errors: string[] = [];

  componentDidMount() {
    logScreen('ACCOUNT_ACTIVATE');
  }

  get valid() {
    return this.errors.length === 0;
  }

  validatePassword() {
    const errors = [];

    if (this.passwordConfirm !== this.password) {
      errors.push(ERROR_TYPES.PASSWORD_MISMATCH);
    }
    if (!isValidPassword(this.password)) {
      errors.push(ERROR_TYPES.PASSWORD_TOO_WEAK);
    }

    this.errors = errors;
  }

  onActivate = e => {
    e.preventDefault();
    this.validatePassword();
    if (this.valid) {
      this.props.onActivate(this.password, this.passwordConfirm);
    }
  };

  handleInformedConsentAccepted(checked: boolean | undefined) {
    this.informedConsentAccepted = Boolean(checked);
  }

  handleChange = e => {
    this[e.target.name] = e.target.value;
  };

  renderErrorMessage = () => {
    return (
      <Observer>
        {() => {
          const messages = [];
          if (this.errors.includes(ERROR_TYPES.PASSWORD_MISMATCH)) {
            messages.push(<PasswordMismatch />);
          }
          if (this.errors.includes(ERROR_TYPES.PASSWORD_TOO_WEAK)) {
            messages.push(<PasswordRequirements error />);
          }
          return <>{messages}</>;
        }}
      </Observer>
    );
  };

  render() {
    return (
      <Form onSubmit={this.onActivate} className={cn('ui', 'form', { error: this.errors.length > 0 })}>
        <FormInput label={i18n.t('auth.activate.password_label')} name="password" type="password" onChange={this.handleChange} />
        <FormInput
          label={i18n.t('auth.activate.confirm_password_label')}
          name="passwordConfirm"
          type="password"
          onChange={this.handleChange}
        />
        {this.renderErrorMessage()}
        {!this.errors.includes(ERROR_TYPES.PASSWORD_TOO_WEAK) && <PasswordRequirements />}
        <Alert variant="secondary">{i18n.t('auth.activate.password_notification_trigger')}</Alert>
        <Form.Check
          checked={this.informedConsentAccepted}
          onChange={e => this.handleInformedConsentAccepted(e.target.checked)}
          label={
            <label>
              {i18n.t('auth.activate.consent.body')}
              <a href={getInformedConsentUrl(this.language)} target="_BLANK">
                {i18n.t('auth.activate.consent.url')}
              </a>
              .
            </label>
          }
        />
        <Button
          block
          type="submit"
          content={i18n.t('auth.activate.button')}
          // disabled={!this.informedConsentAccepted}
          loading={this.props.invitation.busy}
        />
      </Form>
    );
  }
}

export default observer(Activate);
