import { getEnv, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { Report } from './report';

export const TestReport = types
  .compose(
    'TestReport',
    Report,
    types.model({
      test: types.string,
    }),
  )
  .views(self => ({
    get api() {
      return getEnv(self).api.reports.testReports;
    },
  }));

export interface ITestReport extends Instance<typeof TestReport> {}
export interface ITestReportSnapshotIn extends SnapshotIn<typeof TestReport> {}
export interface ITestReportSnapshotOut extends SnapshotOut<typeof TestReport> {}
