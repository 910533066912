import * as enrollments from './enrollments.api';
import * as feedback from './feedback.api';
import * as recordings from './recordings.api';
import * as reports from './reports.api';
import * as surveys from './surveys.api';
import * as tests from './tests.api';
import * as units from './units.api';
import * as users from './users.api';

const api = {
  units,
  tests,
  users,
  recordings,
  reports,
  enrollments,
  feedback,
  surveys,
};

export default api;
export type ApiType = typeof api;
