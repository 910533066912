import { IRecordingReportSnapshotIn } from 'core/stores/data/reports/recording_report';
import { ITestReportSnapshotIn } from 'core/stores/data/reports/test_report';
import { parseISO } from 'core/util/date';

export function convertRecordingReport(report: RecordingReportAPI): IRecordingReportSnapshotIn {
  if (!report.approved || report.archived) {
    return null;
  }

  return {
    id: report._id,
    updatedAt: parseISO(report.modified_at),
    archived: report.archived,
    approved: report.approved,
    type: report.report_type,
    read: report.read,
    file: report.file,
    recording: report.recording,
  };
}

export function convertTestReport(report: TestReportAPI): ITestReportSnapshotIn {
  if (!report.approved || report.archived) {
    return null;
  }

  return {
    id: report._id,
    updatedAt: parseISO(report.modified_at),
    archived: report.archived,
    approved: report.approved,
    type: report.report_type,
    read: report.read,
    file: report.file,
    test: report.test,
  };
}
