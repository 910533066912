import React from 'react';

import LogoMarkColored from 'svgs/N_logoMark_gekleurd.svg';

import '../styles.scss';

interface Props {
  title: string;
  footer?: () => React.ReactNode;
}

export const Card: React.FC<Props> = ({ title, footer, children }) => {
  return (
    <div className="card">
      <div className="card-top-image">
        <img src={LogoMarkColored} className="App-logo" alt="logo" />
      </div>
      <div className="card-header">
        <div className="text-center">{title}</div>
      </div>
      <div className="card-body">{children}</div>
      {footer && footer()}
    </div>
  );
};
