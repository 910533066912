import { compareAsc } from 'date-fns';
import { getRoot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import i18n from 'core/i18n';
import { IStore } from 'core/stores/index';

const PAGE_SIZE = 20;

export const SurveysUIStore = types
  .model('SurveysUIStore', {
    page: 0,
    surveyFilter: types.optional(types.string, 'ALL'),
    nameFilter: types.maybeNull(types.string),
  })
  .views(self => ({
    get totalPages(): number {
      return Math.ceil(this.filteredAndSortedResponses.length / PAGE_SIZE);
    },
    get filteredAndSortedResponses() {
      return getRoot(self)
        .data.surveys.responses.filter(response => {
          if (self.surveyFilter !== 'ALL') {
            return response.survey.id === self.surveyFilter;
          }
          return true;
        })
        .filter(response => {
          if (self.nameFilter) {
            if (response.patient) {
              return `${response.patient.lastname} ${response.patient.firstname}`.toLowerCase().includes(self.nameFilter);
            }
            return false;
          }
          return true;
        })
        .sort((a, b) => {
          return -1 * compareAsc(a.createdAt, b.createdAt);
        });
    },
    get visibleResponses() {
      return this.filteredAndSortedResponses.slice(PAGE_SIZE * self.page, PAGE_SIZE * (self.page + 1));
    },
    get surveyFilterOptions() {
      const options = [
        {
          label: i18n.t('surveys.all_filter_option'),
          value: 'ALL',
        },
        ...getRoot<IStore>(self).data.surveys.surveys.map(survey => ({
          label: survey.name,
          value: survey.id,
        })),
      ];
      return options;
    },
  }))
  .actions(self => ({
    goToPage: (page: number): void => {
      self.page = page;
    },
    setSurveyFilter: (value: string | null): void => {
      self.page = 0;
      self.surveyFilter = value;
    },
    setNameFilter: (value: string): void => {
      self.page = 0;
      self.nameFilter = value;
    },
  }));

export interface ISurveysUI extends Instance<typeof SurveysUIStore> {}
export interface ISurveysUISnapshotIn extends SnapshotIn<typeof SurveysUIStore> {}
export interface ISurveysUISnapshotOut extends SnapshotOut<typeof SurveysUIStore> {}
