export const brandPrimary = '#58C7B4';
export const brandSecondary = '#566463';

export const blue = '#2ea1f8';
export const white = '#ffffff';
export const black = '#000000';
export const dark = '#566463';

export const cyan = '#83c8bc';
export const cyanDark = '#566663';
export const cyanLight = '#cae7e2';

export const gray = '#7f8fa4';
export const grayLight = '#dfe3eb';
export const greySubtle = '#f5f8fa';
export const grayLighter = '#f8fafc';

export const orange = '#ffae75';
export const red = '#d25459';

export const backgroundColor = '#eff3f6';

export const suspended = orange;

export const brand = {
  primary: brandPrimary,
  secondary: brandSecondary,
  warning: orange,
  danger: red,
};
