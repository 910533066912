import cn from 'classnames';
import React from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router';
import * as SurveyReact from 'survey-react';

import { getSurvey, postResponse } from 'api/surveys.api';
import { captureException } from 'core/analytics';
import i18n, { getBrowserLanguage, getSurveyLanguage } from 'core/i18n';

interface Props {
  history: any;
  match: any;
  location: any;
}

class SurveyClass extends React.Component<Props, {}> {
  state = {
    survey: null,
    loading: true,
  };

  componentDidMount() {
    const surveyId = this.props.match.params.id;
    if (surveyId) {
      if (surveyId === 'undefined') {
        const search = new URLSearchParams(location.search);
        const recording = search.get('recording');
        const unit = search.get('unit');
        if (unit === '5d531f751c9d440000bd77db' && recording && recording !== 'undefined') {
          getSurvey('5d5bf80cf352ce001b955489')
            .then(survey => {
              this.setState({ survey });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else if (unit === '5d531f751c9d440000bd77db' && recording === 'undefined') {
          getSurvey('5d5bf7953f745c001c466b65')
            .then(survey => {
              this.setState({ survey });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else if (unit === '5d7a4840656ce6001c8c94f4' && recording === 'undefined') {
          getSurvey('5d54791d2ef2af002847bfde')
            .then(survey => {
              this.setState({ survey });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else {
          this.setState({ loading: false });
        }
      } else {
        getSurvey(surveyId)
          .then(survey => {
            this.setState({ survey });
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  sendDataToServer = (survey: any) => {
    const search = new URLSearchParams(location.search);
    const test = search.get('test');
    const recording = search.get('recording');
    const unit = search.get('unit');
    return postResponse({
      test: test === 'undefined' ? undefined : test,
      survey: this.state.survey._id,
      response: survey.data,
      unit: unit === 'undefined' ? undefined : unit,
      recording: recording === 'undefined' ? undefined : recording,
    }).finally(() => {
      // Navigate to finished url
      window.location.replace('/survey/finish');
    });
  };

  render() {
    if (this.state.loading) {
      return <div />;
    }

    const search = new URLSearchParams(location.search);
    const test = search.get('test');
    const unit = search.get('unit');
    const recording = search.get('recording');
    if (!this.state.survey) {
      captureException(
        new Error(`No survey found for survey: ${this.props.match.params.id}, unit: ${unit}, test: ${test}, recording: ${recording}`),
      );
      window.location.replace('/survey/finish');
    }

    SurveyReact.StylesManager.applyTheme('bootstrap');

    return (
      <Container className={cn({ 'resmed-theme': RESMED_BRANDED_UNITS.includes(unit) })} style={{ paddingTop: 20 }}>
        <SurveyReact.Survey json={this.state.survey.survey} onComplete={this.sendDataToServer} locale={getBrowserLanguage()} />
      </Container>
    );
  }
}

export const Survey = withRouter(SurveyClass);
