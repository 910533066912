import cn from 'classnames';
import { computed, observable } from 'mobx';
import { inject, observer, Observer } from 'mobx-react';
import React, { Component } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { logScreen } from 'core/analytics';
import { Button } from 'core/components/Button';
import { PasswordRequirements } from 'core/components/messages/PasswordRequirements';
import i18n from 'core/i18n';
import { IAuth } from 'core/stores/auth';
import { isValidPassword } from 'core/util/password';

import { Card } from './components/Card';
import Container from './components/Container';
import './styles.scss';

const ERROR_TYPES = {
  PASSWORD_TOO_WEAK: 'PasswordTooShort',
  PASSWORD_MISMATCH: 'PasswordMismatch',
  REQUEST_FAILURE: 'RequestFailure',
};

interface InjectedProps {
  auth: IAuth;
}

interface PassedProps {
  history: any;
  token: string | null;
}

type Props = InjectedProps & PassedProps;

class ResetPassword extends Component<Props, {}> {
  @observable password = '';
  @observable passwordConfirm = '';
  @observable errors: string[] = [];
  @observable success = false;
  @observable loading = false;

  componentDidMount() {
    logScreen('RESET_PASSWORD');
  }

  @computed
  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  validateInput(): void {
    const errors = [];

    if (this.passwordConfirm !== this.password) {
      errors.push(ERROR_TYPES.PASSWORD_MISMATCH);
    }
    if (!isValidPassword(this.password)) {
      errors.push(ERROR_TYPES.PASSWORD_TOO_WEAK);
    }

    this.errors = errors;
  }

  submit = async e => {
    e.preventDefault();
    this.validateInput();
    if (!this.hasErrors && this.props.token && !this.success) {
      const { auth, token, history } = this.props;
      this.loading = true;
      try {
        await auth.resetPassword(token, this.password, this.passwordConfirm);
        this.success = true;
        setTimeout(() => {
          history.push('/login');
        }, 1500);
      } catch (e) {
        toast.error(i18n.t('auth.toasts.password_change_error'));
      } finally {
        this.loading = false;
      }
    }
  };

  handleChange = e => {
    this[e.currentTarget.name] = e.currentTarget.value;
  };

  renderErrorMessage = () => {
    return (
      <Observer>
        {() => {
          const messages = [];
          if (this.errors.includes(ERROR_TYPES.PASSWORD_MISMATCH)) {
            messages.push(
              <Alert variant="danger">
                <Alert.Heading>{i18n.t('password.error.no_match')}</Alert.Heading>
              </Alert>,
            );
          }
          if (this.errors.includes(ERROR_TYPES.PASSWORD_TOO_WEAK)) {
            messages.push(<PasswordRequirements error />);
          }
          return <>{messages}</>;
        }}
      </Observer>
    );
  };

  render() {
    return (
      <Container>
        <Card title={i18n.t('auth.reset_password.title')}>
          <Form onSubmit={this.submit} className={cn({ error: this.errors.length > 0 })}>
            <Form.Group>
              <Form.Label>{i18n.t('input_labels.password_new')}</Form.Label>
              <Form.Control
                name="password"
                value={this.password}
                onChange={this.handleChange}
                type="password"
                // error={this.hasErrors}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{i18n.t('input_labels.password_confirm')}</Form.Label>
              <Form.Control
                name="passwordConfirm"
                value={this.passwordConfirm}
                onChange={this.handleChange}
                type="password"
                // error={this.hasErrors}
              />
            </Form.Group>
            {this.renderErrorMessage()}
            {!this.errors.includes(ERROR_TYPES.PASSWORD_TOO_WEAK) && <PasswordRequirements />}
            {this.success ? (
              <Button block disabled className="success-button" icon="check">
                {i18n.t('auth.reset_password.success_button')}
              </Button>
            ) : (
              <Button
                type="submit"
                block
                content={i18n.t('auth.reset_password.action_button')}
                disabled={this.loading}
                loading={this.loading}
              />
            )}
          </Form>
        </Card>
      </Container>
    );
  }
}

// @ts-ignore
// tslint:disable-next-line: max-line-length
export default withRouter(
  inject<InjectedProps, Props>(
    // @ts-ignore
    ({ root }, { location }) => {
      const search = new URLSearchParams(location.search);
      return { auth: root.auth, token: search.get('token') };
    },
  )(observer(ResetPassword)),
);
