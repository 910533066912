import moment from 'moment';

export function convertPatientBirthday(dateString: string): Date | null {
  if (dateString) {
    return moment.utc(dateString, moment.ISO_8601).toDate();
  }
  return null;
}

export function convertToPatientBirthday(date: Date | number | null): string | null {
  if (date) {
    return moment.utc(date).format('YYYY-MM-DD');
  }
  return null;
}
