import differenceInMinutes from 'date-fns/differenceInMinutes';

import { IllegalArgumentException, SessionExpiredError } from './errors';

const MAX_SESSION_DURATION_MINUTES = 720;

export function validateSession(user: any): void {
  if (!user) {
    throw new IllegalArgumentException();
  }

  if (!user.savedAt || differenceInMinutes(new Date(), user.savedAt) > MAX_SESSION_DURATION_MINUTES) {
    throw new SessionExpiredError();
  }
}
