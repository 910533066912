import React from 'react';
import { Alert } from 'react-bootstrap';

import i18n from 'core/i18n';

export const PasswordMismatch: React.FC = () => {
  return (
    <Alert variant="danger">
      <Alert.Heading>{i18n.t('password.error.no_match')}</Alert.Heading>
    </Alert>
  );
};
