import { flow, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { logEvent } from 'core/analytics';

// ABSTRACT MODEL
export const Report = types
  .model('Report', {
    id: types.identifier,
    type: types.maybeNull(types.string),
    archived: false,
    read: false,
    updatedAt: types.Date,
    approved: types.maybeNull(types.boolean),
    file: types.string,
  })
  .views(self => ({
    get visible() {
      return !self.archived && self.approved;
    }
  }))
  .actions(self => {
    return {
      markAsRead: flow(function* markAsRead() {
        self.read = true;
        logEvent('REPORT_MARK_READ', { report_id: self.id });
        try {
          // @ts-ignore
          yield self.api.markRead(self.id);
        } catch (error) {
          console.error(error);
        }
      }),
      getFileUrl: flow(function* url() {
        // @ts-ignore
        return yield self.api.file(self.file);
      }),
    };
  });

export interface IReport extends Instance<typeof Report> {}
export interface IReportSnapshotIn extends SnapshotIn<typeof Report> {}
export interface IReportSnapshotOut extends SnapshotOut<typeof Report> {}
