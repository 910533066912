import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

export const Survey = types.model("survey", {
  id: types.identifier,
  name: types.string,
  type: types.string
});

export interface ISurvey extends Instance<typeof Survey> {}
export interface ISurveySnapshotIn extends SnapshotIn<typeof Survey> {}
export interface ISurveySnapshotOut extends SnapshotOut<typeof Survey> {}
