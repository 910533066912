import { flow, Instance, types } from 'mobx-state-tree';

import { ApiType } from 'api';

import { Auth } from './auth';
import { Data } from './data';
import { UIStore } from './ui';
import { Recording } from 'core/stores/data/recordings/recording';

const AppStore = types
  .model('AppStore', {
    auth: types.optional(Auth, {}),
    data: types.optional(Data, {}),
    ui: types.optional(UIStore, {}),
  })
  .actions(self => ({
    clearData: flow(function* clearData() {
      self.data = Data.create({});
      yield Promise.resolve(self.data);
    }),
  }));

export default AppStore;
export interface IStore extends Instance<typeof AppStore> {}
export type StoreEnv = { api: ApiType };
