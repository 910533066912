import { flow, getEnv, getRoot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { setLanguage } from 'core/i18n';
import { IEnrollment } from 'core/stores/data/enrollments/enrollment';
import { IUnit } from 'core/stores/data/units/unit';
import { IStore } from 'core/stores/index';

import { TwoFa } from './twoFA';

export const AuthUser = types
  .model('AuthUser', {
    id: types.string,
    firstname: types.string,
    lastname: types.string,
    email: types.string,
    superuser: types.boolean,
    informedConsentAccepted: types.maybe(types.boolean),
    informedConsentVersion: types.maybe(types.string),
    language: types.string,
    twoFa: types.maybeNull(TwoFa),
    fetching2FA: false,
    error: '',
    createdAt: types.string,
    verified: types.boolean,
  })
  .views(self => ({
    get enrollments(): IEnrollment[] {
      const { data } = getRoot<IStore>(self);
      if (data.enrollments.initialized) {
        const result = data.enrollments.enrollments
          .filter((e: IEnrollment) => e.user && e.user.id === self.id)
          .sort((a: IEnrollment, b: IEnrollment) => a.unit.name.localeCompare(b.unit.name));
        return result;
      }
      return [];
    },
    get enrollmentsAdmin(): IEnrollment[] {
      return this.enrollments.filter((e: IEnrollment) => e.isAdmin);
    },
    get enrollmentsPhysician(): IEnrollment[] {
      return this.enrollments.filter((e: IEnrollment) => e.isPhysician);
    },
    get isPhysician(): boolean {
      return this.enrollments.some((e: IEnrollment) => e.isPhysician);
    },
    get isAdmin(): boolean {
      return this.enrollments.some((e: IEnrollment) => e.isAdmin);
    },
  }))
  .actions(self => ({
    getEnrollmentForUnit(unit: IUnit): IEnrollment {
      return self.enrollments.filter((e: IEnrollment) => e.unit.id === unit.id)[0];
    },

    editUser: flow(function* editUser(firstname: string, lastname: string, email: string, language: string) {
      try {
        const { api } = getEnv(self);
        yield api.users.editUser(firstname, lastname, email, language);
        self.firstname = firstname;
        self.lastname = lastname;
        self.email = email;
        self.language = language;
        self.error = '';
        setLanguage(language);
      } catch (error) {
        self.error = 'Could not edit the user';
      }
    }),

    editPassword: flow(function* editPassword(oldPassword: string, newPassword: string, passwordConfirm: string) {
      const { api } = getEnv(self);
      return api.users.editPassword(oldPassword, newPassword, passwordConfirm);
    }),

    get2FA: flow(function* get2FA() {
      const { api } = getEnv(self);
      try {
        self.fetching2FA = true;
        const twoFa = yield api.users.get2FA();
        self.twoFa = twoFa;
      } finally {
        self.fetching2FA = false;
      }
    }),

    enable2FA: flow(function* enable2FA(type: string, password: string, value: string): Generator<any, TwoFaEnableResponse, any> {
      const { api } = getEnv(self);
      return yield api.users.enable2FA({ type, password, typeValue: value });
    }),

    disable2FA: flow(function* disable2FA(password) {
      const { api } = getEnv(self);
      return yield api.users.disable2FA(password);
    }),

    verify2FA: flow(function* verify2FA(type, secret, code) {
      const { api } = getEnv(self);
      return yield api.users.verify2FA({ type, secret, code });
    }),
  }));

export interface IAuthUser extends Instance<typeof AuthUser> {}
export interface IAuthUserSnapshotIn extends SnapshotIn<typeof AuthUser> {}
export interface IAuthUserSnapshotOut extends SnapshotOut<typeof AuthUser> {}
