import { apiInstance } from './axios';
import { convertEnrollment } from './transformers/enrollmentTransformer';

export function setStatus(id: string, status: string) {
  return apiInstance.patch<any>(`enrollments/${id}`, { status: status.toUpperCase() });
}

// @ts-ignore
export function resendInvite(enrollment: id) {
  return apiInstance.patch(`enrollments/resend/${enrollment}`);
}

export function getUserEnrollments(user: id, units?: string[], updatedSince?: Date) {
  return apiInstance
    .get<ListEnrollmentsAPI>('enrollments', {
      params: {
        with_related: true,
        query: {
          user_id: user,
          status: 'ACTIVE',
          // unit: { $in: units },
        },
        // 'archived.ne': true,
        // 'updated_at.gte': updatedSince,
      },
    })
    .then(res => {
      return res.data.enrollments.map(convertEnrollment);
    });
}

export function getUnitEnrollments(unit: id) {
  return apiInstance
    .get<ListEnrollmentsAPI>('enrollments', {
      params: {
        with_related: true,
        query: {
          unit,
        },
        // 'updated_at.gte': updatedSince,
      },
    })
    .then(res => {
      return res.data.enrollments.map(convertEnrollment);
    });
}
