import { isValid as fnsIsValid } from 'date-fns';
import moment from 'moment';

export const ISOFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";
export const ISOWithMsFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

export function isValid(date: Date): boolean {
  return fnsIsValid(date);
}

export function parseISO(s: string): Date {
  return moment(s, moment.ISO_8601).toDate();
}

export function formatISO(date: Date): string {
  return moment(date).toISOString();
}

export function constructDate(day: string, month: string | number, year: string): string {
  return moment.utc(`${year}-${month}-${day}`).format();
}
