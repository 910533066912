import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const TwoFa = types.model({
  type: types.string,
  value: types.string,
});

export interface ITwoFa extends Instance<typeof TwoFa> {}
export interface ITwoFaSnapshotIn extends SnapshotIn<typeof TwoFa> {}
export interface ITwoFaSnapshotOut extends SnapshotOut<typeof TwoFa> {}
