import maxDate from 'date-fns/max';
import { flow, getEnv, getRoot, Instance, isAlive, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import moment from 'moment';

import { captureException } from 'core/analytics';
import { IStore } from 'core/stores';

import { Recording } from '../recordings/recording';
import { TestReport } from '../reports/test_report';
import { IUnit } from '../units/unit';

const ActivationCode = types.model('activationCode', {
  key: types.string,
});

export interface IActivationCode extends Instance<typeof ActivationCode> {}
export interface IActivationCodeSnapshotIn extends SnapshotIn<typeof ActivationCode> {}
export interface IActivationCodeSnapshotOut extends SnapshotOut<typeof ActivationCode> {}

export const Test = types
  .model('test', {
    id: types.identifier,
    activationCode: types.maybe(ActivationCode),
    patientId: types.string,
    patientFirstname: types.string,
    patientLastname: types.string,
    patientBirthday: types.maybeNull(types.Date),
    patientPhone: types.maybeNull(types.string),
    patientEmail: types.maybeNull(types.string),
    numberOfRecordings: types.number,
    recordings: types.array(Recording),
    flagged: false,
    // offline: types.boolean,
    unitId: types.string,
    updatedAt: types.Date,
    createdAt: types.Date,
    report: types.maybeNull(TestReport),
    isResmedAustraliaTest: false,
    isResmedFranceTest: false,
    error: '',
  })
  .views(self => ({
    get unit(): IUnit {
      return getRoot<IStore>(self).data.enrollments.findUnit(self.unitId);
    },
    get status(): 'NEW' | 'IN_PROGRESS' | 'COMPLETED' {
      if (self.recordings.length === self.numberOfRecordings) {
        return 'COMPLETED';
      }

      if (self.recordings.length > 0) {
        return 'IN_PROGRESS';
      }

      return 'NEW';
      // const recordingStates = self.recordings.map(r => r.status);

      // Error = any recording in the test has an error
      // if (recordingStates.filter(r => r === 'ERROR').length > 0) {
      //   return 'error';
      // }

      // Analysed = completed = all recordings of the test are successful
      // if (
      //   recordingStates.filter(r => r === 'ANALYSED').length === self.recordings.length &&
      //   self.recordings.length === self.numberOfRecordings
      // ) {
      //   return 'analysed';
      // }

      // Awaiting = in progress = any recording in the test is not completed yet
      // if (
      //   recordingStates.filter(r => r === 'QUEUEING').length > 0 ||
      //   (self.numberOfRecordings !== self.recordings.length && self.recordings.length > 0)
      // ) {
      //   return 'awaiting';
      // }

      // New = 0 started recordings
      // return 'new';
    },
    get fullName(): string {
      return [self.patientFirstname, self.patientLastname].join(' ');
    },
    get formattedBirthday(): string {
      if (self.patientBirthday) {
        return moment.utc(self.patientBirthday.toJSON(), moment.ISO_8601).format('ll');
      }
      return '';
    },
    get unreadCount(): number {
      return self.recordings.reduce((sum, recording) => sum + recording.unreadCount, 0);
    },
    get lastRecordingDate(): Date {
      return maxDate(self.recordings.map(r => r.date));
    },
    get dynamicReportPath(): string {
      return `dashboard/dynamic-report/${self.id}`;
    },
    get completedNights(): number {
      return self.recordings.length;
    },
    get isResmedTest(): boolean {
      return this.isResmedAustraliaTest || this.isResmedFranceTest;
    },
  }))
  .actions((self: ITest) => ({
    sendMail(locale: string): void {
      const { api } = getEnv(self);
      api.tests.sendMail(self, locale);
    },
    fetchActivationCode: flow(function* fetchActivationCode() {
      const { api } = getEnv(self);
      self.activationCode = yield api.tests.fetchActivationCode(self);
    }),
    remove: flow(function* remove(): any {
      try {
        const { api } = getEnv(self);
        yield api.tests.archive(self.id);
        self.error = '';
        getRoot<IStore>(self).data.tests.removeTest(self.id);
      } catch (err) {
        captureException(err);
        self.error = "couldn't remove test";
      }
    }),
    update: flow(function* update(newTest: any): any {
      try {
        const { api } = getEnv(self);
        const updatedTest = yield api.tests.update(newTest);
        Object.keys(updatedTest).forEach(key => {
          // eslint-disable-next-line no-prototype-builtins
          if (self.hasOwnProperty(key)) {
            self[key] = updatedTest[key];
          }
        });
        self.error = '';
      } catch (err) {
        captureException(err);
        self.error = "Couldn't update test";
      }
    }),
    setReport(report): void {
      if (isAlive(self)) {
        self.report = TestReport.create(report);
      }
    },
    mailReport: flow(function* mailReport(email: string) {
      try {
        const { api } = getEnv(self);
        yield api.tests.mailReport(self.id, email);
        self.error = '';
      } catch (err) {
        captureException(err);
        self.error = "couldn't send email";
      }
    }),
    downloadEDF: flow(function* downloadEDF(): any {
      try {
        const { api } = getEnv(self);
        return yield Promise.allSettled(self.recordings.map(recording => api.tests.downloadEDF(recording.id)));
      } catch (err) {
        console.error(err);
        captureException(err);
      }
    }),
  }));

export interface ITest extends Instance<typeof Test> {}
export interface ITestSnapshotIn extends SnapshotIn<typeof Test> {}
export interface ITestSnapshotOut extends SnapshotOut<typeof Test> {}
