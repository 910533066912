/* eslint-disable max-len */
/* tslint:disable */
export default {
  general: {
    save: 'Sauvegarder',
    edit: 'Éditer',
    yes: 'Oui',
    no: 'Non',
    cancel: 'Annuler',
    submit: 'Soumettre',
    confirm: 'Confirmer',
    done: 'Fini',
    loading: 'Chargement',
    print: 'Imprimer',
    update: 'Mettre à jour',
    enable: 'Activer',
    disable: 'Désactiver',
    next: 'Suivant',
    back: 'Retour',
    send: 'Envoyer',
    email: 'Email',
    help: 'Aide',
    day: 'Jour',
    month: 'Mois',
    year: 'Année',
  },
  months: {
    jan: 'Janvier',
    feb: 'Février',
    mar: 'Mars',
    apr: 'Avril',
    may: 'Mai',
    jun: 'Juin',
    jul: 'Juillet',
    aug: 'Août',
    sep: 'Septembre',
    oct: 'Octobre',
    nov: 'Novembre',
    dec: 'Decembre',
  },
  input_labels: {
    email: 'Email',
    phone: 'Numéro de téléphone',
    password: 'Mot de passe',
    password_confirm: 'Confirmer le mot de passe',
    password_new: 'Nouveau mot de passe',
    password_old: 'Ancien mot de passe',
    simple: 'Simple',
    select_unit: 'Sélectionnez le centre',
    unit: 'Centre',
    lastname: 'Nom',
    firstname: 'Prénom',
    birthday: 'Date de naissance',
    birthday_and_format: 'Date de naissance (AAAA-MM-JJ)',
    language: 'Langue',
    fulfillment: 'Livraison par Ectosense',
    address: 'Addresse',
    city: 'Ville',
    region: 'Province/Région',
    zip: 'Code Postal',
    country: 'Pays',
    offline: 'Test hors ligne',
    nights: 'Nuits',
    verifiation_code: 'Code de vérification',
  },
  validation_errors: {
    required_field: '{{field}} est requis',
    invalid_email: 'Email invalide',
    invalid_password: 'Mot de passe incorrect',
    invalid_phone_require_international:
      "Le numéro de téléphone fourni n'est pas valide, veuillez fournir un numéro au format international.",
  },
  locales: {
    english: 'Anglais',
    dutch: 'Néerlandais',
    french: 'Français',
    portuguese: 'Portugais',
    german: 'Allemand',
    spanish: 'Espagnol',
  },
  roles: {
    PHYSICIAN: 'professionnel de santé',
    ADMIN: 'administrateur',
  },
  password: {
    requirements: {
      req_intro_header: 'Exigences de mot de passe',
      req_intro: 'Les mots de passe doivent',
      not_met: 'Exigences de mot de passe non remplies',
      num_characters: 'Comporter au moins {{amount}} caractères',
      num_lowercase: {
        one: 'Contenir au moins {{amount}} caractère minuscule',
        other: 'Contenir au moins {{amount}} caractères minuscules',
      },
      num_uppercase: {
        one: 'Contenir au moins {{amount}} caractère majuscule',
        other: 'Contenir au moins {{amount}} caractères majuscules',
      },
      num_number: {
        one: 'Contenir au moins {{amount}} chiffre',
        other: 'Contenir au moins {{amount}} chiffres',
      },
    },
    error: {
      no_match: 'Les mots de passe ne correspondent pas',
    },
  },
  auth: {
    login: {
      title: 'Bienvenue à NightOwl',
      email_input_label: 'Se connecter avec adresse e-mail',
      button: 'Se Connecter',
      messages: {
        incorrect: "Vous avez fourni des informations d'identification incorrectes. Veuillez réessayer.",
        error: "Nous n'avons pas pu vous connecter. Veuillez réessayer.",
      },
    },
    '2fa': {
      title: 'Two Factor Authentication',
      button: 'Submit',
      messages: {
        info_sms: 'Un SMS vous a été envoyé avec un code de vérification, merci de renseigner le code reçu',
        info_email: 'Un e-mail vous a été envoyé avec un code de vérification, veuillez remplir le code reçu',
        info_authenticator: "Entrez un code depuis votre application d'authentification",
        incorrect: 'Le code que vous avez fourni était incorrect, veuillez réessayer',
        error: "Une erreur s'est produite, veuillez réessayer",
      },
    },
    reset_password: {
      title: 'Réinitialiser le mot de passe',
      success_button: 'Succès',
      action_button: 'Changer mon mot de passe',
    },
    forgot_password: {
      title: 'Mot de passe oublié?',
      success_button: 'Vérifiez votre email',
      action_button: 'Réinitialiser mon mot de passe',
      errors: {
        invalid_email: 'Veuillez fournir un e-mail valide',
        unknown_email: "Aucun utilisateur n'a été trouvé pour l'adresse e-mail indiquée",
        unknown: "Une erreur s'est produite. Veuillez réessayer",
      },
    },
    invite: {
      title: 'Bienvenue à NightOwl',
      main: {
        intro: 'Cher/Chère {{firstname}} {{lastname}},',
        body: 'Vous avez été invité à rejoindre le centre {{unit}} avec les rôles suivants:',
        button: "Accepter l'invitation",
      },
      user_logged_in: {
        body: 'Un autre utilisateur est déjà connecté.',
        button: "Déconnecter l'autre utilisateur",
      },
      back_to_login_button: 'Retour à la page de connexion',
    },
    activate: {
      password_label: 'Choisir un mot de passe',
      confirm_password_label: 'Confirmer le mot de passe',
      button: 'Activer votre compte',
      consent: {
        body: "En activant mon compte, j'accepte ",
        url: 'le Consentement Éclairé de NightOwl',
      },
      password_notification_trigger: 'Cela déclenchera un courrier électronique vous informant que votre mot de passe a changé.',
    },
    toasts: {
      reset_email: 'Email de réinitialisation du mot de passe envoyé',
      password_change_error: 'Erreur en essayant de changer le mot de passe',
    },
    footer: {
      no_account_yet: 'Créer votre compte',
      forgot_password: 'Mot de passe oublié?',
    },
    errors: {
      invitation_not_found: "Désolé, votre invitation n'a pas été trouvée. Peut-être qu'il a expiré ou qu'il a déjà été accepté.",
      unknown: "Désolé, quelque chose s'est mal passé. Veuillez réessayer plus tard.",
    },
  },
  dashboard: {
    title: 'Résultats',
    subtitle: 'Les résultats pour tous vos patients',
    no_tests: "Il n'y a pas de tests à afficher",
    all_units: 'Toutes les centres',
    header: {
      search_placeholder: 'Recherche...',
      new_test: 'Nouveau Test',
    },
    labels: {
      new: 'NOUVEAU',
      awaiting: 'EN ATTENTE',
      error: 'ERREUR',
      report: 'RAPPORT',
      summary: 'RÉSUMÉ',
    },
    actions: {
      mail_report: 'Envoyer le rapport au patient',
      delete: 'Supprimer le test',
      request_feedback: 'Demande de la commentaire',
      download_edf: "Télécharger l'EDF",
    },
    table_header: {
      refresh_popup: 'Dernière mise à jour: {{date}}',
    },
    columns: {
      patient: 'Patient',
      uploaded_nights: 'Nuits Téléchargées',
      recording_date: "Date d'Enregistrement",
      creation_date: 'Date de Création',
      status: 'État',
      actions: 'Actions',
    },
    filter_states: {
      all: 'Tout',
      in_progress: 'En cours',
      completed: 'Terminé',
      new: 'Nouveau',
      error: 'Erreur',
    },
    modals: {
      comment: {
        title: 'Commentaire',
        toast_error: "Erreur lors de l'ajout du commentaire",
        toast_success: 'Commentaire ajouté',
        toast_error_update: 'Erreur lors de la mise à jour du commentaire',
        toast_success_update: 'Commentaire mis à jour',
      },
      mail_report: {
        title: 'Envoyer le rapport',
        body: "Le rapport sera envoyé à l'adresse électronique suivante. Si nécessaire, vous pouvez changer cela ici.",
        toast_error: "Erreur lors de l'envoi du rapport",
        toast_success: 'Rapport envoyé',
      },
      create: {
        patient_title: 'Information du patient',
        test_title: 'Concernant le test',
        fulfillment_popup: 'Laisser Ectosense gérer la livraison du kit de test NightOwl',
        simple_popup: 'Les informations requises sont à compléter par le patient',
        offline_popup: "Les tests hors ligne sont limités à 3 nuits, le patient n'a pas besoin de smartphone pour compléter le test",
        suspended_unit: 'De nouveaux tests ne peuvent pas être créés pour les centres suspendues, contactez votre administrateur',
        button_ack: 'Créer un test',
        no_edit_confirm: {
          one: 'Êtes-vous sûr de vouloir commencer un test pour une nuit?',
          other: 'Êtes-vous sûr de vouloir commencer un test pendant {{nights}} nuits?',
        },
        toasts: {
          email_sent: 'Email envoyé avec succès',
          email_sending: "Envoi d'email...",
          success: 'Test créé avec succès',
          missing_info: 'Information manquante',
          error: 'Impossible de créer le test',
        },
      },
      delete: {
        title: 'Supprimer le test',
        body: 'Êtes-vous sûr de vouloir supprimer le test de {{info}}?',
        toast_success: 'Test supprimé avec succès',
        toast_error: 'Erreur lors de la suppression du test',
      },
      feedback: {
        title: 'Demande de la commentaire',
        toast_success: 'Demande envoyée',
        toast_error: "Erreur lors de l'envoi de la demande",
      },
      qr_modal: {
        header: "Code d'Activation: ",
        toast_success: 'Email envoyé!',
        print_instructions_button: 'Imprimer Instructions',
        print_qr_button: 'Imprimer le code QR',
        mail_button: {
          done: 'Email envoyé!',
          not_set: "Pas d'email défini",
          send: 'Envoyer email',
        },
      },
      report_viewer: {
        title: 'Visionneuse de rapports',
      },
      edit: {
        title: 'Information du patient',
        toast: {
          error: 'Impossible de mettre à jour le test',
          success: 'Test mis à jour',
        },
      },
    },
    load_more: 'Chargez plus',
  },
  unit: {
    physicians: 'Professionnels de santé',
    admins: 'Administrateurs',
    statistics_undefined: "Une erreur s'est produite lors de l'extraction des statistiques du centre.",
    buttons: {
      edit: 'Modifier',
      invite: 'Inviter un membre',
      retry: 'réessayer',
    },
    statistics: {
      tests: 'Tests',
      new_reports: 'Nouveaux Rapports',
      credits: 'Crédits',
      physicians: 'Professionnels de santé',
    },
    columns: {
      name: 'Nom',
      joined: 'Joint',
      status: 'État',
    },
    suspended: {
      body_wo_name: "Ce centre est suspendue. De nouveaux tests ne peuvent pas être démarrés jusqu'à ce que le centre soit réactivée.",
      body_with_name:
        "Le centre {{name}} est suspendue. De nouveaux tests ne peuvent pas être démarrés jusqu'à ce que le centre soit réactivée.",
    },
    user: {
      unknown: 'Utilisateur Inconnu',
      actions: {
        disable: 'Désactiver',
        reactivate: 'Réactiver',
        resend: 'Envoyer à nouveau',
      },
      status: {
        invited: 'Invité',
        active: 'Actif',
        disabled: 'Désactivé(e)',
        undefined: 'Inconnu',
      },
      toasts: {
        change_error: 'Erreur lors du changement de statut',
        resend_error: "Erreur lors du renvoi de l'invitation",
        resend_success: "L'invitation a ete renvoyée avec succès",
      },
    },
    modals: {
      invite: {
        title: 'Inviter un membre',
        button_invite: 'Inviter',
      },
    },
  },
  unit_edit: {
    details: {
      title: 'Détails Du Centre',
      name: 'Nom du centre',
      btn_edit: 'Modifier les détails',
    },
    settings: {
      title: 'Paramètres du centre',
      simple: 'Les nouveaux tests passent en configuration simple',
      nights: 'Nombre de nuits par défaut pour les nouveaux tests',
      two_fa_enforced: "Appliquer l'authentification à deux facteurs pour les utilisateurs inscrits",
      btn_edit: 'Modifier les paramètres',
    },
    modals: {
      details: {
        title: 'Modifier les détails du centre',
        name_label: 'Nom du centre',
        toast_error: 'Erreur lors de la mise à jour des détails du centre',
        toast_success: 'Mise à jour avec succès les détails du centre',
      },
      settings: {
        title: 'Modifier les paramètres du centre',
        simple: 'Les nouveaux tests passent en configuration simple',
        nights: 'Nombre de nuits par défaut pour les nouveaux tests',
        two_fa_enforced: "Appliquer l'authentification à deux facteurs pour les utilisateurs inscrits",
        toast_error: 'Erreur lors de la mise à jour des détails du centre',
        toast_success: 'Mise à jour avec succès les détails du centre',
      },
    },
  },
  units: {
    title: 'Centres',
    subtitle: 'Vous administrez les centres suivantes',
    button_new: 'Nouveau Centre',
    unit_card: {
      suspended: 'Suspendu',
      new_reports: 'Nouveaux Rapports',
      credits: 'Crédits',
      physician: {
        one: '{{count}} Professionnel de santé',
        other: '{{count}} Professionnels de santé',
      },
    },
    create_modal: {
      s1: {
        title: 'Détails du nouveau centre',
        labels: {
          name: 'Nom du centre',
          balance: 'Crédits',
        },
      },
      s2: {
        title: 'Premier utilisateur de {{name}}',
        button: 'Créer un centre',
      },
    },
  },
  account: {
    title: 'Compte',
    subtitle: '',
    log_out: 'Se Déconnecter',
    modals: {
      edit_password: {
        toast_error: 'Impossible de mettre à jour le mot de passe',
        toast_success: 'Mot de passe mis à jour',
        unauth_header: 'Non autorisé',
        unauth_content: "L'ancien mot de passe donné ne correspond pas au mot de passe actuel",
        reasons: {
          outdated: {
            header: 'Politique de rotation du mot de passe',
            content: 'Notre politique de rotation des mots de passe vous oblige à mettre à jour votre mot de passe tous les 60 jours',
          },
        },
      },
      edit_user: {
        warn_firstname: 'Prénom ne peut pas être vide',
        warn_lastname: 'Nom ne peut pas être vide',
        warn_email: "L'email fourni n'est pas valide",
        toast_error: 'Erreur lors de la mise à jour du profil',
        toast_success: 'Profil mis à jour',
      },
      enable_two_fa: {
        title: 'Authentification à deux facteurs',
        subtitle: 'Choisissez la méthode souhaitée',
        alert: {
          Enforced2FA: "L'authentification à deux facteurs est requise pour l'un des centres auxquels vous êtes inscrit",
        },
        authenticator: {
          h1: "Scannez ce code QR avec votre application d'authentification",
          p1:
            "Scannez l'image ci-dessous avec l'application d'authentification à deux facteurs sur votre téléphone. Si vous ne pouvez pas scanner un code QR, veuillez utiliser une méthode différente.",
          h2: "Entrez le code à 6 chiffres de l'application",
          p2: "Après avoir numérisé l'image du code-barres, l'application affichera un code à 6 chiffres que vous devez saisir ici:",
        },
        email: {
          p_email: "Indiquez l'adresse e-mail sur laquelle vous souhaitez recevoir deux codes de vérification des facteurs",
          p_verification: "Veuillez saisir le code de vérification qui a été envoyé à l'adresse e-mail fournie",
        },
        sms: {
          p_sms:
            'Indiquez le numéro de téléphone (format international) sur lequel vous souhaitez recevoir deux codes de vérification des facteurs',
          p_verification: 'Veuillez entrer le code de vérification qui a été envoyé au numéro de téléphone fourni',
        },
      },
      disable_two_fa: {
        title: 'Authentification à deux facteurs',
        subtitle: "Veuillez confirmer que vous souhaitez désactiver la méthode d'authentification à deux facteurs suivante",
        verify: {
          instruction_sms: 'Veuillez entrer le code de vérification qui a été envoyé au numéro de téléphone enregistré',
          instruction_email: "Veuillez saisir le code de vérification qui a été envoyé à l'adresse e-mail enregistrée",
          instruction_authenticator: "Entrez un code depuis votre application d'authentification",
        },
      },
    },
    personal_codes: {
      title: "Code d'application personnel",
      subtitle: 'Fournissez à vos patients le code suivant lors du démarrage d’un nouveau test:',
    },
    enrollments: {
      title: 'Mes inscriptions',
      unit_col: 'Centre',
      roles_col: 'Rôles',
    },
    profile: {
      title: 'Mon profil',
      name: 'Nom',
      edit_profile: 'Editer le profil',
    },
    security: {
      title: 'Sécurité',
      password: {
        title: 'Mot de passe',
        change_btn: 'Changer le mot de passe',
      },
      two_fa: {
        title: 'Authentification à deux facteurs',
        subtitle:
          "L'authentification à deux facteurs ajoute une couche de sécurité supplémentaire à votre compte en exigeant plus qu'un simple mot de passe pour vous connecter.",
        methods: {
          authenticator: "Application d'authentification",
          sms: 'SMS',
          email: 'Email',
        },
        verify_pw: 'Veuillez vérifier votre mot de passe avant de continuer',
      },
    },
  },
  surveys: {
    title: 'Sondages',
    all_filter_option: 'Toutes les sondages',
    responses: {
      title: 'Sondages',
      subtitle: 'Réponses',
    },
    columns: {
      patient: 'Patient',
      survey: 'Enquête',
      response_date: 'Date de réponse',
      actions: 'Actions',
    },
  },
  survey_assessment: {
    questions: {
      satisfaction: 'Hi {{name}}, how satisfied do you feel about your current sleep?',
      sleep_description: 'How would you describe your sleep (pick most applicable)?',
      sleep_hours: 'On average, how many hours of sleep do you get each night?',
      sleep_related_pain: 'How often have you had trouble sleeping because of pain?',
      feel_sleepy: 'Even after sleeping through the night, do you feel sleepy during the day?',
      teeth_grinding: 'Do you wake up with an aching jaw, or ever been told that you grind your teeth during sleep?',
      difficult_falling_asleep: 'Do you find it difficult to fall asleep?  How severe is this problem for you?',
      difficult_staying_asleep: 'Do you find it difficult to stay asleep throughout the night? How severe is this problem for you?',
      waking_early: 'Do you have issues waking up too early and struggle to fall back asleep? How severe is this problem for you?',
      negative_impact:
        'To what extent does sleep negatively impact your quality of life? (e.g. daytime fatigue, mood, driving performance, work performance,  concentration or memory).',
      sleep_worried: 'How worried are you about your current sleep?',
      snore_self: 'Now let’s look at your night time breathing. Have you been told you snore?',
      snore_position: 'If you can recall, which position do you usually snore in while sleeping?',
      dry_mouth: 'Do you wake up with a dry mouth?',
      snore_partner: 'Do you sleep next to someone who snores?',
      gasping_breath: 'Do you ever wake gasping for breath?',
      hold_breath: 'Have you ever been told you hold your breath while sleeping?',
      headaches: 'Thanks {{name}}. Do you notice that you wake up with headaches in the morning?',
      heartburn: 'Do you experience heartburn or acid reflux, or take medication to treat it?',
      blood_pressure: 'Do you have high blood pressure or are taking medicine to treat it?',
      restless_legs: 'Do you sometimes feel that you have to move your legs to make them feel comfortable?',
      disorder_medication: 'Do you suffer from depression or a mood disorder and are currently taking medication for it?',
      copd: 'Have you ever been diagnosed with COPD, Heart Failure and or use Opioids to treat chronic pain?',
      bmi:
        'Your BMI and age can help us understand your risk factor for certain sleep disorders. Let’s calculate your BMI. Please enter your height and weight?',
      age: 'Thanks. Please indicate your age bracket',
      // eureka: 'Was there a "Eureka/aha!" moment or incident that motivated you into improving your sleep health?',
      motivation: 'What has been your motivation for wanting to improve your sleep?',
      gender: 'Lastly, what best describes your gender?',
    },
    answers: {
      yes: 'Yes',
      no: 'No',
      not_sure: 'Not sure',
      very_satisfied: 'Very satisfied',
      satisfied: 'Satisfied',
      moderately_satisfied: 'Moderately satisfied',
      dissatisfied: 'Dissatisfied',
      very_dissatisfied: 'Very dissatisfied',
      deep: 'Deep',
      great: 'Great',
      terrible: 'Terrible',
      less_5_hours: 'Less than 5 hours',
      '5_to_7_hours': '5 to 7 hours',
      '7_to_9_hours': '7 to 9 hours',
      more_9_hours: 'More than 9 hours',
      never: 'Never',
      less_once_week: 'Less than once a week',
      once_twice_week: 'Once or twice a week',
      three_more_week: 'Three or more times a week',
      no_problem: 'No problem',
      few_issues: 'Few issues',
      moderately: 'Moderately',
      severe: 'Severe',
      very_severe: 'Very severe',
      none: 'None',
      little: 'A little',
      somewhat: 'Somewhat',
      alot: 'A lot',
      not_at_all: 'Not at all',
      on_back: 'On my back',
      on_side: 'On my side',
      any_position: 'In any position',
      no_recall: "Can't recall",
      loved_one: "A loved one's suggestion",
      doctor: "Doctor's recommendation",
      energy: 'Low energy and fatigue',
      memory: 'Memory and performance issues',
      general: 'General health concerns',
      other: 'Other',
      male: 'Male',
      female: 'Female',
      light: 'Light',
      fragmented: 'Fragmented',
      interrupted: 'Could be better',
    },
    sleep_health: {
      sleep_health1: {
        light: 'Light sleeper',
        interrupted: 'Interrupted sleeper',
        fragmented: 'Fragmented sleeper',
      },
      sleep_health2: {
        yes: 'Heartburn/Acid reflux',
      },
      sleep_health3: {
        yes: 'Restless legs',
      },
    },
    physio_signs: {
      snoring: 'Snoring',
      hold_breath: 'Holds breath',
      gasping: 'Gasping for breath',
      sleepy: 'Daytime sleepiness',
      dry_mouth: 'Dry mouth/Sore throat',
      heartburn: 'Heartburn/reflux',
      blood_pressure: 'High blood pressure',
      headaches: 'Headaches',
    },
    snoring_type: {
      positional: 'Positional snoring',
      mouth: 'Mouth snoring',
      nose: 'Nose snoring',
      partner: 'Partner snoring',
    },
    result: {
      indicator: 'Risk indicator:',
      your_indicators: 'Your indicators:',
      your_physio_indicators: 'Your physiological indicators:',
      recommendations: 'Care Recommendations',
      high_risk: 'high risk',
      moderate_risk: 'moderate risk',
      low_risk: 'low risk',
      at_risk: 'at risk',
      at_risk_with_pain: 'at risk of insomnia with pain',
      apnea: {
        title: 'Sleep Apnea',
        subtitle: 'Your symptoms and BMI suggests that you may be at {{severity}} of Sleep apnea.',
        question: 'What is Sleep apnea',
        recommendations: {
          risk:
            "Your sleep assessment results indicate you may be at risk of Sleep Apnea. Consider taking a home sleep test to determine if you require treatment or talk to your doctor. We've also pulled together some suggested products just for you.  Waking up feeling refreshed and energised will help you enjoy the things you love. Alternatively, you may want to talk to your doctor.",
          low_risk:
            "Your sleep assessment results indicate you may be at low risk of Sleep Apnea. If you're unsure about your symptoms you should consider taking a home sleep test to determine if you require treatment. Alternatively, you may want to talk to your doctor.",
          copd:
            'Your sleep assessment results indicate that due to existing medical conditions you will need to speak to your GP about your symptoms and possible treatments options to improve your sleep. Alternatively, you may want to talk to your doctor.',
        },
        definition:
          'Sleep apnea is a sleep disorder that causes your body to stop breathing while you sleep. Air stops flowing to your lungs for 10 seconds or longer which can happen repeatedly during the night. Left undiagnosed and untreated, it can have long-term effects on your health. A home sleep study will help determine if you have a sleep disorder.',
      },
      insomnia: {
        title: 'Insomnia',
        risk_title: '{{risk}} of insomnia',
        risk_pain_title: '{{risk}} of insomnia with pain',
        subtitle: 'Your assessment results suggest you may be at {{severity}}.',
        question: 'What is insomnia',
        recommendations: {
          none:
            "Looks like insomnia may not be a problem for you. However, if you're looking for ways to improve your overall sleep health we've pulled together some suggested products just for you.  You deserve to feel your best with the energy to live each day to its full potential. Alternatively, you may want to talk to your doctor.",
          normal:
            "Your sleep assessment results indicate you may be at risk of Insomnia. We've pulled together some suggested products just for you that may help you get a better nights sleep. You deserve to feel your best with the energy to live each day to its full potential. Alternatively, you may want to talk to your doctor.",
          pain:
            'Your sleep assessment results indicate you may be at risk of insomnia due to pain. You should speak to your GP who may refer you to a sleep psychologist. You deserve to feel your best with the energy to live each day to its full potential.',
        },
        definition:
          'Insomnia is the name given to regularly having a hard time falling asleep, staying asleep or getting back to sleep if you wake in the night. Insomnia can make you feel drowsy during the day, and effect memory, concentration and learning.',
      },
      sleep_health: {
        subtitle: 'Your assessment results suggest you may benefit from sleep Health products',
        question: 'What is Sleep Health',
        definition:
          'Your body and mind depend on a good night’s sleep for your physical and mental health as well as your quality of life. While we sleep many important functions take place that helps the body to repair itself. Sleep also supports brain function and improves memory and mood',
        recommendation:
          'A good night’s sleep helps you feel good and be more alert, you feel more energetic and can concentrate better. Getting enough sleep is one of the most important things you can do for your health and well-being.  Here are some products just for you that may help improve your sleep. Alternatively, you may want to talk to your doctor.',
      },
      snoring: {
        positional: {
          title: 'Positional snoring',
          severity: 'Positional snorer',
          recommendation:
            'Changing your sleeping position may be key to easing your snoring. If you need a little extra help, here are some products that may help. Alternatively, you may want to talk to your doctor.',
          question: 'What is positional snoring?',
          definition:
            'Positional snoring is when you only snore in a certain position. Most people find they snore more when sleeping on their back. When you sleep on your back, gravity helps the relaxed muscles in your mouth and tongue to fall back and restrict your airway. That’s why some people snore less when they sleep on their side.',
        },
        mouth: {
          title: 'Mouth snoring',
          severity: 'Mouth snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is mouth snoring?',
          definition:
            'Mouth snorers also suffer from blocked nasal passages and therefore breathe through their mouth when they sleep. This causes the soft tissues inside their mouth to vibrate and produce a low rumbling sound.',
        },
        nose: {
          title: 'Nose snoring',
          severity: 'Nose snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is nose snoring?',
          defintion:
            'Nose snoring occurs when something is blocking the nasal passages. It can be caused by things like a deviated septum, allergies, colds and flu.',
        },
        partner: {
          title: 'Partner snoring',
          severity: 'Partner snores',
          recommendation:
            'If you’re worried about the effect that your partner’s snoring is having on your sleep there are a few things you can try. Download the myNight app to help them understand the severity of their snoring. Alternatively, you may want to talk to your doctor.',
          question: 'What is snoring?',
          definition:
            'Snoring can be a sign that you’re not breathing properly when you sleep. As you fall asleep the muscles in your mouth, tongue and throat relax. Snoring occurs when these muscles relax too much and they partially block your airway and vibrate.',
        },
      },
    },
  },
  qrPrint: {
    step: 'Étape',
    patientGeneric: 'Cher/Chère Patient,',
    patientName: 'Cher/Chère {{name}},',
    titles: {
      install: "INSTALLER L'APPLICATION POUR SMARTPHONE",
      perform: 'EFFECTUER LE TEST',
      return: 'RETOURNER LE TEST',
    },
    intro:
      "Vous êtes invité à effectuer un test de sommeil NightOwl®. Il s'agit d'un test de dépistage de l'apnée du sommeil basé sur la mesure de signaux pendant votre sommeil. Pour ce faire, vous devrez fixer un petit capteur au bout de votre index. Ce capteur éclaire la lumière sur votre doigt et enregistre la lumière réfléchie par votre peau. Il se connecte sans fil à votre smartphone via Bluetooth. Vous pouvez être invité à dormir avec ce capteur pendant plus d'une nuit.",
    introSteps:
      'La préparation de votre test est simple et ne prend que quelques minutes, à mesure que nous vous guidons à travers les étapes ci-dessous.',
    step1:
      "Sur votre smartphone, ouvrez l'application App Store si vous avez un iPhone ou l'application Play Store si vous avez un téléphone Android.",
    step2: "Dans l'application que vous venez d'ouvrir, recherchez et téléchargez l'application NightOwl Companion, qui ressemble à ceci:",
    step3: "Ouvrez l&apos;application NightOwl Companion et appuyez sur le bouton 'J'ai un code d’activation'.",
    step4: "Scannez le code QR avec l'application NightOwl Companion ou entrez le code manuellement.",
    step5:
      "Lorsque vous êtes prêt à vous coucher, ouvrez l'application NightOwl® sur votre smartphone. Cliquez sur le bouton ‘Continuer’ et suivez les instructions de l’application. Faites de beaux rêves!",
    step6:
      'Lorsque vous vous réveillez, terminez l’enregistrement en appuyant sur le bouton «Réveil» de l’application. Vos données enregistrées vont maintenant être téléchargées sur Internet.',
    step7:
      "Lorsque le processus de téléchargement est terminé, chargez le capteur conformément à la vidéo d'instructions affichée dans l'application.",
    step_return:
      "Lorsque vous avez terminé toutes les nuits de test, retournez l'appareil à votre professionnel de la santé conformément aux instructions qu'il vous a fournies.",
    footer: '© COPYRIGHT 2020 | ECTOSENSE • TOUS DROITS RESERVES',
  },
};
