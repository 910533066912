import { IAuthUserSnapshotIn } from 'core/stores/auth/authUser';
import { IUserSnapshotIn } from 'core/stores/data/users/user';
import { parseISO } from 'core/util/date';

export function convertUser(user: any): IUserSnapshotIn {
  return {
    id: user._id,
    firstname: user.first_name,
    lastname: user.last_name || '',
    email: user.email,
    updatedAt: parseISO(user.modified_at),
    createdAt: parseISO(user.created_at),
    passwordUpdatedAt: parseISO(user.password_updated_at),
  };
}

export function convertAuthUser(user: UserAPI): IAuthUserSnapshotIn {
  let superuser = false;
  const accountsRole = user.roles.find(r => r.service === 'ACCOUNTS');
  if (accountsRole && accountsRole.role.includes('SUPERUSER')) {
    superuser = true;
  }

  return {
    id: user._id,
    firstname: user.first_name,
    lastname: user.last_name || '',
    email: user.email,
    superuser,
    language: user.language_preference,
    createdAt: user.created_at,
    verified: user.verified,
  };
}
