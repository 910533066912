import makeInspectable from 'mobx-devtools-mst';
import { Provider } from 'mobx-react';
import { getSnapshot, unprotect } from 'mobx-state-tree';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './styles/main.scss';

import { initAnalytics } from 'core/analytics';
import { ErrorBoundary } from 'core/components/ErrorBoundary';
import AppStore from 'core/stores';

import App from './App';
import api from './api';

// import registerServiceWorker from './registerServiceWorker';
initAnalytics();

function createStores(snapshot) {
  const store = AppStore.create(snapshot, { api });
  makeInspectable(store);
  unprotect(store);

  return {
    root: store,
  };
}

function renderApp(stores) {
  ReactDOM.unmountComponentAtNode(document.getElementById('root'));
  ReactDOM.render(
    <ErrorBoundary>
      <BrowserRouter>
        <Provider {...stores}>
          <App />
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
}

let stores = createStores({});
renderApp(stores);

// registerServiceWorker();

if (module.hot) {
  module.hot.accept(['./core/stores'], () => {
    // Store definition changed, recreate a new one from old state
    stores = createStores(getSnapshot(stores.root));
    renderApp(stores);
  });

  module.hot.accept(['./App'], () => {
    // Component definition changed, re-render app
    renderApp(stores);
  });
}
