import { LocationDescriptor } from 'history';
import { inject, observer } from 'mobx-react';
import React, { Component, lazy, Suspense } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies

import TwoFactor from 'containers/Auth/TwoFactor';
import { FullscreenSpinner } from 'containers/FullscreenSpinner';
import { SurveyContainer } from 'containers/Survey';
import { onLanguageChange } from 'core/i18n';
import { IAuth } from 'core/stores/auth';

import ForgotPassword from './containers/Auth/ForgotPassword';
import Invitation from './containers/Auth/Invitation';
import Login from './containers/Auth/Login';
import ResetPassword from './containers/Auth/ResetPassword';
import ServiceLogin from './containers/Auth/ServiceLogin';
import Logout from './containers/Auth/Logout';

const Layout = lazy(() => import('./containers/Layout'));
const NotFound = lazy(() => import('./containers/NotFound'));
const DynamicCharting = lazy(() => import('./containers/DynamicCharting'));

interface InjectedProps {
  auth: IAuth;
}

type Props = InjectedProps & RouteComponentProps;

class App extends Component<Props, {}> {
  onLanguageChangeListener: any;

  state = {
    languageChange: false,
  };

  componentDidMount(): void {
    this.onLanguageChangeListener = onLanguageChange(this.onLanguageChange);
  }

  componentWillUnmount(): void {
    this.onLanguageChangeListener.remove();
  }

  onLanguageChange = (newLocale: string, oldLocale: string) => {
    this.setState(
      {
        languageChange: true,
      },
      () => {
        this.setState({
          languageChange: false,
        });
      },
    );
  };

  get authenticatedRedirectPath(): string {
    const { auth } = this.props;
    if (auth.isPhysician) {
      return '/dashboard';
    }
    if (auth.isAdmin) {
      return '/units';
    }
    return '/';
  }

  get localizedRedirectPath(): LocationDescriptor {
    const { location } = this.props;
    if (location) {
      const { pathname } = location;
      if (pathname.startsWith('/fr') || pathname.startsWith('/de')) {
        return {
          pathname: pathname.substring(3),
          search: `?locale=${pathname.substring(1, 3)}`,
        };
      }
    }
    return '/login';
  }

  render(): JSX.Element {
    const { auth } = this.props;
    if (auth.initialized && !this.state.languageChange) {
      const loggedIn = auth.authenticated;
      return (
        <Suspense fallback={<FullscreenSpinner />}>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={3000} />
          <Switch>
            <Route exact path="/dashboard/charting/" component={DynamicCharting} />
            <Route path="/survey/:id" component={SurveyContainer} />
            <Route path="/enrollments/activate" component={Invitation} />
            <Route
              exact
              path="/login"
              render={props => (loggedIn ? <Redirect to={this.authenticatedRedirectPath} /> : <Login {...props} />)}
            />
            <Route
              exact
              path="/login/service"
              render={props => (loggedIn ? <Redirect to={this.authenticatedRedirectPath} /> : <ServiceLogin {...props} />)}
            />
            <Route path="/login/2fa" render={props => (loggedIn ? <Redirect to="/dashboard" /> : <TwoFactor {...props} />)} />
            <Route exact path="/logout" render={props => (loggedIn ? <Logout /> : <Redirect to={this.authenticatedRedirectPath} />)} />
            <Route path="/forgot_password" render={() => (loggedIn ? <Redirect to="/dashboard" /> : <ForgotPassword />)} />
            <Route path="/reset/password" render={() => <ResetPassword />} />
            <Route path="/404" render={() => <NotFound />} />
            <Route path="/" render={() => (loggedIn ? <Layout /> : <Redirect to={this.localizedRedirectPath} />)} />
          </Switch>
        </Suspense>
      );
    }
    return <FullscreenSpinner />;
  }
}

export default withRouter(inject(({ root }) => ({ auth: root.auth }))(observer(App)));
