import { observable } from 'mobx';
import { inject, observer, Observer } from 'mobx-react';
import React, { Component } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { captureException, logScreen } from 'core/analytics';
import i18n from 'core/i18n';
import { IAuth } from 'core/stores/auth';
import { isValidEmail } from 'core/util';

import { Card } from './components/Card';
import Container from './components/Container';

import './styles.scss';

enum Errors {
  unknown,
  invalidEmail,
  unknownEmail,
}

interface InjectedProps {
  auth: IAuth;
}

class ForgotPassword extends Component<InjectedProps, {}> {
  @observable email = '';
  @observable success = false;
  @observable loading = false;
  @observable error: Errors | null = null;

  componentDidMount() {
    logScreen('FORGOT_PASSWORD');
  }

  submit = e => {
    e.preventDefault();
    const { auth } = this.props;
    if (!this.success && isValidEmail(this.email)) {
      this.loading = true;
      auth
        .forgotPassword(this.email)
        .then(() => {
          this.success = true;
          this.loading = false;
          toast.success(i18n.t('auth.toasts.reset_email'));
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            this.error = Errors.unknownEmail;
          } else {
            captureException(err);
            this.error = Errors.unknown;
          }
          this.loading = false;
        });
    } else {
      this.error = Errors.invalidEmail;
    }
  };

  renderErrorMessage() {
    return (
      <Observer>
        {() => {
          switch (this.error) {
            case Errors.invalidEmail:
              return <Alert variant="warning">{i18n.t('auth.forgot_password.errors.invalid_email')}</Alert>;
            case Errors.unknownEmail:
              return <Alert variant="warning">{i18n.t('auth.forgot_password.errors.unknown_email')}</Alert>;
            case Errors.unknown:
              return <Alert variant="warning">{i18n.t('auth.forgot_password.errors.unknown')}</Alert>;
            default:
              return null;
          }
        }}
      </Observer>
    );
  }

  renderFooter() {
    const { ENV } = process.env;
    return (
      <>
        <a className="footerlink left" href={ENV !== 'production' ? 'https://signup-qa.nightowl.care/' : 'https://signup.nightowl.care/'}>
          {i18n.t('auth.footer.no_account_yet')}
        </a>
        <Link className="footerlink right" to="/login">
          {i18n.t('auth.footer.back_to_login')}
        </Link>
      </>
    );
  }

  render() {
    return (
      <Container>
        <Card title={i18n.t('auth.forgot_password.title')} footer={this.renderFooter}>
          {this.renderErrorMessage()}
          <Form onSubmit={this.submit}>
            <Form.Group>
              <Form.Label>{i18n.t('input_labels.email')}</Form.Label>
              <Form.Control
                type="email"
                isInvalid={this.error === Errors.invalidEmail}
                onChange={e => {
                  this.email = e.target.value;
                }}
              />
            </Form.Group>
            {this.success ? (
              <Button
                block
                disabled
                className="success-button"
                // icon='check'
              >
                {i18n.t('auth.forgot_password.success_button')}
              </Button>
            ) : (
              <Button type="submit" block disabled={this.loading}>
                {i18n.t('auth.forgot_password.action_button')}
              </Button>
            )}
          </Form>
        </Card>
      </Container>
    );
  }
}

export default inject<InjectedProps, InjectedProps>(({ root }) => ({
  auth: root.auth,
}))(observer(ForgotPassword));
