import axios from 'axios';

const isUsInfra = () => {
  if (window.location.hostname.includes('-us')) return true;
};

const V3_BASE_URL = __DEV__ ? DEV_URL : isUsInfra() ? SERVER_URL_US : SERVER_URL;
export const ACCOUNTS_BASE_URL = __DEV__ ? ACCOUNTS_DEV_URL : isUsInfra() ? ACCOUNTS_URL_US : ACCOUNTS_URL;
const SURVEY_BASE_URL = __DEV__ ? SURVEY_DEV_URL : SURVEY_URL;
export const SIGNING_SERVICE = isUsInfra() ? 'SOMNOWARE' : 'HCP';

export let apiInstance = axios.create({
  baseURL: V3_BASE_URL,
});

export let apiAccountsInstance = axios.create({
  withCredentials: true,
  baseURL: ACCOUNTS_BASE_URL,
});

export const surveyApiInstance = axios.create({
  baseURL: SURVEY_BASE_URL,
  headers: {
    'x-api-key': EST,
    'x-manufacturer-id': 'browser',
    'x-signing-service': 'HCP',
  },
});

function getApiInstanceWithToken(token: string, service: string | null) {
  const api = axios.create({
    baseURL: V3_BASE_URL,
    headers: {
      'x-auth-token': token,
      'x-signing-service': service || SIGNING_SERVICE,
    },
  });

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401 && !error.response.config.url.endsWith('/me')) {
        window.location.replace(`/login`);
      }
      return Promise.reject(error);
    },
  );

  return api;
}

export function setAuthToken(token: string | null, service: string | null): void {
  if (token === null) {
    apiInstance = axios.create({
      baseURL: V3_BASE_URL,
    });
    apiAccountsInstance = axios.create({
      withCredentials: true,
      baseURL: ACCOUNTS_BASE_URL,
    });
  } else {
    apiInstance = getApiInstanceWithToken(token, service);
    apiAccountsInstance = axios.create({
      withCredentials: true,
      baseURL: ACCOUNTS_BASE_URL,
      headers: {
        'x-auth-token': token,
        'x-signing-service': service || SIGNING_SERVICE,
      },
    });
  }
}
