import i18n from 'core/i18n';

export const FILTER_STATES: { [key: string]: { value: string; label: () => string } } = {
  ALL: {
    value: 'filter_all',
    label: () => i18n.t('dashboard.filter_states.all'),
  },
  IN_PROGRESS: {
    value: 'filter_in_progress',
    label: () => i18n.t('dashboard.filter_states.in_progress'),
  },
  COMPLETED: {
    value: 'filter_completed',
    label: () => i18n.t('dashboard.filter_states.completed'),
  },
  NEW: {
    value: 'filter_new',
    label: () => i18n.t('dashboard.filter_states.new'),
  },
};

export enum MODAL_TYPES {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  FEEDBACK = 'feedback',
  COMMENT = 'comment',
  ACTIVATIONCODE = 'activationcode',
  MAIL_REPORT = 'mail_report',
}

export const DEFAULT_OFFLINE_EMAIL = 'offline-test@ectosense.com';

export const PROTOCOLS = {
  1: '5f1094929896cf16420f2d83',
  2: '5f1094929896cf16420f2d85',
  3: '5f1094929896cf16420f2d88',
  4: '5f1094929896cf16420f2d8c',
  5: '5f1094929896cf16420f2d91',
  6: '5f1094929896cf16420f2d97',
  7: '5f1094929896cf16420f2d9e',
};

export const PROD_PROTOCOLS = {
  1: '5f0f2c16a8bb8e3a206a2329',
  2: '5f0f2c1ba8bb8e3a206a232a',
  3: '5ece4cfdd63993c94e94d809',
  4: '5f0f2c1fa8bb8e3a206a232b',
  5: '5f0f2c23a8bb8e3a206a232c',
  6: '5f0f2c2da8bb8e3a206a232d',
  7: '5f0f2c32a8bb8e3a206a232e',
};

export const PLUS_PROTOCOLS = {
  1: '637b993f7115afb6c163c88f',
  2: '635e9247842ce244f9e11518',
  3: '635e9202842ce244f9e105da',
  4: '637b98d67115afb6c163c88e',
  5: '637b9a1b7115afb6c163c891',
  6: '637b9a827115afb6c163c892',
  7: '637b9ac07115afb6c163c893',
};
