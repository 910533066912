import cn from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';

import { Icon, IconNames } from './Icon';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: IconNames;
  content?: string;
  loading?: boolean;
  variant?: string;
  block?: boolean;
}

export const Button: React.FC<Props> = ({ icon, loading, block, content, variant, className, children, ...props }) => {
  let iconComponent;
  const newClassName = cn(`btn btn-${variant}`, className, {
    'btn-block': block,
    'btn-icon': icon,
  });
  if (loading) {
    iconComponent = <Spinner as="span" className="mr-1" animation="border" size="sm" role="status" aria-hidden="true" />;
  } else if (icon) {
    iconComponent = <Icon className={cn({ 'mr-1': content || children })} name={icon} />;
  }

  return (
    <button type="button" className={newClassName} {...props}>
      {iconComponent}
      {content}
      {children}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  variant: 'primary',
};
