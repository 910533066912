import { observable } from 'mobx';
import { inject, observer, Observer } from 'mobx-react';
import React, { Component } from 'react';
import { Alert, Button, Form, Toast } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';

import { logScreen } from 'core/analytics';
import i18n from 'core/i18n';
import { IAuth } from 'core/stores/auth';
import { UnauthorizedError } from 'core/util/errors';

import { Card } from './components/Card';
import Container from './components/Container';

import './styles.scss';

interface InjectedProps {
  auth: IAuth;
}

type Props = InjectedProps & RouteComponentProps;

class Login extends Component<Props, {}> {
  @observable email = '';
  @observable password = '';
  @observable error: any = null;

  state = {
    showToaster: false,
  };

  componentDidMount(): void {
    logScreen('LOGIN');
    const params = new URLSearchParams(this.props.location.search);
    const isVerified = params.get('signup_status');
    if (isVerified) {
      this.setState({ showToaster: true });
    }
  }

  logIn = async (e): Promise<void> => {
    e.preventDefault();
    const { auth } = this.props;
    try {
      const loginData = await auth.login(this.email, this.password);
      if (loginData.type === '2FA') {
        this.props.history.push({
          pathname: '/login/2fa',
          search: `?type=${loginData.authType}&secret=${loginData.secret}`,
        });
      }
    } catch (err) {
      this.error = err;
    }
  };

  renderExpirationMessage(): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          if (this.props.auth.sessionExpired) {
            return <Alert variant="warning">Your session has expired, please log in again</Alert>;
          }
          return null;
        }}
      </Observer>
    );
  }

  renderErrorMessage(): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const { auth } = this.props;
          if (auth.error) {
            if (this.error && this.error.name === UnauthorizedError.NAME) {
              return <Alert variant="warning">{i18n.t('auth.login.messages.incorrect')}</Alert>;
            }
            return <Alert variant="warning">{i18n.t('auth.login.messages.error')}</Alert>;
          }
          return null;
        }}
      </Observer>
    );
  }

  renderFooter(): JSX.Element {
    const { ENV } = process.env;
    return (
      <>
        <a className="footerlink left" href={ENV !== 'production' ? 'https://signup-qa.nightowl.care/' : 'https://signup.nightowl.care/'}>
          {i18n.t('auth.footer.no_account_yet')}
        </a>
        <Link className="footerlink right" to="/forgot_password">
          {i18n.t('auth.footer.forgot_password')}
        </Link>
      </>
    );
  }

  render(): JSX.Element {
    const { auth } = this.props;
    return (
      <Container>
        <div aria-live="polite" aria-atomic="true" className="toast-email-verified-container">
          <Toast
            onClose={() => this.setState({ showToaster: false })}
            show={this.state.showToaster}
            delay={3000}
            autohide
            className="toast-email-verified"
          >
            <Toast.Header>
              <p className="toast-email-verified-header">Your email has been verified successfully!</p>
            </Toast.Header>
          </Toast>
        </div>
        <Card title={i18n.t('auth.login.title')} footer={this.renderFooter}>
          <div className="neoInfo">
            <div className="neoInfoIcon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
                  fill="#006DB7"
                />
                <path
                  d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z"
                  fill="#006DB7"
                />
              </svg>
            </div>
            <div className="neoInfoParagraph">
              <div className="neoInfoTitle">Updated portal available</div>
              <div className="neoInfoUpdate">
                We have recently made updates to the NightOwl™ provider portal. Click the button below, log in with your existing
                credentials, and discover several new patient management features and a fresh new look.
              </div>
              <div className="neoInfoQuestion">
                If you have any questions, visit our{' '}
                <a href="https://nightowl.care/patientsupport" className="neoInfoPatientLink">
                  support page
                </a>
                .
              </div>
              <a href="https://nightowl.resmed.com">
                <button type="button" className="neoInfoButton">
                  Try now{' '}
                </button>
              </a>
            </div>
          </div>
          {this.renderExpirationMessage()}
          {this.renderErrorMessage()}
          <Form onSubmit={this.logIn}>
            <Form.Group>
              <Form.Label>{i18n.t('auth.login.email_input_label')}</Form.Label>
              <Form.Control
                type="email"
                onChange={e => {
                  this.email = e.target.value;
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{i18n.t('input_labels.password')}</Form.Label>
              <Form.Control
                type="password"
                onChange={e => {
                  this.password = e.target.value;
                }}
              />
            </Form.Group>
            <Button type="submit" block disabled={auth.busy}>
              {i18n.t('auth.login.button')}
            </Button>
          </Form>
        </Card>
      </Container>
    );
  }
}

export default inject<InjectedProps, Props>(({ root }) => ({
  auth: root.auth,
}))(observer(Login));
