import fileDownload from 'js-file-download';
import { applySnapshot, clone, flow, getEnv, getRoot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import moment from 'moment';

import { logEvent } from 'core/analytics';

import { Enrollment, IEnrollment } from '../enrollments/enrollment';
import { IUnitStatistic } from '../statistics/unitStatistics';

import { IDataStore } from '..';

export const EnrollmentUnit = types.model('enrollmentUnit', {
  id: types.identifier,
  name: types.string,
  status: types.maybe(types.enumeration('State', ['ACTIVE', 'DISABLED', 'SUSPENDED'])),
  fulfillmentEnabled: false,
  offlineEnabled: false,
  defaultNights: types.optional(types.number, 5),
  default_extensions: types.optional(types.number, 0),
  default_minimum_nights: types.optional(types.number, 0),
  defaultSimple: false,
  resmedFranceUnit: false,
  resmedAustraliaUnit: false,
  verified: true,
  protocol_enabled: types.maybeNull(types.boolean),
  plus_enabled: types.maybeNull(types.boolean),
  consents_requested: types.array(
    types.model({
      mandatory: types.boolean,
      consent: types.string,
      // info: types.maybeNull(types.string),
      info: types.maybe(types.late(() => types.model({}))),
    }),
  ),
});

export interface IEnrollmentUnit extends Instance<typeof EnrollmentUnit> {}
export interface IEnrollmentUnitSnapshotIn extends SnapshotIn<typeof EnrollmentUnit> {}
export interface IEnrollmentUnitSnapshotOut extends SnapshotOut<typeof EnrollmentUnit> {}

export const Unit = types
  .model('unit', {
    id: types.identifier,
    name: types.string,
    status: types.maybe(types.enumeration('State', ['ACTIVE', 'DISABLED', 'SUSPENDED'])),
    logo: types.maybeNull(types.string),
    balance: types.maybeNull(types.number),
    enrollments: types.array(types.late(() => Enrollment)),
    error: '',
    updatedAt: types.Date,
    fulfillmentEnabled: false,
    offlineEnabled: false,
    verified: false,
    defaultSimple: false,
    defaultNights: types.optional(types.number, 5),
    default_extensions: types.optional(types.number, 0),
    default_minimum_nights: types.optional(types.number, 0),
    protocol_enabled: types.maybeNull(types.boolean),
    plus_enabled: types.maybeNull(types.boolean),
    consents_requested: types.array(
      types.model({
        mandatory: types.boolean,
        consent: types.string,
        // info: types.maybeNull(types.string),
        info: types.maybe(types.late(() => types.model({}))),
      }),
    ),
    twoFaEnforced: false,
    fetchingStats: false,
    fetchingEnrollments: false,
    rawSettings: types.string,
  })
  .views(self => ({
    get statistics(): IUnitStatistic | undefined {
      const { data } = getRoot(self);
      return (data as IDataStore).statistics.unitStatistics.get(self.id);
    },
    get physicians(): IEnrollment[] {
      return this.enrollments.filter((e: IEnrollment) => e.isPhysician);
    },
    get administrators(): IEnrollment[] {
      return this.enrollments.filter((e: IEnrollment) => e.isAdmin);
    },
    get path(): string {
      return `/units/${self.id}`;
    },
    get suspended(): boolean {
      return self.status === 'SUSPENDED';
    },
    get disabled(): boolean {
      return self.status === 'DISABLED';
    },
  }))
  .actions(self => ({
    sync: (unit: any): void => {
      const enrollments = clone(self.enrollments);
      applySnapshot(self, unit);
      self.enrollments = enrollments;
    },
    update: flow(function* update(name: string) {
      try {
        const { api } = getEnv(self);
        yield api.units.setName(self.id, name);
        self.name = name;
        self.error = '';
      } catch (err) {
        self.error = 'Error while updating unit';
      }
    }),
    updateSettings: flow(function* updateSettings(settings: Partial<UnitAPI['settings']>) {
      try {
        const { api } = getEnv(self);
        yield api.units.updateUnitSettings(self.id, { ...JSON.parse(self.rawSettings), ...settings });
        if (settings.default_nights) {
          self.defaultNights = settings.default_nights;
        }
        if (settings.default_extensions) {
          self.default_extensions = settings.default_extensions;
        }
        if (settings.default_minimum_nights) {
          self.default_minimum_nights = settings.default_minimum_nights;
        }
        if (settings.default_simple != null) {
          self.defaultSimple = settings.default_simple;
        }
        if (settings.two_fa_enforced != null) {
          self.twoFaEnforced = settings.two_fa_enforced;
        }
        if (settings.protocol_enabled !== null) {
          self.protocol_enabled = settings.protocol_enabled;
        }
        if (settings.plus_enabled !== null) {
          self.plus_enabled = settings.plus_enabled;
        }
        self.error = '';
      } catch (err) {
        self.error = 'Error while applying settings';
      }
    }),
    inviteMember: flow(function* inviteMember(physician: boolean, admin: boolean, firstname: string, lastname: string, email: Email) {
      try {
        const { api } = getEnv(self);
        const enrollment = yield api.units.inviteMember(self.id, {
          physician,
          admin,
          firstname,
          lastname,
          email,
        });
        logEvent('MEMBER_INVITED', { unit: enrollment.unit, invitee: enrollment.user_id });
        enrollment.unit = {
          id: self.id,
          name: self.name,
          status: self.status,
        };
        enrollment.user = {
          id: enrollment.user_id,
          firstname,
          lastname,
          email,
          updatedAt: new Date(),
          createdAt: new Date(),
          passwordUpdatedAt: new Date(),
        };
        self.enrollments.push(enrollment);
        self.error = '';
      } catch (err) {
        self.error = "Couldn't invite member";
      }
    }),
    fetchEnrollments: flow(function* fetchEnrollments() {
      self.fetchingEnrollments = true;
      try {
        const { api } = getEnv(self);
        self.enrollments = yield api.enrollments.getUnitEnrollments(self.id);
      } catch (e) {
        // TODO: do something
      } finally {
        self.fetchingEnrollments = false;
      }
    }),
    fetchStatistics: flow(function* fetchStatistics() {
      self.fetchingStats = true;
      try {
        const { data } = getRoot(self);
        yield data.statistics.fetchStatistics(self.id);
      } catch (err) {
        // TODO: do something
      } finally {
        self.fetchingStats = false;
      }
    }),
    downloadStats: flow(function* downloadStats(from: Date, to: Date) {
      const { api } = getEnv(self);
      const stats = yield api.units.getPatientStatistics(
        self.id,
        moment(from)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate(),
        moment(to)
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .toDate(),
      );
      fileDownload(JSON.stringify(stats.statistics, null, 2), `stats_${self.name.replace(' ', '_')}.txt`);
    }),
  }));

export interface IUnit extends Instance<typeof Unit> {}
export interface IUnitSnapshotIn extends SnapshotIn<typeof Unit> {}
export interface IUnitSnapshotOut extends SnapshotOut<typeof Unit> {}
