const INFORMED_CONSENT_URLS: { [key: string]: string } = {
  en: 'https://cdn.ectosense.com/190306_ICFHCP_EN_001.pdf',
  fr: 'https://cdn.ectosense.com/190306_ICFHCP_FR_001.pdf',
};

export function getInformedConsentUrl(locale: string): string {
  return INFORMED_CONSENT_URLS[locale] || INFORMED_CONSENT_URLS.en;
}

export const SHOW_SURVEY_UNITS = [
  '5de9504a6c60c90027bec0bc',
  '5dee69d5dc8836001b827a46',
  '5d1c888e2c06e810040025f3', // SABIC PROD
  '5d531f751c9d440000bd77db', // RESMED AUS
  '5e258db6ec53fb001b20bdab', // SLAAPKLINIEK DEMO
  '5e356c8d8c67d9001bb78ab2', // JOEL SINGAPORE
];

export const SHOW_STATS_DOWNLOAD = [
  '5d531f751c9d440000bd77db', // RESMED AUS
];

export enum TWO_FACTOR_METHOD {
  AUTHENTICATOR = 'AUTHENTICATOR',
  // SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum ACCOUNT_MODALS {
  EDIT_PROFILE = 'EditProfile',
  CHANGE_PASSWORD = 'ChangePassword',
  ENABLE_2FA = 'Enable2FA',
}

export enum TWO_FACTOR_MODAL_REASONS {
  ENFORCED = 'Enforced2FA',
}

export enum PASSWORD_CHANGE_MODAL_REASONS {
  OUTDATED = 'PasswordOutdated',
}
