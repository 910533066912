import { observable } from 'mobx';
import { inject, observer, Observer } from 'mobx-react';
import React, { Component } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { logScreen } from 'core/analytics';
import i18n, { getScopedI18n, tlabels } from 'core/i18n';
import { IAuth } from 'core/stores/auth';
import { UnauthorizedError } from 'core/util/errors';

import { Card } from './components/Card';
import Container from './components/Container';

import './styles.scss';

const t = getScopedI18n('auth.2fa');

interface InjectedProps {
  auth: IAuth;
}

type Props = InjectedProps & RouteComponentProps;

class TwoFactor extends Component<Props, {}> {
  @observable code = '';
  @observable error: any = null;
  @observable type = null;

  componentDidMount(): void {
    logScreen('2FA');
    const { location } = this.props;
    const search = new URLSearchParams(location.search);
    this.type = search.get('type');
  }

  submit2fa = async event => {
    event.preventDefault();
    const { auth, location } = this.props;
    const search = new URLSearchParams(location.search);
    try {
      await auth.submit2fa(search.get('secret'), this.code, search.get('type'));
    } catch (err) {
      this.error = err;
    }
  };

  renderErrorMessage(): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          if (this.error) {
            console.log(this.error);
            if (this.error?.name === UnauthorizedError.NAME) {
              return <Alert variant="warning">{t('messages.incorrect')}</Alert>;
            }
            return <Alert variant="warning">{t('messages.error')}</Alert>;
          }
          return null;
        }}
      </Observer>
    );
  }

  renderFooter() {
    return (
      <>
        <Link className="footerlink right" to="/login">
          {i18n.t('auth.footer.back_to_login')}
        </Link>
      </>
    );
  }

  render(): JSX.Element {
    const { auth } = this.props;
    return (
      <Container>
        <Card title={t('title')} footer={this.renderFooter}>
          {this.renderErrorMessage()}
          {this.type && <Alert variant="primary">{t(`messages.info_${this.type.toLowerCase()}`)}</Alert>}
          <Form onSubmit={this.submit2fa}>
            <Form.Group>
              <Form.Label>{tlabels('verification_code')}</Form.Label>
              <Form.Control
                autoFocus
                onChange={e => {
                  this.code = e.target.value;
                }}
              />
            </Form.Group>
            <Button type="submit" block disabled={auth.busy}>
              {t('button')}
            </Button>
          </Form>
        </Card>
      </Container>
    );
  }
}

export default inject<InjectedProps, Props>(({ root }) => ({
  auth: root.auth,
}))(observer(TwoFactor));
