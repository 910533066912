import { some } from 'lodash';
import { flow, getEnv, Instance, types } from 'mobx-state-tree';

import { logEvent } from '../analytics';
import i18n from '../i18n';

import { User } from './data/users/user';

interface API {
  load(...params: any): Promise<any>;
  accept(...params: any): Promise<any>;
  activate(...params: any): Promise<any>;
}

const ERROR_MESSAGES: { [key: string]: string } = {
  400: i18n.t('auth.errors.invitation_not_found'),
  general: i18n.t('auth.errors.unknown'),
};

function errorMessage(key?: string | number | null): string {
  if (key) {
    return ERROR_MESSAGES[key] || ERROR_MESSAGES.general;
  }
  return ERROR_MESSAGES.general;
}

const InviteEnrollment = types
  .model('InviteEnrollment', {
    id: types.identifier,
    status: types.enumeration(['active', 'invited', 'disabled']),
    roles: types.array(types.enumeration(['PHYSICIAN', 'ADMIN'])),
    createdAt: types.Date,
    acceptedAt: types.maybeNull(types.Date),
    fees: types.number,
    code: types.maybe(types.string),
    updatedAt: types.Date,
    error: '',
  })
  .views(self => ({
    get isAdmin(): boolean {
      return some(self.roles, r => r === 'ADMIN');
    },
    get isPhysician(): boolean {
      return some(self.roles, r => r === 'PHYSICIAN');
    },
  }));

export const Invitation = types
  .model('Invitation', {
    busy: true,
    token: types.string,
    enrollmentId: types.string,
    accepted: false,
    confirmed: false,
    user: types.maybe(User),
    enrollment: types.maybe(InviteEnrollment),
    unitName: types.optional(types.string, ''),
    errorCode: types.maybeNull(types.number),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get initialized(): boolean {
      return self.user !== undefined;
    },
    get api() {
      return (getEnv(self).api as any) as API;
    },
    get invitee() {
      return self.user;
    },
  }))
  .actions(self => ({
    afterCreate(): void {
      this.initialize();
    },
    initialize: flow(function* initialize() {
      self.busy = true;
      self.error = null;
      try {
        const result = yield self.api.load(self.enrollmentId, self.token);
        self.user = result.user;
        self.enrollment = result.enrollment;
        self.unitName = result.unit.name;
      } catch (error) {
        if (error.response) {
          self.errorCode = error.response.status;
          self.error = errorMessage(self.errorCode);
        } else {
          self.error = errorMessage();
        }
      } finally {
        self.busy = false;
      }
    }),
    accept: flow(function* accept() {
      self.busy = true;
      self.error = null;
      try {
        const result = yield self.api.accept(self.enrollmentId, self.token);
        logEvent('INVITATION_ACCEPTED');
        self.accepted = true;
        self.confirmed = result.confirmed;
      } catch (error) {
        self.error = errorMessage();
      }
      self.busy = false;
    }),
    activate: flow(function* activate(password: string, passwordConfirmation: string) {
      // TODO: first set password, then set language / name / accept ICF ...
      self.busy = true;
      self.error = null;
      try {
        yield self.api.activate(self.token, password, passwordConfirmation);
        logEvent('INVITATION_ACTIVATED');
        self.confirmed = true;
      } catch (error) {
        self.error = errorMessage();
      }
      self.busy = false;
    }),
  }));

export type IInvitation = Instance<typeof Invitation>;
