import maxDate from 'date-fns/max';
import { applySnapshot, flow, getEnv, getRoot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { fetchRecordingReports, fetchTestReports } from 'api/reports.api';
import { logEvent } from 'core/analytics';
import { IStore } from 'core/stores/index';

import { IEnrollment } from '../enrollments/enrollment';
import { IEnrollmentUnit } from '../units/unit';

import { ITest, Test } from './test';

export const Tests = types
  .model('test', {
    tests: types.map(Test),
    initialized: false,
    initializing: false,
    errors: '',
  })
  .views(self => ({
    get mostRecentUpdate(): Date | null {
      return maxDate(Array.from(self.tests.values()).map(s => s.updatedAt)) || null;
    },
    getTestById(id: string): ITest {
      return self.tests.get(id);
    },
  }))
  .actions(self => ({
    initialize: flow(function* initialize(query?) {
      self.initializing = true;
      const { api } = getEnv(self);
      const { auth } = getRoot<IStore>(self);
      const enrolledPhysicianUnits = auth.user.enrollmentsPhysician.map(enr => enr.unit.id);
      const tests = yield api.tests.getTests(enrolledPhysicianUnits, null, query);
      if (tests != null) {
        tests.forEach((test: ITest) => {
          self.tests.put(test);
        });
      }
      self.initializing = false;
      self.initialized = true;
    }),
    refresh: flow(function* refresh() {
      const { api } = getEnv(self);
      const { auth } = getRoot<IStore>(self);
      const enrolledPhysicianUnits = auth.user.enrollmentsPhysician.map(enr => enr.unit.id);
      const tests = yield api.tests.getTests(enrolledPhysicianUnits, self.mostRecentUpdate);
      if (tests != null) {
        // clear previously loaded results
        self.tests.clear();

        tests.forEach((test: ITest) => {
          const testEntity = self.tests.get(test.id);
          if (testEntity) {
            const { activationCode } = testEntity;
            applySnapshot(testEntity, test);
            test.activationCode = activationCode;
          } else {
            self.tests.put(test);
          }
        });
      }
    }),
    loadMore: flow(function* getTests(query, filter, sort?) {
      self.initializing = true;
      const { api } = getEnv(self);
      const { auth } = getRoot<IStore>(self);

      const enrolledPhysicianUnits = auth.user.enrollmentsPhysician.map(enr => enr.unit.id);
      const tests = yield api.tests.getTests(enrolledPhysicianUnits, null, query, filter, sort);
      if (tests != null) {
        tests.forEach((test: ITest) => {
          const testEntity = self.tests.get(test.id);
          if (testEntity) {
            const { activationCode } = testEntity;
            applySnapshot(testEntity, test);
            test.activationCode = activationCode;
          } else {
            self.tests.put(test);
          }
        });
      }

      self.initializing = false;
      self.initialized = true;
    }),
    getTestWithQueryAndFilter: flow(function* getTests(query, filter, sort?) {
      self.initializing = true;
      const { api } = getEnv(self);
      const { auth } = getRoot<IStore>(self);

      const enrolledPhysicianUnits = auth.user.enrollmentsPhysician.map(enr => enr.unit.id);
      const tests = yield api.tests.getTests(enrolledPhysicianUnits, null, query, filter, sort);
      if (tests != null) {
        self.tests.clear();
        tests.forEach((test: ITest) => {
          self.tests.put(test);
        });
      }

      self.initializing = false;
      self.initialized = true;
    }),
    removeTest: (id: id): void => {
      self.tests.delete(id);
    },

    addTest: flow(function* addTest(
      unit: IEnrollmentUnit,
      enrollment: IEnrollment,
      offline: boolean,
      numberOfRecordings: number,
      language: string,
      patient: PatientInfo,
      sensor_type: any,
      allowed_extensions: any,
      diagnostic_nights_needed: any,
      surveys: any,
      // consents_requested: any,
      fulfillment: boolean,
      address?: Address,
      protocol?: any,
    ) {
      try {
        const { api } = getEnv(self);
        const newTestData = yield api.tests.createTest(
          unit,
          enrollment,
          offline,
          numberOfRecordings,
          language,
          patient,
          sensor_type,
          allowed_extensions,
          diagnostic_nights_needed,
          surveys,
          // consents_requested,
          fulfillment,
          address,
          protocol,
        );
        const newTest = Test.create(newTestData);
        self.tests.put(newTest);
        self.errors = '';
        logEvent('TEST_CREATED', { test_id: newTest.id });
        return newTest;
      } catch (err) {
        console.error(err);
        self.errors = "Couldn't create test";
      }
      return undefined;
    }),

    addSimpleTest: flow(function* addSimpleTest(
      email: Email,
      nights: number,
      fulfillment: boolean,
      unit: IEnrollmentUnit,
      enrollment: IEnrollment,
      language: string,
      config: any,
    ) {
      try {
        const { api } = getEnv(self);
        const code = yield api.tests.createSimpleTest(email, fulfillment, nights, unit, enrollment, language, config);
        self.errors = '';
        return code;
      } catch (err) {
        console.error(err);
        self.errors = "Couldn't create simple test";
      }
      return undefined;
    }),

    fetchReports: flow(function* fetchReports(tests: ITest[]) {
      const recordingMap = tests.reduce((acc, test) => {
        test.recordings.forEach(rec => {
          acc[rec.id] = rec;
        });
        return acc;
      }, {});

      const recordingIds = Object.keys(recordingMap);

      const testReports = yield fetchTestReports(
        { test: tests.map(test => test.id), approved: true, current_report: true },
        undefined,
        0,
        tests.map(test => test.id).length,
      );
      const recordingReports = yield fetchRecordingReports(
        { recording: recordingIds, approved: true, current_report: true },
        undefined,
        0,
        recordingIds.length,
      );

      testReports.forEach(report => {
        const test = self.tests.get(report.test);
        test?.setReport(report);
      });

      recordingReports.forEach(report => {
        recordingMap[report.recording].setReport(report);
      });
    }),

    fetchReportsForSingleTest: flow(function* fetchReports(tests: ITest[], recIds: string[]) {
      const recordingIds = recIds;

      const recordingMap = recordingIds.reduce((acc, id) => {
        acc[id] = {};
        return acc;
      }, {});

      // fetches summary report of test
      const testReports = yield fetchTestReports(
        { test: tests.map(test => test.id), approved: true, current_report: true },
        undefined,
        0,
        tests.map(test => test.id).length,
      );

      // fetches individual report of night
      const recordingReports = yield fetchRecordingReports(
        { recording: recordingIds, approved: true, current_report: true },
        undefined,
        0,
        recordingIds.length,
      );

      testReports.forEach(report => {
        const test = self.tests.get(report.test);
        test?.setReport(report);
      });

      recordingReports.forEach(report => {
        recordingMap[report.recording] = report;
      });
      return recordingMap;
    }),
  }));

export interface ITestsStore extends Instance<typeof Tests> {}
export interface ITestsStoreSnapshotIn extends SnapshotIn<typeof Tests> {}
export interface ITestsStoreSnapshotOut extends SnapshotOut<typeof Tests> {}
