import { Formik } from 'formik';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import * as yup from 'yup';

import { BirthdayInputControlled } from 'containers/Dashboard/components/BirthdayInputControlled';
import { FormInput } from 'core/components/FormInput';

import './UserForm.css';
import { getScopedI18n, getSurveyLanguage } from 'core/i18n';

const tl = getScopedI18n('input_labels', { locale: getSurveyLanguage() });

const validationSchema = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  dob: yup
    .date()
    .required()
    .min('1900-01-01T00:00:00.000Z')
    .max(new Date()),
  phone: yup.string().matches(/^\+?(?:[0-9] ?){6,14}[0-9]$/, { excludeEmptyString: true }),
});

interface Props {
  submitting: boolean;
  onSubmit(values: any): void;
}

export const UserForm: React.FC<Props> = ({ onSubmit, submitting }) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={{}} validationSchema={validationSchema}>
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormInput
            name="first_name"
            value={values.first_name}
            isInvalid={!!errors.first_name}
            onChange={handleChange}
            label={`${tl('firstname')}*`}
          />
          <FormInput
            name="last_name"
            value={values.last_name}
            isInvalid={!!errors.last_name}
            onChange={handleChange}
            label={`${tl('lastname')}*`}
          />
          <FormInput
            name="email"
            type="email"
            value={values.email}
            isInvalid={!!errors.email}
            onChange={handleChange}
            label={`${tl('email')}*`}
          />
          <FormInput name="phone" value={values.phone} isInvalid={!!errors.phone} onChange={handleChange} label={tl('phone')} />
          <BirthdayInputControlled
            label={`${tl('birthday')}*`}
            name="dob"
            value={values.dob}
            isInvalid={!!errors.dob}
            onChange={handleChange}
          />
          <div className="app_btn_dv">
            <button type="submit" className="btn btn-primary btn-lg px-5 mt-4" disabled={submitting}>
              {submitting ? <Spinner animation="border" variant="light" /> : 'Start'}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
