import React from 'react';

import { getScopedI18n, getSurveyLanguage } from 'core/i18n';

const locale = getSurveyLanguage();

const t = getScopedI18n('survey_assessment', { locale });
const tq = getScopedI18n('survey_assessment.questions', { locale });
const ta = getScopedI18n('survey_assessment.answers', { locale });
const tr = getScopedI18n('survey_assessment.result', { locale });

export enum RESULT_TYPE {
  sleep_apnea = 'Sleep apnea',
  insomnia = 'Insomnia',
  snoring = 'Snoring',
  sleep_health = 'Sleep health',
}

export const QuestionType = {
  OSA: {
    value: 'OSA',
    bands: {
      band1: 'band1',
      band2: 'band2',
    },
  },
  weight: {
    value: 'weight',
  },
  height: {
    value: 'height',
  },
  sleepHealth: {
    value: 'sleep_health',
  },
  snoring: {
    value: 'snoring',
  },
  insomnia: {
    value: 'insomnia',
  },
  gender: {
    value: 'gender',
  },
};

export enum SNORING_TYPE {
  positional = 'positional',
  mouth = 'mouth',
  nose = 'nose',
  partner = 'partner',
}

export enum OSA_RISK_TYPE {
  OSAHighRisk = 'high_risk',
  OSAAtRisk = 'moderate_risk',
  OSALowRisk = 'low_risk',
}

export enum INSOMNIA_RISK_TYPE {
  HighRisk = 'high_risk',
  AtRisk = 'at_risk',
  LowRisk = 'low_risk',
  ModerateRisk = 'moderate_risk',
  InsomniaAndPain = 'at_risk_with_pain',
}

export const SortResults = (results, pains) => {
  if (results.length === 1) {
    return results;
  }

  // insomnia pains, it should be on the top
  const riskValues = {
    [OSA_RISK_TYPE.OSAHighRisk]: 1,
    [INSOMNIA_RISK_TYPE.HighRisk]: 1,
    [OSA_RISK_TYPE.OSAAtRisk]: 2,
    [INSOMNIA_RISK_TYPE.AtRisk]: 2,
    [INSOMNIA_RISK_TYPE.ModerateRisk]: 3,
    [OSA_RISK_TYPE.OSALowRisk]: 4,
    [INSOMNIA_RISK_TYPE.LowRisk]: 4,
  };

  const updatedResults = [...results];
  if (pains > 0) {
    updatedResults.reverse();
  } else {
    updatedResults.sort((a, b) => {
      return riskValues[a.indicator_severity] - riskValues[b.indicator_severity];
    });
  }

  return updatedResults;
};

export const SLEEP_HEALTH = {
  key_1: {
    value: 'sleep_health1',
    answers: {
      light: 'light',
      fragmented: 'fragmented',
      interrupted: 'interrupted',
    },
  },
  key_2: {
    value: 'sleep_health2',
    answers: {
      yes: 'yes',
    },
  },
  key_3: {
    value: 'sleep_health3',
    answers: {
      yes: 'yes',
    },
  },
};

export function SleepHealthMetadata(sleepHealthRecommendations, locale = 'en') {
  if (sleepHealthRecommendations.length > 0) {
    return {
      title: RESULT_TYPE.sleep_health,
      severity_title: Object.keys(sleepHealthRecommendations)
        .map(recommendation =>
          t(`survey_assessment.sleep_health.${recommendation}.${sleepHealthRecommendations[recommendation]}`, { locale }),
        )
        .join(', '),
      care_recommendation: tr('sleep_health.recommendation', { locale }),
      definition: tr('sleep_health.definition', { locale }),
      definition_link: 'https://www.resmed.com.au/sleep-health/about-sleep-health',
    };
  }
}

export const OSARiskMetaData = (riskType, isDiagnoisedCOPDHeartFailure, locale = 'en') => {
  const COPDRecommendation = tr('apnea.recommendations.copd', { locale });
  const recommendation =
    riskType === OSA_RISK_TYPE.OSAHighRisk || riskType === OSA_RISK_TYPE.OSAAtRisk
      ? tr('apnea.recommendations.risk')
      : tr('apnea.recommendations.low_risk');
  return {
    indicator_severity: tr(riskType, { locale }),
    severity_title: tr(riskType, { locale }),
    care_recommendation: isDiagnoisedCOPDHeartFailure ? COPDRecommendation : recommendation,
    definition: tr('apnea.definition', { locale }),
    definition_link: 'https://www.resmed.com.au/sleep-apnea/what-is-sleep-apnea',
  };
};

export const SnoringMetadata = (snoringRisks, locale = 'en') => {
  const snoringData = [];
  if (snoringRisks.length > 0) {
    if (snoringRisks[0] !== SNORING_TYPE.partner) {
      const risk = snoringRisks[0];
      const rt = getScopedI18n(`survey_assessment.result.snoring.${risk}`, { locale });
      snoringData.push({
        title: rt(`title`),
        severity_title: rt(`severity`),
        care_recommendation: rt('recommendation'),
        question: rt('question'),
        definition: rt('definition'),
        definition_link: 'https://www.resmed.com.au/snoring/how-to-stop-snoring',
      });
    }

    if (snoringRisks.includes(SNORING_TYPE.partner) && snoringData.length === 0) {
      if (snoringData.length === 0) {
        const rt = getScopedI18n(`survey_assessment.result.snoring.${SNORING_TYPE.partner}`, { locale });
        snoringData.push({
          title: rt(`title`),
          subTitle: rt('subtitle'),
          severity_title: rt(`severity`),
          care_recommendation: rt('recommendation'),
          primary_link_text: 'Download MyNight',
          primary_link: 'https://www.resmed.com.au/mynight',
          question: rt('question'),
          definition: rt('definition'),
          definition_link: 'https://www.resmed.com.au/snoring/what-is-snoring',
        });
      }
    }
  }

  return snoringData;
};

export const InsomniaMetadata = (riskType, pains, locale = 'en') => {
  const riskTranslation = tr(riskType, { locale });
  let severity_title = tr('insomnia.risk_title', { risk: riskTranslation, locale });
  let care_recommendation = tr('insomnia.recommendations.normal', { locale });

  if (pains > 0) {
    severity_title = tr('insomnia.risk_pain_title', { risk: riskTranslation, locale });
    care_recommendation = tr('insomnia.recommendations.pain', { locale });
  }

  if (riskType === INSOMNIA_RISK_TYPE.LowRisk) {
    care_recommendation = tr('insomnia.recommendations.none', { locale });
  }

  return {
    indicator_severity: tr(riskType, { locale }),
    severity_title,
    care_recommendation,
    definition: tr('insomnia.definition', { locale }),
    definition_link: 'https://www.resmed.com.au/insomnia/what-is-insomnia',
  };
};

export enum PhysioSigns {
  snoring = 'snoring',
  hold_breath = 'hold_breath',
  gasping = 'choking',
  sleepy = 'sleepy',
  dry_mouth = 'dry_mouth',
  heartburn = 'heartburn',
  blood_pressure = 'blood_pressure',
  headaches = 'headaches',
}

export const Sleep_Assessment_Questions = (name: string): NOSurveyQuestion[] => {
  return [
    {
      question_key: 'satisfaction',
      question: tq('satisfaction', { name }),
      answers: ['very_satisfied', 'satisfied', 'moderately_satisfied', 'dissatisfied', 'very_dissatisfied'],
      qType: 'select',
    },
    {
      question_key: 'sleep_description',
      question: tq('sleep_description'),
      answers: [
        SLEEP_HEALTH.key_1.answers.light,
        SLEEP_HEALTH.key_1.answers.interrupted,
        SLEEP_HEALTH.key_1.answers.fragmented,
        'deep',
        'great',
        'terrible',
      ],
      answersWeight: {
        [SLEEP_HEALTH.key_1.answers.light]: 1,
        [SLEEP_HEALTH.key_1.answers.interrupted]: 1,
        [SLEEP_HEALTH.key_1.answers.fragmented]: 1,
        deep: 0,
        great: 0,
        terrible: 2,
      },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes, QuestionType.sleepHealth.value as NOSurveyQuestionTypes],
      key: SLEEP_HEALTH.key_1.value,
      qType: 'select',
    },
    {
      question_key: 'sleep_hours',
      question: tq('sleep_hours'),
      answers: ['less_5_hours', '5_to_7_hours', '7_to_9_hours', 'more_9_hours'],
      answersWeight: {
        less_5_hours: 4,
        '5_to_7_hours': 2,
        '7_to_9_hours': 0,
        more_9_hours: 0,
      },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'sleep_related_pain',
      question: tq('sleep_related_pain'),
      answers: ['never', 'less_once_week', 'once_twice_week', 'three_more_week'],
      answersWeight: {
        never: 0,
        less_once_week: 0,
        once_twice_week: 'PAIN',
        three_more_week: 'PAIN',
      },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'feel_sleepy',
      question: tq('feel_sleepy'),
      answers: ['yes', 'no'],
      answersWeight: {
        yes: 2,
        no: 0,
      },
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes],
      sub_type: QuestionType.OSA.bands.band1,
      physioSign: PhysioSigns.sleepy,
      qType: 'select',
    },
    {
      question_key: 'teeth_grinding',
      question: tq('teeth_grinding'),
      answers: ['yes', 'no', 'not_sure'],
      type: [QuestionType.snoring.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'difficult_falling_asleep',
      question: tq('difficult_falling_asleep'),
      answers: ['no_problem', 'few_issues', 'moderately', 'severe', 'very_severe'],
      answersWeight: { no_problem: 0, few_issues: 1, moderately: 2, severe: 3, very_severe: 4 },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'difficult_staying_asleep',
      question: tq('difficult_staying_asleep'),
      answers: ['no_problem', 'few_issues', 'moderately', 'severe', 'very_severe'],
      answersWeight: { no_problem: 0, few_issues: 1, moderately: 2, severe: 3, very_severe: 4 },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'waking_early',
      question: tq('waking_early'),
      answers: ['no_problem', 'few_issues', 'moderately', 'severe', 'very_severe'],
      answersWeight: { no_problem: 0, few_issues: 1, moderately: 2, severe: 3, very_severe: 4 },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'negative_impact',
      question: tq('negative_impact'),
      answers: ['none', 'little', 'somewhat', 'moderately', 'alot'],
      answersWeight: { none: 0, little: 1, somewhat: 2, moderately: 3, alot: 4 },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'insomnia_worried_about_your_sleep',
      question: tq('sleep_worried'),
      answers: ['not_at_all', 'little', 'somewhat', 'moderately', 'alot'],
      answersWeight: { not_at_all: 0, little: 1, somewhat: 2, moderately: 3, alot: 4 },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'snore_self',
      question: tq('snore_self'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 2, no: 0 },
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes, QuestionType.snoring.value as NOSurveyQuestionTypes],
      sub_type: QuestionType.OSA.bands.band1,
      filters: { answer: 'no', skipToIndex: 14 },
      physioSign: PhysioSigns.snoring,
      qType: 'select',
    },
    {
      question_key: 'snore_position',
      question: tq('snore_position'),
      answers: ['on_back', 'on_side', 'any_position', 'no_recall'],
      answersWeight: {
        on_back: 'BACK',
        on_side: 'SIDE',
        any_position: 'ANY',
        no_recall: 'DONTR',
      },
      type: [QuestionType.snoring.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'dry_mouth',
      question: tq('dry_mouth'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 'Mouth', no: 'Nose' },
      type: [QuestionType.snoring.value as NOSurveyQuestionTypes],
      physioSign: PhysioSigns.dry_mouth,
      qType: 'select',
    },
    {
      question_key: 'snore_partner',
      question: tq('snore_partner'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 'PARTNER', no: 0 },
      type: [QuestionType.snoring.value as NOSurveyQuestionTypes, QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'gasping_breath',
      question: tq('gasping_breath'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 1, no: 0 },
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes],
      sub_type: QuestionType.OSA.bands.band1,
      physioSign: PhysioSigns.gasping,
      qType: 'select',
    },
    {
      question_key: 'hold_breath',
      question: tq('hold_breath'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 1, no: 0 },
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes],
      sub_type: QuestionType.OSA.bands.band1,
      physioSign: PhysioSigns.hold_breath,
      qType: 'select',
    },
    {
      question_key: 'headaches',
      question: tq('headaches'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 1, no: 0 },
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes],
      sub_type: QuestionType.OSA.bands.band2,
      physioSign: PhysioSigns.headaches,
      qType: 'select',
    },
    {
      question_key: 'heartburn',
      question: tq('heartburn'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 1, no: 0 },
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes, QuestionType.sleepHealth.value as NOSurveyQuestionTypes],
      sub_type: QuestionType.OSA.bands.band2,
      key: SLEEP_HEALTH.key_2.value,
      physioSign: PhysioSigns.heartburn,
      qType: 'select',
    },
    {
      question_key: 'blood_pressure',
      question: tq('blood_pressure'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 1, no: 0 },
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes],
      sub_type: QuestionType.OSA.bands.band1,
      physioSign: PhysioSigns.blood_pressure,
      qType: 'select',
    },
    {
      question_key: 'restless_legs',
      question: tq('restless_legs'),
      answers: ['yes', 'no', 'not_sure'],
      answersWeight: { yes: 'RLS', no: 0, not_sure: 0 },
      type: [QuestionType.sleepHealth.value as NOSurveyQuestionTypes],
      key: SLEEP_HEALTH.key_3.value,
      qType: 'select',
    },
    {
      question_key: 'disorder_medication',
      question: tq('disorder_medication'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 1, no: 0 },
      type: [QuestionType.insomnia.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'copd',
      question: tq('copd'),
      answers: ['yes', 'no'],
      answersWeight: { yes: 1, no: 0 },
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes],
      qType: 'select',
    },
    {
      question_key: 'bmi',
      question: tq('bmi'),
      type: [
        QuestionType.OSA.value as NOSurveyQuestionTypes,
        QuestionType.height.value as NOSurveyQuestionTypes,
        QuestionType.weight.value as NOSurveyQuestionTypes,
      ],
      sub_type: QuestionType.OSA.bands.band2,
      qType: 'input',
    },
    {
      question_key: 'motivation',
      question: tq('motivation'),
      answers: ['loved_one', 'doctor', 'energy', 'memory', 'general', 'other'],
      qType: 'select',
    },
    {
      question_key: 'gender',
      question: tq('gender'),
      answers: ['female', 'male', 'other'],
      type: [QuestionType.OSA.value as NOSurveyQuestionTypes, QuestionType.gender.value as NOSurveyQuestionTypes],
      sub_type: QuestionType.OSA.bands.band2,
      qType: 'select',
    },
  ];
};
