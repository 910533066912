import { Color } from 'csstype';
import React from 'react';
import { IconBaseProps } from 'react-icons';
import {
  FaBars,
  FaCheck,
  FaChevronLeft,
  FaComment,
  FaEdit,
  FaEnvelope,
  FaExclamationCircle,
  FaFilePdf,
  FaPlus,
  FaPrint,
  FaQrcode,
  FaQuestion,
  FaRegCircle,
  FaRegComment,
  FaRegQuestionCircle,
  FaRegUserCircle,
  FaSearch,
  FaSignOutAlt,
  FaSyncAlt,
  FaTrashAlt,
  FaInfo,
  FaDownload,
  FaMinus,
  FaMapMarkerAlt,
  FaChartArea,
  FaWindowClose,
  FaPen,
  FaUndo,
} from 'react-icons/fa';

const iconMap = {
  plus: FaPlus,
  minus: FaMinus,
  edit: FaEdit,
  check: FaCheck,
  question: FaQuestion,
  'question-circle-outline': FaRegQuestionCircle,
  bars: FaBars,
  'user-circle-outline': FaRegUserCircle,
  search: FaSearch,
  'chevron-left': FaChevronLeft,
  'warning-circle': FaExclamationCircle,
  'circle-outline': FaRegCircle,
  qrcode: FaQrcode,
  comment: FaComment,
  'comment-outline': FaRegComment,
  sync: FaSyncAlt,
  trash: FaTrashAlt,
  print: FaPrint,
  envelope: FaEnvelope,
  'sign-out': FaSignOutAlt,
  'file-pdf': FaFilePdf,
  info: FaInfo,
  download: FaDownload,
  marker: FaMapMarkerAlt,
  'area-graph': FaChartArea,
  undo: FaUndo,
  close: FaWindowClose,
  pen: FaPen,
};

export type IconNames = keyof typeof iconMap;

interface Props extends IconBaseProps {
  name: IconNames;
  color?: Color;
}

export const Icon: React.FC<Props> = ({ name, ...props }) => {
  const MappedIcon = iconMap[name];
  if (MappedIcon) {
    return <MappedIcon {...props} />;
  }
  return <FaQuestion {...props} />;
};
