/* eslint-disable max-len */
export default {
  general: {
    save: 'Speichern',
    edit: 'Redigieren',
    yes: 'Ja',
    no: 'Nein',
    cancel: 'Abbrechen',
    submit: 'Absenden',
    confirm: 'Bestätigen',
    done: 'Erledigt',
    loading: 'Lädt',
    print: 'Drucken',
    update: 'Aktualisieren',
    enable: 'Aktivieren',
    disable: 'Deaktivieren',
    next: 'Weiter',
    back: 'Zurück',
    send: 'Versenden',
    email: 'Email',
    help: 'Hilfe',
    day: 'Tag',
    month: 'Monat',
    year: 'Jahr',
  },
  months: {
    jan: 'Januar',
    feb: 'Februar',
    mar: 'März',
    apr: 'April',
    may: 'Mai',
    jun: 'Juni',
    jul: 'Juli',
    aug: 'August',
    sep: 'September',
    oct: 'Oktober',
    nov: 'November',
    dec: 'Dezember',
  },
  input_labels: {
    email: 'Email',
    phone: 'Telefon',
    password: 'Passwort',
    password_confirm: 'Passwort bestätigen',
    password_new: 'Neues Passwort',
    password_old: 'Altes Passwort',
    simple: 'Einfach',
    select_unit: 'Zentrum auswählen',
    unit: 'Zentrum',
    lastname: 'Nachname',
    firstname: 'Vorname',
    birthday: 'Geburtstag',
    birthday_and_format: 'Geburtstag (YYYY-MM-TT)',
    language: 'Sprache',
    fulfillment: 'Erfüllung durch Ectosense',
    address: 'Adresse',
    city: 'Stadt',
    region: 'Bundesland / Landkreis / Region',
    zip: 'Postleitzahl',
    country: 'Land',
    offline: 'Offline-Test',
    nights: 'Nächte',
    verification_code: 'Verifizierungs-Schlüssel',
  },
  validation_erros: {
    required_field: '{{field}} ist erforderlich',
    invalid_email: 'Ungültige E-Mail',
    invalid_password: 'Ungültiges Passwort',
    invalid_phone_require_international:
      'Die angegebene Telefonnummer ist ungültig. Bitte geben Sie eine Nummer im internationalen Format an.',
  },
  locales: {
    english: 'Englisch',
    dutch: 'Niederländisch',
    french: 'Französisch',
    portuguese: 'Portugiesisch',
    german: 'Deutsch',
    spanish: 'Spanisch',
  },
  roles: {
    PHYSICIAN: 'Gesundheits-Experte',
    ADMIN: 'Administrator',
  },
  password: {
    requirements: {
      req_intro_header: 'Passwortanforderungen',
      req_intro: 'Passwort muss',
      not_met: 'Passwortanforderungen nicht erfüllt',
      num_characters: 'Zumindest {{amount}} Zeichen',
      num_lowercase: {
        one: 'Muss mindestens {{amount}} Kleinbuchstaben enthalten',
        other: 'Muss mindestens {{amount}} Kleinbuchstaben enthalten',
      },
      num_uppercase: {
        one: 'Muss mindestens {{amount}} Großbuchstabe enthalten',
        other: 'Muss mindestens {{amount}} Großbuchstabe enthalten',
      },
      num_number: {
        one: 'Muss mindestens {{amount}} Nummer enthalten',
        other: 'Muss mindestens {{amount}} Nummer enthalten',
      },
    },
    error: {
      no_match: 'Passwörter gleichen sich nicht',
    },
  },
  auth: {
    login: {
      title: 'Wilkommen bei NightOwl',
      email_input_label: 'Mit Email Adresse eingeben',
      button: 'Anmelden',
      messages: {
        incorrect: 'Entschuldigung, Sie haben falsche Anmeldeinformationen eingegeben. Bitte versuchen Sie erneut.',
        error: 'Entschuldigung, wir konnten Sie nicht anmelden. Bitte versuchen Sie erneut.',
      },
    },
    '2fa': {
      title: 'Two Factor Authentication',
      button: 'Submit',
      messages: {
        info_sms: 'Es wurde eine Textnachricht mit einem Bestätigungscode an Sie gesendet. Bitte geben Sie den empfangenen Code ein',
        info_email: 'Es wurde eine E-Mail mit einem Bestätigungscode an Sie gesendet. Bitte geben Sie den erhaltenen Code ein',
        info_authenticator: 'Geben Sie einen Code aus Ihrer Authentifizierungs-App ein',
        incorrect: 'Der von Ihnen angegebene Code war falsch. Bitte versuchen Sie es erneut',
        error: 'Es ist ein Fehler aufgetreten. Versuchen Sie es erneut',
      },
    },
    reset_password: {
      title: 'Passwort zurücksetzen',
      success_button: 'Erfolg',
      action_button: 'Passwort ändernd',
    },
    forgot_password: {
      title: 'Passwort vergessen?',
      success_button: 'Prüfen Sie Ihre Mails',
      action_button: 'Passwort zurücksetzen',
      errors: {
        invalid_email: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
        unknown_email: 'Für die angegebene E-Mail-Adresse wurde kein Benutzer gefunden',
        unknown: 'Etwas ist schief gelaufen. Bitte versuche es erneut',
      },
    },
    invite: {
      title: 'Wilkommen bei NightOwl',
      main: {
        intro: 'Lieber {{firstname}} {{lastname}},',
        body: 'Sie wurden mit den folgenden Rollen zum {{unit}} Zentrum eingeladen.',
        button: 'Einladun annhemen',
      },
      user_logged_in: {
        body: 'Ein anderer Benutzer ist bereits eingeloggt.',
        button: 'Den anderen Benutzer abmelden',
      },
    },
    activate: {
      password_label: 'Ein Passwort wählen ',
      confirm_password_label: 'Passwort bestätigen',
      button: 'Account aktivieren',
      consent: {
        body: 'Durch die Aktivierung meines Kontos akzeptiere ich die ',
        url: 'NightOwl informierte Zustimmung',
      },
      password_notification_trigger: 'Dies löst eine E-Mail aus, in der Sie benachrichtigt werden, dass sich Ihr Passwort geändert hat.',
    },
    toasts: {
      reset_email: 'E-Mail zum Zurücksetzen des Passworts gesendet',
      password_change_error: 'Fehler beim Versuch, das Passwort zu ändern',
    },
    footer: {
      no_account_yet: 'Konto erstellen',
      forgot_password: 'Passwort vergessen?',
    },
  },
  dashboard: {
    title: 'Ergebnisse',
    subtitle: 'Alle Testergebnisse für Ihre Patienten',
    no_tests: 'Es sind keine Tests zum Anzeigen vorhanden',
    all_units: 'Alle Zentren',
    header: {
      search_placeholder: 'Suchen...',
      new_test: 'Neuer Test',
    },
    labels: {
      new: 'NEU',
      awaiting: 'ERWARTET',
      error: 'FEHLER',
      report: 'BERICHT',
      summary: 'ZUSAMMENFASSUNG',
    },
    actions: {
      mail_report: 'Bericht an den Patienten versenden',
      delete: 'Test löschen',
      request_feedback: 'Feedback anfordern',
      download_edf: 'Laden Sie den EDF herunter',
    },
    table_header: {
      refresh_popup: 'Am {{date}} zuletzt aktualisiert',
    },
    columns: {
      patient: 'Patient',
      uploaded_nights: 'Hochgeladene Nächte',
      recording_date: 'Aufnahmedatum',
      creation_date: 'Erstellungsdatum',
      status: 'Status',
      actions: 'Aktionen',
    },
    filter_states: {
      all: 'Alle',
      in_progress: 'In Bearbeitung',
      completed: 'Abgeschlossen',
      new: 'Neu',
      error: 'Fehler',
    },
    modals: {
      comment: {
        title: 'Bemerkungen',
        toast_error: 'Fehler beim Hinzufügen eines Kommentars',
        toast_success: 'Kommentar hinzugefügt',
        toast_error_update: 'Fehler beim Aktualisieren des Kommentars',
        toast_success_update: 'Kommentar aktualisiert',
      },
      mail_report: {
        title: 'Bericht versenden',
        body: 'Der Bericht wird an die folgende E-Mail-Adresse gesendet. Bei Bedarf können Sie dies hier ändern.',
        toast_error: 'Fehler beim Senden des Berichts',
        toast_success: 'Bericht gesendet',
      },
      create: {
        patient_title: 'Informationen des Patienten',
        test_title: 'Über den Test',
        fulfillment_popup: 'Die Lieferung des NightOwl-Testkits Ectosense überlassen',
        simple_popup: 'Lassen Sie Ihren Patienten die erforderlichen Informationen eingeben',
        offline_popup: 'Offline-Tests sind auf 3 Nächte begrenzt, der Patient benötigt kein Smartphone, um den Test abzuschließen',
        suspended_unit: 'Für suspendierte Zentren können keine neuen Tests erstellt werden. Wenden Sie sich an Ihren Administrator',
        button_ack: 'Test erstellen',
        no_edit_confirm: {
          one: 'Möchten Sie wirklich einen Test für eine Nacht starten?',
          other: 'Möchten Sie wirklich einen Test für {{nights}} Nächte starten?',
        },
        toasts: {
          email_sent: 'E-Mail erfolgreich gesendet',
          email_sending: 'E-Mail wird gesendet',
          success: 'Test erfolgreich erstellt',
          missing_info: 'Fehlende Information',
          error: 'Test konnte nicht erstellt werden',
        },
      },
      delete: {
        title: 'Test löschen',
        body: 'Möchten Sie diesen Test wirklich aus {{info}} löschen?',
        toast_success: 'Test erfolgreich gelöscht',
        toast_error: 'Fehler beim Löschen des Tests',
      },
      feedback: {
        title: 'Feedback anfordern',
        toast_success: 'Feedback geschickt',
        toast_error: 'Fehler beim Senden des Feedbacks',
      },
      qr_modal: {
        header: 'Aktivierungscode: ',
        toast_success: 'E-Mail gesendet',
        print_instructions_button: 'Anweisungen Drucken',
        print_qr_button: 'QR-Code Drucken',
        mail_button: {
          done: 'E-Mail gesendet!',
          not_set: 'Kein E-Mail Set',
          send: 'E-Mail senden',
        },
      },
      report_viewer: {
        title: 'Report Viewer',
      },
      edit: {
        title: 'Informationen des Patienten',
        toast: {
          error: 'Konnte den Test nicht aktualisieren',
          success: 'Test aktualisiert',
        },
      },
    },
    load_more: 'Mehr laden',
  },
  unit: {
    physicians: 'Medizinische Fachpersonen',
    admins: 'Administratoren',
    statistics_undefined: 'Beim Abrufen der Gerätestatistik ist ein Fehler aufgetreten',
    buttons: {
      edit: 'Zentrum bearbeiten',
      invite: 'Mitglied einladen',
      retry: 'Wiederhohlen',
    },
    statistics: {
      tests: 'Tests',
      new_reports: 'Neue Berichte',
      credits: 'Kredit',
      physicians: 'Medizinische Fachpersonen',
    },
    columns: {
      name: 'Name',
      joined: 'Beigetreten',
      status: 'Status',
    },
    suspended: {
      body_wo_name: 'Dieses Gerät ist aufgehängt. Neue Tests können erst nach erneuter Aktivierung des Geräts gestartet werden.',
      body_with_name: 'Gerät {{name}} ist aufgehängt. Neue Tests können erst nach erneuter Aktivierung des Geräts gestartet werden.',
    },
    user: {
      unknown: 'Unbekannter Benutzer',
      actions: {
        disable: 'deaktivieren',
        reactivate: 'reaktivieren',
        resend: 'erneut senden',
      },
      status: {
        invited: 'eingeladen',
        active: 'aktiv',
        disabled: 'deaktiviert',
        undefined: 'unbekannte',
      },
      toasts: {
        change_error: 'Fehler beim Ändern des Status',
        resend_error: 'Fehler beim erneuten Senden der Einladung',
        resend_success: 'Einladung erfolgreich erneut gesendet',
      },
    },
    modals: {
      invite: {
        title: 'Einladen',
        button_invite: 'Einladen',
      },
      edit: {
        title: 'Zentrum bearbeiten',
        name_label: 'Name des Zentrums',
        toast_error: 'Fehler bei der Aktualisierung des Zentrums',
        toast_success: 'Zentrum erfolgreich aktualisiert',
      },
    },
  },
  unit_edit: {
    details: {
      title: 'Zentrum Details',
      name: 'Name des Zentrums',
      btn_edit: 'Details bearbeiten',
    },
    settings: {
      title: 'Zentrum Einstellungen',
      simple: 'Neue Tests sind standardmäßig auf einfache Konfiguration eingestellt',
      nights: 'Standardanzahl der Nächte für neue Tests',
      two_fa_enforced: '',
      btn_edit: 'Einstellungen bearbeiten',
    },
    modals: {
      details: {
        title: 'Zentrum-Details bearbeiten',
        name_label: 'Name des Zentrums',
        toast_error: 'Fehler bei der Aktualisierung des Zentrums',
        toast_success: 'Zentrum erfolgreich aktualisiert',
      },
      settings: {
        title: 'Zentrum-Einstellungen bearbeiten',
        simple: 'Neue Tests sind standardmäßig auf einfache Konfiguration eingestellt',
        nights: 'Standardanzahl der Nächte für neue Tests',
        two_fa_enforced: 'Erzwingen Sie die Zwei-Faktor-Authentifizierung für registrierte Benutzer',
        toast_error: 'Fehler bei der Aktualisierung des Zentrums',
        toast_success: 'Zentrum erfolgreich aktualisiert',
      },
    },
  },

  units: {
    title: 'Zentren',
    subtitle: 'Sie verwalten die folgenden Zentren',
    button_new: 'Neues Zentrum',
    unit_card: {
      suspended: 'suspendiert',
      new_reports: 'Neue Berichte',
      credits: 'Guthaben',
      physician: {
        one: '{{count}} Medizinische Fachperson',
        other: '{{count}} Medizinische Fachpersonen',
      },
    },
    create_modal: {
      s1: {
        title: 'Neue Zentrumdetails',
        labels: {
          name: 'Zentrumsname',
          balance: 'Credits',
        },
      },
      s2: {
        title: 'Erster Benutzer für {{name}}',
        button: 'Zentrum erstellen',
      },
    },
  },
  account: {
    title: 'Konto',
    subtitle: '',
    log_out: 'Abmelden',
    modals: {
      edit_password: {
        toast_error: 'Passwort konnte nicht aktualisiert werden',
        toast_success: 'Passwort aktualisiert',
        unauth_header: 'Nicht autorisiert',
        unauth_content: 'Das angegebene alte Passwort gleicht dem aktuellen Passwort nicht',
        reasons: {
          outdated: {
            header: 'Richtlinie zur Kennwortrotation',
            content: 'Gemäß unserer Richtlinie zur Kennwortrotation müssen Sie Ihr Kennwort alle 60 Tage aktualisieren',
          },
        },
      },
      edit_user: {
        warn_firstname: 'Vorname darf nicht leer sein',
        warn_lastname: 'Nachname darf nicht leer sein',
        warn_email: 'Email ist nicht gültig',
        toast_error: 'Profil konnte nicht aktualisiert werden',
        toast_success: 'Profil aktualisiert',
      },
      enable_two_fa: {
        title: 'Zwei-Faktor-Authentifizierung',
        subtitle: 'Wählen Sie die gewünschte Methode',
        alert: {
          Enforced2FA: 'Für eines der Zentren, in denen Sie registriert sind, ist eine Zwei-Faktor-Authentifizierung erforderlich',
        },
        authenticator: {
          h1: 'Scannen Sie diesen QR-Code mit Ihrer Authentifizierungs-App',
          p1:
            'Scannen Sie das Bild unten mit der Zwei-Faktor-Authentifizierungs-App auf Ihrem Telefon. Wenn Sie einen QR-Code nicht scannen können, verwenden Sie bitte eine andere Methode.',
          h2: 'Geben Sie den 6-stelligen Code aus der Anwendung ein',
          p2: 'Nach dem Scannen des Barcode-Bildes zeigt die App einen 6-stelligen Code an, den Sie hier eingeben müssen:',
        },
        email: {
          p_email: 'Geben Sie die E-Mail-Adresse an, unter der Sie zwei Faktor-Bestätigungscodes erhalten möchten',
          p_verification: 'Bitte geben Sie den Bestätigungscode ein, der an die angegebene E-Mail-Adresse gesendet wurde',
        },
        sms: {
          p_sms: 'Geben Sie die Telefonnummer an (internationales Format), unter der Sie zwei Faktor-Bestätigungscodes erhalten möchten',
          p_verification: 'Bitte geben Sie den Bestätigungscode ein, der an die angegebene Telefonnummer gesendet wurde',
        },
      },
      disable_two_fa: {
        title: 'Zwei-Faktor-Authentifizierung',
        subtitle: 'Bitte bestätigen Sie, dass Sie die folgende Zwei-Faktor-Authentifizierungsmethode deaktivieren möchten',
        verify: {
          instruction_sms: 'Bitte geben Sie den Bestätigungscode ein, der an die registrierte Telefonnummer gesendet wurde',
          instruction_email: 'Bitte geben Sie den Bestätigungscode ein, der an die registrierte E-Mail-Adresse gesendet wurde',
          instruction_authenticator: 'Geben Sie einen Code aus Ihrer Authentifizierungs-App ein',
        },
      },
    },
    personal_codes: {
      title: 'Persönlicher App-Code',
      subtitle: 'Geben Sie Ihre Patienten den folgenden Code, wenn Sie einen neuen Test starten::',
    },
    enrollments: {
      title: 'Meine Einschreibungen',
      unit_col: 'Zentrum',
      roles_col: 'Rollen',
    },
    profile: {
      title: 'Mein Profil',
      name: 'Name',
      edit_profile: 'Profil bearbeiten',
    },
    security: {
      title: 'Sicherheit',
      password: {
        title: 'Passwort',
        change_btn: 'Passwort ändern',
      },
      two_fa: {
        title: 'Zwei-Faktor-Authentifizierung',
        subtitle:
          'Die Zwei-Faktor-Authentifizierung verleiht Ihrem Konto eine zusätzliche Sicherheitsebene, da für die Anmeldung mehr als nur ein Kennwort erforderlich ist.',
        methods: {
          authenticator: 'Authenticator app',
          sms: 'SMS',
          email: 'Email',
        },
        verify_pw: 'Bitte überprüfen Sie Ihr Passwort, bevor Sie fortfahren',
      },
    },
  },
  surveys: {
    title: 'Umfragen',
    all_filter_option: 'Alle Umfragen',
    responses: {
      title: 'Umfragen',
      subtitle: 'Antworten',
    },
    columns: {
      patient: 'Patient',
      survey: 'Umfragen',
      response_date: 'Antwortdatum',
      actions: 'Aktionen',
    },
  },
  survey_assessment: {
    questions: {
      satisfaction: 'Hi {{name}}, how satisfied do you feel about your current sleep?',
      sleep_description: 'How would you describe your sleep (pick most applicable)?',
      sleep_hours: 'On average, how many hours of sleep do you get each night?',
      sleep_related_pain: 'How often have you had trouble sleeping because of pain?',
      feel_sleepy: 'Even after sleeping through the night, do you feel sleepy during the day?',
      teeth_grinding: 'Do you wake up with an aching jaw, or ever been told that you grind your teeth during sleep?',
      difficult_falling_asleep: 'Do you find it difficult to fall asleep?  How severe is this problem for you?',
      difficult_staying_asleep: 'Do you find it difficult to stay asleep throughout the night? How severe is this problem for you?',
      waking_early: 'Do you have issues waking up too early and struggle to fall back asleep? How severe is this problem for you?',
      negative_impact:
        'To what extent does sleep negatively impact your quality of life? (e.g. daytime fatigue, mood, driving performance, work performance,  concentration or memory).',
      sleep_worried: 'How worried are you about your current sleep?',
      snore_self: 'Now let’s look at your night time breathing. Have you been told you snore?',
      snore_position: 'If you can recall, which position do you usually snore in while sleeping?',
      dry_mouth: 'Do you wake up with a dry mouth?',
      snore_partner: 'Do you sleep next to someone who snores?',
      gasping_breath: 'Do you ever wake gasping for breath?',
      hold_breath: 'Have you ever been told you hold your breath while sleeping?',
      headaches: 'Thanks {{name}}. Do you notice that you wake up with headaches in the morning?',
      heartburn: 'Do you experience heartburn or acid reflux, or take medication to treat it?',
      blood_pressure: 'Do you have high blood pressure or are taking medicine to treat it?',
      restless_legs: 'Do you sometimes feel that you have to move your legs to make them feel comfortable?',
      disorder_medication: 'Do you suffer from depression or a mood disorder and are currently taking medication for it?',
      copd: 'Have you ever been diagnosed with COPD, Heart Failure and or use Opioids to treat chronic pain?',
      bmi:
        'Your BMI and age can help us understand your risk factor for certain sleep disorders. Let’s calculate your BMI. Please enter your height and weight?',
      age: 'Thanks. Please indicate your age bracket',
      // eureka: 'Was there a "Eureka/aha!" moment or incident that motivated you into improving your sleep health?',
      motivation: 'What has been your motivation for wanting to improve your sleep?',
      gender: 'Lastly, what best describes your gender?',
    },
    answers: {
      yes: 'Yes',
      no: 'No',
      not_sure: 'Not sure',
      very_satisfied: 'Very satisfied',
      satisfied: 'Satisfied',
      moderately_satisfied: 'Moderately satisfied',
      dissatisfied: 'Dissatisfied',
      very_dissatisfied: 'Very dissatisfied',
      deep: 'Deep',
      great: 'Great',
      terrible: 'Terrible',
      less_5_hours: 'Less than 5 hours',
      '5_to_7_hours': '5 to 7 hours',
      '7_to_9_hours': '7 to 9 hours',
      more_9_hours: 'More than 9 hours',
      never: 'Never',
      less_once_week: 'Less than once a week',
      once_twice_week: 'Once or twice a week',
      three_more_week: 'Three or more times a week',
      no_problem: 'No problem',
      few_issues: 'Few issues',
      moderately: 'Moderately',
      severe: 'Severe',
      very_severe: 'Very severe',
      none: 'None',
      little: 'A little',
      somewhat: 'Somewhat',
      alot: 'A lot',
      not_at_all: 'Not at all',
      on_back: 'On my back',
      on_side: 'On my side',
      any_position: 'In any position',
      no_recall: "Can't recall",
      loved_one: "A loved one's suggestion",
      doctor: "Doctor's recommendation",
      energy: 'Low energy and fatigue',
      memory: 'Memory and performance issues',
      general: 'General health concerns',
      other: 'Other',
      male: 'Male',
      female: 'Female',
      light: 'Light',
      fragmented: 'Fragmented',
      interrupted: 'Could be better',
    },
    sleep_health: {
      sleep_health1: {
        light: 'Light sleeper',
        interrupted: 'Interrupted sleeper',
        fragmented: 'Fragmented sleeper',
      },
      sleep_health2: {
        yes: 'Heartburn/Acid reflux',
      },
      sleep_health3: {
        yes: 'Restless legs',
      },
    },
    physio_signs: {
      snoring: 'Snoring',
      hold_breath: 'Holds breath',
      gasping: 'Gasping for breath',
      sleepy: 'Daytime sleepiness',
      dry_mouth: 'Dry mouth/Sore throat',
      heartburn: 'Heartburn/reflux',
      blood_pressure: 'High blood pressure',
      headaches: 'Headaches',
    },
    snoring_type: {
      positional: 'Positional snoring',
      mouth: 'Mouth snoring',
      nose: 'Nose snoring',
      partner: 'Partner snoring',
    },
    result: {
      indicator: 'Risk indicator:',
      your_indicators: 'Your indicators:',
      your_physio_indicators: 'Your physiological indicators:',
      recommendations: 'Care Recommendations',
      high_risk: 'high risk',
      moderate_risk: 'moderate risk',
      low_risk: 'low risk',
      at_risk: 'at risk',
      at_risk_with_pain: 'at risk of insomnia with pain',
      apnea: {
        title: 'Sleep Apnea',
        subtitle: 'Your symptoms and BMI suggests that you may be at {{severity}} of Sleep apnea.',
        question: 'What is Sleep apnea',
        recommendations: {
          risk:
            "Your sleep assessment results indicate you may be at risk of Sleep Apnea. Consider taking a home sleep test to determine if you require treatment or talk to your doctor. We've also pulled together some suggested products just for you.  Waking up feeling refreshed and energised will help you enjoy the things you love. Alternatively, you may want to talk to your doctor.",
          low_risk:
            "Your sleep assessment results indicate you may be at low risk of Sleep Apnea. If you're unsure about your symptoms you should consider taking a home sleep test to determine if you require treatment. Alternatively, you may want to talk to your doctor.",
          copd:
            'Your sleep assessment results indicate that due to existing medical conditions you will need to speak to your GP about your symptoms and possible treatments options to improve your sleep. Alternatively, you may want to talk to your doctor.',
        },
        definition:
          'Sleep apnea is a sleep disorder that causes your body to stop breathing while you sleep. Air stops flowing to your lungs for 10 seconds or longer which can happen repeatedly during the night. Left undiagnosed and untreated, it can have long-term effects on your health. A home sleep study will help determine if you have a sleep disorder.',
      },
      insomnia: {
        title: 'Insomnia',
        risk_title: '{{risk}} of insomnia',
        risk_pain_title: '{{risk}} of insomnia with pain',
        subtitle: 'Your assessment results suggest you may be at {{severity}}.',
        question: 'What is insomnia',
        recommendations: {
          none:
            "Looks like insomnia may not be a problem for you. However, if you're looking for ways to improve your overall sleep health we've pulled together some suggested products just for you.  You deserve to feel your best with the energy to live each day to its full potential. Alternatively, you may want to talk to your doctor.",
          normal:
            "Your sleep assessment results indicate you may be at risk of Insomnia. We've pulled together some suggested products just for you that may help you get a better nights sleep. You deserve to feel your best with the energy to live each day to its full potential. Alternatively, you may want to talk to your doctor.",
          pain:
            'Your sleep assessment results indicate you may be at risk of insomnia due to pain. You should speak to your GP who may refer you to a sleep psychologist. You deserve to feel your best with the energy to live each day to its full potential.',
        },
        definition:
          'Insomnia is the name given to regularly having a hard time falling asleep, staying asleep or getting back to sleep if you wake in the night. Insomnia can make you feel drowsy during the day, and effect memory, concentration and learning.',
      },
      sleep_health: {
        subtitle: 'Your assessment results suggest you may benefit from sleep Health products',
        question: 'What is Sleep Health',
        definition:
          'Your body and mind depend on a good night’s sleep for your physical and mental health as well as your quality of life. While we sleep many important functions take place that helps the body to repair itself. Sleep also supports brain function and improves memory and mood',
        recommendation:
          'A good night’s sleep helps you feel good and be more alert, you feel more energetic and can concentrate better. Getting enough sleep is one of the most important things you can do for your health and well-being.  Here are some products just for you that may help improve your sleep. Alternatively, you may want to talk to your doctor.',
      },
      snoring: {
        positional: {
          title: 'Positional snoring',
          severity: 'Positional snorer',
          recommendation:
            'Changing your sleeping position may be key to easing your snoring. If you need a little extra help, here are some products that may help. Alternatively, you may want to talk to your doctor.',
          question: 'What is positional snoring?',
          definition:
            'Positional snoring is when you only snore in a certain position. Most people find they snore more when sleeping on their back. When you sleep on your back, gravity helps the relaxed muscles in your mouth and tongue to fall back and restrict your airway. That’s why some people snore less when they sleep on their side.',
        },
        mouth: {
          title: 'Mouth snoring',
          severity: 'Mouth snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is mouth snoring?',
          definition:
            'Mouth snorers also suffer from blocked nasal passages and therefore breathe through their mouth when they sleep. This causes the soft tissues inside their mouth to vibrate and produce a low rumbling sound.',
        },
        nose: {
          title: 'Nose snoring',
          severity: 'Nose snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is nose snoring?',
          defintion:
            'Nose snoring occurs when something is blocking the nasal passages. It can be caused by things like a deviated septum, allergies, colds and flu.',
        },
        partner: {
          title: 'Partner snoring',
          severity: 'Partner snores',
          recommendation:
            'If you’re worried about the effect that your partner’s snoring is having on your sleep there are a few things you can try. Download the myNight app to help them understand the severity of their snoring. Alternatively, you may want to talk to your doctor.',
          question: 'What is snoring?',
          definition:
            'Snoring can be a sign that you’re not breathing properly when you sleep. As you fall asleep the muscles in your mouth, tongue and throat relax. Snoring occurs when these muscles relax too much and they partially block your airway and vibrate.',
        },
      },
    },
  },
  qrPrint: {
    step: 'Schritt',
    patientGeneric: 'Lieber Patient,',
    patientName: 'Lieber {{name}},',
    titles: {
      install: 'DIE SMARTPHONE APP INSTALLIEREN',
      perform: 'FÜHREN SIE DEN TEST DURCH',
      return: 'DEN TEST ZURÜCK SENDEN',
    },
    intro:
      'Es ist vorgesehen worden, dass Sie einen NightOwl® Schlaftest machen. Dieser Test überprüft, ob sie an Schlafapnoe leiden, indem Signale während ihrem Schlaf gemessen werden. Dafür müssen Sie einen kleinen Sensor an der Spitze Ihres Zeigefingers befestigen. Dieser Sensor beleuchtet Ihren Finger und nimmt das von Ihrer Haut reflektierte Licht auf. Er verbindet sich drahtlos an Ihrem Smartphone durch Bluetooth. Möglicherweise werden Sie gebeten, länger als eine Nacht mit diesem Sensor zu schlafen.',
    introSteps:
      'Sich auf Ihrem Test vorzubereiten ist einfach und nimmt nur wenige Minuten Ihrer Zeit in Anspruch, während wir Sie durch die folgenden Schritte führen.',
    step1:
      'Öffnen Sie auf Ihrem Smartphone den App Store fals Sie einen iPhone haben, ansonsten den Play Store fals Sie einen Android haben.',
    step2:
      'In der App die Sie gerade geöffnet haben, suchen Sie nach der NightOwl Begleiter App und laden Sie sie herunter. In the app you just opened, search for and download the NightOwl',
    step3: "Öffnen Sie die NightOwl Beigleiter App, und drücken Sie auf den Knopf der 'Ich habe einen Aktivierungscode' sagt.",
    step4:
      'Sie können entweder den QR-code mit der NightOwl Beigleiter App scannen, oder den unten stehenden Buchstabencode manuell eingeben.',
    step5:
      'Wenn Sie bereit sind ins Bett zu gehen, öffnen Sie die NightOwl®-App auf Ihrem Smartphone. Drücken Sie auf den Knopf "Weiter" und befolgen Sie die Anweisungen in der App. Süße Träume!',
    step6:
      'Wenn Sie aufwachen, beenden Sie die Aufnahme, indem Sie in der App auf den Knopf “Aufwachen” drücken. Ihre aufgezeichneten Daten werden nun über das Internet hochgeladen.',
    step7: 'Wenn der Upload-Vorgang abgeschlossen ist, laden Sie den Sensor gemäß dem in der App gezeigten Anleitungsvideo auf.',
    step_return:
      'Wenn Sie alle Testnächte abgeschlossen haben, senden Sie das Gerät gemäß den Anweisungen, die Sie von Ihren Gesundheits-Experte erhalten haben, zurück.',
    footer: '© COPYRIGHT 2020 | ECTOSENSE • ALLE RECHTE VORBEHALTEN',
  },
};
