import { toDate } from 'date-fns';

import { IUnitSnapshotIn } from 'core/stores/data/units/unit';

export function convertUnit(unit: UnitAPI): IUnitSnapshotIn {
  return {
    id: unit._id,
    name: unit.name,
    status: unit.status,
    balance: unit.balance,
    updatedAt: toDate(unit.modified_at),
    fulfillmentEnabled: unit.settings.fulfillment_enabled,
    offlineEnabled: unit.settings.offline_enabled,
    defaultNights: unit.settings.default_nights,
    protocol_enabled: unit.settings.protocol_enabled,
    plus_enabled: unit.settings.plus_enabled,
    consents_requested: unit.settings.consents_requested,
    default_extensions: unit.settings.default_extensions,
    default_minimum_nights: unit.settings.default_minimum_nights,
    defaultSimple: unit.settings.default_simple,
    twoFaEnforced: unit.settings.two_fa_enforced,
    verified: unit.verified,
    rawSettings: JSON.stringify(unit.settings),
  };
}
