import { observable } from 'mobx';
import { inject, observer, Observer } from 'mobx-react';
import React, { Component } from 'react';
import { Alert, Button, Form, Toast } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';

import { logScreen } from 'core/analytics';
import i18n from 'core/i18n';
import { IAuth } from 'core/stores/auth';
import { UnauthorizedError } from 'core/util/errors';

import { Card } from './components/Card';
import Container from './components/Container';

import './styles.scss';

interface InjectedProps {
  auth: IAuth;
}

type Props = InjectedProps & RouteComponentProps;

class ServiceLogin extends Component<Props, {}> {
  @observable email = '';
  @observable password = '';
  @observable service = '';
  @observable error: any = null;

  state = {
    showToaster: false,
  };

  componentDidMount(): void {
    logScreen('LOGIN');
    const params = new URLSearchParams(this.props.location.search);
    const isVerified = params.get('signup_status');
    if (isVerified) {
      this.setState({ showToaster: true });
    }
  }

  logIn = async (e): Promise<void> => {
    e.preventDefault();
    const { auth } = this.props;
    try {
      const loginData = await auth.login(this.email, this.password, this.service);
      if (loginData.type === '2FA') {
        this.props.history.push({
          pathname: '/login/2fa',
          search: `?type=${loginData.authType}&secret=${loginData.secret}`,
        });
      }
    } catch (err) {
      this.error = err;
    }
  };

  renderExpirationMessage(): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          if (this.props.auth.sessionExpired) {
            return <Alert variant="warning">Your session has expired, please log in again</Alert>;
          }
          return null;
        }}
      </Observer>
    );
  }

  renderErrorMessage(): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const { auth } = this.props;
          if (auth.error) {
            if (this.error && this.error.name === UnauthorizedError.NAME) {
              return <Alert variant="warning">{i18n.t('auth.login.messages.incorrect')}</Alert>;
            }
            return <Alert variant="warning">{i18n.t('auth.login.messages.error')}</Alert>;
          }
          return null;
        }}
      </Observer>
    );
  }

  renderFooter(): JSX.Element {
    const { ENV } = process.env;
    return (
      <>
        <a className="footerlink left" href={ENV !== 'production' ? 'https://signup-qa.nightowl.care/' : 'https://signup.nightowl.care/'}>
          {i18n.t('auth.footer.no_account_yet')}
        </a>
        <Link className="footerlink right" to="/forgot_password">
          {i18n.t('auth.footer.forgot_password')}
        </Link>
      </>
    );
  }

  render(): JSX.Element {
    const { auth } = this.props;
    return (
      <Container>
        <div aria-live="polite" aria-atomic="true" className="toast-email-verified-container">
          <Toast
            onClose={() => this.setState({ showToaster: false })}
            show={this.state.showToaster}
            delay={3000}
            autohide
            className="toast-email-verified"
          >
            <Toast.Header>
              <p className="toast-email-verified-header">Your email has been verified successfully!</p>
            </Toast.Header>
          </Toast>
        </div>
        <Card title={i18n.t('auth.login.title')} footer={this.renderFooter}>
          {this.renderExpirationMessage()}
          {this.renderErrorMessage()}
          <Form onSubmit={this.logIn}>
            <Form.Group>
              <Form.Label>Service</Form.Label>
              <Form.Control
                type="string"
                onChange={e => {
                  this.service = e.target.value;
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{i18n.t('auth.login.email_input_label')}</Form.Label>
              <Form.Control
                type="email"
                onChange={e => {
                  this.email = e.target.value;
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{i18n.t('input_labels.password')}</Form.Label>
              <Form.Control
                type="password"
                onChange={e => {
                  this.password = e.target.value;
                }}
              />
            </Form.Group>
            <Button type="submit" block disabled={auth.busy}>
              {i18n.t('auth.login.button')}
            </Button>
          </Form>
        </Card>
      </Container>
    );
  }
}

export default inject<InjectedProps, Props>(({ root }) => ({
  auth: root.auth,
}))(observer(ServiceLogin));
