import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const UnitStatistic = types.model('unitStatistic', {
  id: types.identifier,
  tests: types.number,
  unreadPdfReports: types.number,
  unreadSummaryReports: types.number,
}).views(self => ({
  get unreadReports() {
    return self.unreadPdfReports + self.unreadSummaryReports;
  },
}));

export interface IUnitStatistic extends Instance<typeof UnitStatistic> {}
export interface IUnitStatisticSnapshotIn extends SnapshotIn<typeof UnitStatistic> {}
export interface IUnitStatisticSnapshotOut extends SnapshotOut<typeof UnitStatistic> {}
