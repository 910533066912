import toDate from 'date-fns/toDate';
import { compact } from 'lodash';

import { IUnitStatisticSnapshotIn } from 'core/stores/data/statistics/unitStatistics';
import { IUnitSnapshotIn } from 'core/stores/data/units/unit';

import { apiInstance } from './axios';
import { convertEnrollment } from './transformers/enrollmentTransformer';
import { convertUnit } from './transformers/unitTransformer';

function getMeData() {
  const storedSession = localStorage.getItem('session');
  if (storedSession) {
    return JSON.parse(storedSession);
  }
}

function convertStatistics(stats: StatisticsAPI): IUnitStatisticSnapshotIn {
  return {
    id: stats.unit_id,
    tests: stats.tests,
    unreadPdfReports: stats.unread_pdf_reports,
    unreadSummaryReports: stats.unread_summary_reports,
  };
}

export function inviteMember(unit: id, invite: { firstname: string; lastname: string; email: string; physician: boolean; admin: boolean }) {
  const properties = {
    unit,
    role: compact([invite.physician && 'PHYSICIAN', invite.admin && 'ADMIN']),
    first_name: invite.firstname,
    last_name: invite.lastname,
    email: invite.email,
  };

  return apiInstance.post<createEnrollmentAPI>(`enrollments`, properties).then(response => {
    return convertEnrollment(response.data.enrollment);
  });
}

export function createUnit(props: CreateUnitProps): Promise<IUnitSnapshotIn> {
  return apiInstance.post<CreateUnitAPI>('units', props).then(response => {
    if (response.data) {
      return convertUnit(response.data.unit);
    }
    return Promise.reject('A problem occured when creating the unit, check logs for details.');
  });
}

export function setName(id: id, name: string) {
  return apiInstance.patch(`units/${id}`, { name }).then(response => response.data);
}

export function updateUnitSettings(id: id, settings: UnitAPI['settings']) {
  return apiInstance.patch(`units/settings/${id}`, settings).then(response => convertUnit(response.data.unit));
}

export function getUnits(enrolledUnits: string[], updatedSince?: Date): Promise<IUnitSnapshotIn[]> {
  const { service } = getMeData();
  return apiInstance
    .get<ListUnitsAPI>('units', {
      params: {
        query: {
          // _id: { $in: enrolledUnits },
          status: { $ne: 'DISABLED' },
          'settings.service': service || 'HCP',
        },
      },
    })
    .then(res => {
      return res.data.units.map(convertUnit);
    });
}

export function getUnitStatistics(unit: id): Promise<IUnitStatisticSnapshotIn> {
  return apiInstance.get(`units/statistics/${unit}`).then((response: any) => {
    return convertStatistics(response.data);
  });
}

export function getPatientStatistics(unit: id, from: Date, to: Date) {
  return apiInstance
    .get(`units/patients/statistics/${unit}`, {
      params: {
        before: to,
        after: from,
      },
    })
    .then(response => {
      return response.data;
    });
}
