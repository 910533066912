import { parseISO } from 'core/util/date';

export function convertEnrollmentUser(user: any) {
  return {
    id: user._id,
    firstname: user.first_name,
    lastname: user.last_name || '',
    email: user.email,
    updatedAt: parseISO(user.modified_at),
    createdAt: parseISO(user.created_at),
    passwordUpdatedAt: parseISO(user.password_updated_at),
  };
}

export function convertEnrollmentUnit(unit: any) {
  return {
    id: unit._id,
    name: unit.name,
    status: unit.status,
    offlineEnabled: unit.settings && unit.settings.offline_enabled,
    fulfillmentEnabled: unit.settings && unit.settings.fulfillment_enabled,
    defaultNights: unit.settings && unit.settings.default_nights,
    default_extensions: unit.settings && unit.settings.default_extensions,
    default_minimum_nights: unit.settings && unit.settings.default_minimum_nights,
    protocol_enabled: unit.settings && unit.settings.protocol_enabled,
    plus_enabled: unit.settings && unit.settings.plus_enabled,
    consents_requested: unit.settings && unit.settings.consents_requested,
    defaultSimple: unit.settings && unit.settings.default_simple,
    resmedFranceUnit: unit.settings && unit.settings.resmed_france,
    resmedAustraliaUnit: unit.settings && unit.settings.resmed_australia,
  };
}

export function convertEnrollment(e: EnrollmentAPI) {
  return {
    id: e._id,
    user: e.user ? convertEnrollmentUser(e.user) : undefined,
    user_id: e.user_id,
    unit: e.unit ? convertEnrollmentUnit(e.unit) : undefined,
    status: e.status ? e.status.toLowerCase() : undefined,
    roles: e.role,
    createdAt: parseISO(e.created_at),
    acceptedAt: e.accepted_at ? parseISO(e.accepted_at) : null,
    fees: e.fees || 0,
    code: e.code,
    updatedAt: parseISO(e.modified_at),
  };
}
