import React from 'react';
import { Col, Form, FormControlProps } from 'react-bootstrap';

interface Props extends FormControlProps {
  label?: string;
  asCol?: boolean;
  groupAs?: any;
  required?: boolean;
  name?: string;
  feedback?: any;
  className?: string;
  placeholder?: string;
  style?: string;
}

export const FormInput: React.FC<Props> = ({ label, asCol, groupAs, required, feedback, ...props }) => {
  return (
    <Form.Group {...{ as: asCol ? Col : groupAs || undefined }}>
      {label && (
        <Form.Label>
          {label}
          {required ? ' *' : null}
        </Form.Label>
      )}
      <Form.Control {...props} />
      {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
    </Form.Group>
  );
};
