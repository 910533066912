import maxDate from 'date-fns/max';
import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import { logEvent } from 'core/analytics';
import { IStore } from 'core/stores/index';

import { IUnit, Unit } from './unit';

const Units = types
  .model('units', {
    units: types.map(Unit),
    initialized: false,
    initializing: false,
    error: '',
  })
  .views(self => ({
    getUnitById(id: string): IUnit {
      return self.units.get(id);
    },
    get mostRecentUpdate(): Date | null {
      return maxDate(Array.from(self.units.values()).map((u: IUnit) => u.updatedAt)) || null;
    },
  }))
  .actions(self => ({
    initialize: flow(function* initialize() {
      self.initializing = true;
      const { api } = getEnv(self);
      const { auth } = getRoot<IStore>(self);
      const units = yield api.units.getUnits(auth.user.enrollmentsAdmin.map(enr => enr.unit.id));
      units.forEach((unit: IUnit) => {
        self.units.put(unit);
      });
      self.initializing = false;
      self.initialized = true;
    }),
    createUnit: flow(function* createUnit(
      unitName: string,
      balance: number,
      verified: boolean,
      physician: boolean,
      admin: boolean,
      firstname: string,
      lastname: string,
      email: Email,
    ) {
      try {
        const { api } = getEnv(self);
        const unit = yield api.units.createUnit({
          name: unitName,
          balance,
          verified,
          fee: 1,
          status: 'ACTIVE',
        });
        logEvent('UNIT_CREATED', { name: unitName });
        yield api.units.inviteMember(unit.id, {
          firstname,
          lastname,
          email,
          physician,
          admin,
        });
        self.error = '';
      } catch (err) {
        self.error = 'Error while creating unit';
      }
    }),
    refresh: flow(function* refresh() {
      const { api } = getEnv(self);
      const { auth } = getRoot<IStore>(self);
      const units = yield api.units.getUnits(
        auth.user.enrollmentsAdmin.map(enr => enr.unit.id),
        self.mostRecentUpdate,
      );
      units?.forEach((unit: IUnit) => {
        const unitEntity = self.units.get(unit.id);
        if (unitEntity) {
          unitEntity.sync(unit);
        } else {
          self.units.put(unit);
        }
      });
    }),
  }));

export default Units;
export interface IUnitsStore extends Instance<typeof Units> {}
