import React, { FC } from 'react';
import { Alert } from 'react-bootstrap';

import i18n from 'core/i18n';
import { getPasswordRequirements } from 'core/util/password';

interface Props {
  error?: boolean;
}

export const PasswordRequirements: FC<Props> = ({ error }) => {
  return (
    <Alert variant={error ? 'warning' : 'secondary'}>
      <Alert.Heading>{error ? i18n.t('password.requirements.not_met') : i18n.t('password.requirements.req_intro_header')}</Alert.Heading>
      {i18n.t('password.requirements.req_intro')}
      <ul>
        {getPasswordRequirements().map((r, idx) => (
          <li key={idx}>{r}</li>
        ))}
      </ul>
    </Alert>
  );
};
