import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import NOSurvey from './assessment';
import { Survey } from './components/Survey';
import { SurveyFinished } from './components/SurveyFinished';

import './styles.scss';

export class SurveyContainer extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/survey/finish" component={SurveyFinished} />
          <Route path="/survey/assessment" component={NOSurvey} />
          <Route
            path="/survey/5de678751c9d44000007f921"
            render={props => <Redirect to={{ pathname: '/survey/assessment', search: props.location.search }} />}
          />
          <Route path="/survey/:id" component={Survey} />
        </Switch>
      </div>
    );
  }
}
