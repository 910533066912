import maxDate from 'date-fns/max';
import _ from 'lodash';
import { flow, getEnv, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { convertSurvey } from 'api/transformers/surveyTransformer';
import { ITest } from 'core/stores/data/tests/test';

import { Survey } from './survey';
import { SurveyResponse } from './surveyResponse';

export const Surveys = types
  .model('surveys', {
    responses: types.array(SurveyResponse),
    surveys: types.array(Survey),
    fetching: false,
  })
  .views(self => ({
    responseById: id => {
      return self.responses.find(res => res.id === id);
    },
    get mostRecentUpdate() {
      return maxDate(self.responses.map(r => r.createdAt)) || null;
    },
  }))
  .actions(self => ({
    refresh: flow(function* refresh() {
      const { api } = getEnv(self);
      self.fetching = true;
      const responses = yield api.surveys.getSurveyResponses();

      const surveysToFetch: string[] = _.difference(
        _.uniq(responses.map(response => response.survey)),
        self.surveys.map(survey => survey.id),
      );

      for (const surveyId of surveysToFetch) {
        const survey = yield api.surveys.getSurvey(surveyId);
        self.surveys.push(convertSurvey(survey));
      }

      self.responses = responses;
      self.fetching = false;
    }),
    linkTestToResponse: flow(function* linkTestToResponse(responseId: id, test: ITest) {
      const { api } = getEnv(self);
      yield api.surveys.patchSurveyResponse(responseId, { test: test.id, patient: test.patientId });
      const response = self.responseById(responseId);
      response.patient = {
        ...response.patient,
        id: test.patientId,
      };
      response.test = {
        id: test.id,
      };
    }),
  }));

export interface ISurveysStore extends Instance<typeof Surveys> {}
export interface ISurveysStoreSnapshotIn extends SnapshotIn<typeof Surveys> {}
export interface ISurveysStoreSnapshotOut extends SnapshotOut<typeof Surveys> {}
