import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { RouteConstants } from 'containers/Survey/routes';

import { Finish } from './containers/Finish';
import Home from './containers/Home';
import Questionaire from './containers/Questionaire';
import Result from './containers/Result';
// import './App.css';

const routes = (
  <Switch>
    <Route
      exact
      path={RouteConstants.ASSESSMENT_ROOT}
      render={props => <Redirect to={{ pathname: RouteConstants.ASSESSMENT_HOME, search: props.location.search }} />}
    />
    <Route path={RouteConstants.ASSESSMENT_HOME} component={Home} />
    <Route path={RouteConstants.ASSESSMENT_SURVEY} component={Questionaire} />
    <Route psth={RouteConstants.ASSESSMENT_FINISH} component={Finish} />
    <Route path={RouteConstants.ASSESSMENT_RESULT} component={Result} />
  </Switch>
);

class App extends Component {
  render() {
    return <>{routes}</>;
  }
}

export default App;
