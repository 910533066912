import { observable, when } from 'mobx';
import { inject, Observer, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import * as api from 'api/invitation.api';
import { Button } from 'core/components/Button';
import i18n from 'core/i18n';
import { IAuth } from 'core/stores/auth';
import { IInvitation, Invitation as InvitationStore } from 'core/stores/invitation';

import { Spinner } from '../Spinner';

import Activate from './components/Activate';
import { Card } from './components/Card';
import Container from './components/Container';
import ShowInvitation from './components/ShowInvitation';

import './styles.scss';

enum STEP {
  'INIT',
  'ACTIVATE',
  'ACCEPT',
  'FINISH',
}

interface InjectedProps {
  auth: IAuth;
}
interface PassedProps {
  location: any;
  history: any;
}

type Props = InjectedProps & PassedProps;

interface State {
  invitation: IInvitation | null;
  error: string | null;
  step: STEP;
}

class Invitation extends Component<Props, {}> {
  @observable
  observables: State = {
    invitation: null,
    error: null,
    step: STEP.INIT,
  };

  componentDidMount() {
    const { location } = this.props;
    const search = new URLSearchParams(location.search);
    const token = search.get('token');
    const enrollment = search.get('enrollment');
    if (token && enrollment) {
      this.observables = {
        invitation: InvitationStore.create(
          // @ts-ignore
          { token, enrollmentId: enrollment },
          { api },
        ),
        error: null,
        step: STEP.INIT,
      };
      when(
        () => Boolean(this.observables.invitation?.initialized),
        () => {
          const { invitation } = this.observables;
          if (invitation && invitation.user && invitation.user.isNewUser) {
            this.observables.step = STEP.ACTIVATE;
          } else {
            this.observables.step = STEP.ACCEPT;
          }
        },
      );
    } else {
      this.observables = {
        invitation: null,
        error: 'Incorrect parameters',
        step: STEP.INIT,
      };
    }
  }

  onActivate = async (password: string, passwordConfirm: string) => {
    const { invitation } = this.observables;
    if (invitation) {
      try {
        await invitation.activate(password, passwordConfirm);
        this.observables.step = STEP.ACCEPT;
      } catch (e) {
        console.warn(e);
      }
    }
  };

  onAccept = async () => {
    const { invitation } = this.observables;
    if (invitation) {
      try {
        await invitation.accept();
        this.observables.step = STEP.FINISH;
      } catch (e) {
        console.warn(e);
      }
    }
  };

  renderBody = () => {
    return (
      <Observer>
        {() => {
          const { invitation } = this.observables;

          if (this.props.auth.authenticated) {
            return (
              <div className="show-invitation">
                <p>{i18n.t('auth.invite.user_logged_in.body')}</p>
                <Button block content={i18n.t('auth.invite.user_logged_in.button')} onClick={this.props.auth.logout} />
              </div>
            );
          }

          if (invitation) {
            if (invitation.busy) {
              return (
                <div className="invitation-busy">
                  <Spinner />
                </div>
              );
            }
            if (invitation.initialized) {
              switch (this.observables.step) {
                case STEP.ACTIVATE:
                  return <Activate invitation={invitation} onActivate={this.onActivate} />;
                case STEP.ACCEPT:
                  return <ShowInvitation invitation={invitation} onAccept={this.onAccept} />;
                case STEP.FINISH:
                  return <Redirect to="/" />;
                default:
                  return (
                    <div className="invitation-busy">
                      <Spinner />
                    </div>
                  );
              }
            } else if (invitation.errorCode === 400) {
              return (
                <div className="show-invitation">
                  {/* <p>{i18n.t('auth.invite.user_logged_in.body')}</p> */}
                  <Button block onClick={() => this.props.history.replace('login')}>
                    {i18n.t('auth.invite.back_to_login_button')}
                  </Button>
                </div>
              );
            }
          }

          return null;
        }}
      </Observer>
    );
  };

  render() {
    const { invitation, error } = this.observables;
    return (
      <Container>
        <Card title={i18n.t('auth.invite.title')}>
          <div className="invitation">
            {error == null && (!invitation || (invitation && invitation.error != null)) && (
              <Alert variant="danger">{invitation && invitation.error}</Alert>
            )}
            {this.renderBody()}
          </div>
        </Card>
      </Container>
    );
  }
}

export default inject<InjectedProps, Props>(({ root }) => ({
  auth: root.auth,
}))(observer(Invitation));
