import { parseISO } from 'core/util/date';
import { PasswordMismatchError, UnauthorizedError } from 'core/util/errors';

import { setAuthToken, apiInstance, apiAccountsInstance, SIGNING_SERVICE } from './axios';

export async function me() {
  // User is stored in localStorage as { user: userID, session: sessionToken }
  const storedSession = localStorage.getItem('session');
  if (storedSession) {
    const { user, token, saved_at, service } = JSON.parse(storedSession);
    setAuthToken(token, service);
    return validate()
      .then((result: any) => {
        return {
          ...result,
          savedAt: parseISO(saved_at),
        };
      })
      .catch(() => {
        return Promise.resolve(null);
      });
  }
}

export function validate(): Promise<UserAPI> {
  return apiAccountsInstance
    .get<{ user: UserAPI }>(`/users/me`)
    .then(result => {
      return result.data.user;
    })
    .catch(e => {
      setAuthToken(null, null);
      return Promise.reject(e);
    });
}

export function editUser(firstname: string, lastname: string, email: string, locale: string) {
  return apiInstance
    .patch(`users`, {
      first_name: firstname,
      last_name: lastname,
      email,
      language_preference: locale,
    })
    .then(result => result.data);
}

export function editPassword(oldPassword: string, newPassword: string, passwordConfirmation: string): Promise<void> {
  if (newPassword === passwordConfirmation) {
    return apiInstance
      .patch(`users/password`, {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: passwordConfirmation,
      })
      .then(result => {
        setAuthToken(result.data['x-auth-token'], result.data.service);
        return result.data;
      })
      .catch(error => {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
        throw error;
      });
  }
  throw new PasswordMismatchError();
}

export async function login(
  email: string,
  password: string,
  userService: string,
): Promise<
  { type: 'LOGIN'; needs_reset: boolean; user: UserAPI; enforced2FA: boolean } | { type: '2FA'; authType: string; secret: string }
> {
  try {
    const loginResponse = await apiAccountsInstance.post<LoginResponse>('users/login/service', {
      service: userService || SIGNING_SERVICE,
      email,
      password,
    });
    if (loginResponse.data.message === 'Awaiting 2FA') {
      return {
        type: '2FA',
        authType: loginResponse.data.type,
        secret: loginResponse.data.secret,
      };
    }

    if (loginResponse.data.message === 'Login Successful') {
      const authToken = loginResponse.data['x-auth-token'];
      const { service } = loginResponse.data;
      setAuthToken(authToken, service);
      const userResponse = await validate();
      localStorage.setItem(
        'session',
        JSON.stringify({ user: userResponse._id, token: authToken, saved_at: new Date().toISOString(), service }),
      );
      return {
        user: userResponse,
        type: 'LOGIN',
        needs_reset: false,
        enforced2FA: loginResponse.data.redirect2FA,
      };
    }

    throw new Error('Unsupported login response');
  } catch (error) {
    if (error?.response?.status === 401) {
      throw new UnauthorizedError();
    }
    throw error;
  }
}

export function externalLogin(authToken: string, service: string = 'HCP') {
  try {
    setAuthToken(authToken, service);
    localStorage.setItem('session', JSON.stringify({ token: authToken, service }));
    return {
      type: 'EXTERNAL LOGIN',
    };
  } catch (e) {
    throw e;
  }
}

export async function submit2fa(
  secret: string,
  code: string,
  type: string,
): Promise<{ type: 'LOGIN'; needs_reset: boolean; user: UserAPI }> {
  let authToken: string;
  try {
    const twoFa = await verify2FA({ secret, code, type });
    authToken = twoFa['x-auth-token'];
    const { service } = twoFa;
    setAuthToken(authToken, service);
    const userResponse = await validate();
    localStorage.setItem(
      'session',
      JSON.stringify({ user: userResponse._id, token: authToken, saved_at: new Date().toISOString(), service }),
    );
    return {
      user: userResponse,
      type: 'LOGIN',
      needs_reset: false,
    };
  } catch (error) {
    if (error.response?.status === 401) {
      throw new UnauthorizedError();
    }
    throw error;
  }
}

export function forgotPassword(email: string) {
  return apiInstance.post('users/password/forgot', { email }).then(result => result.data);
}

// tslint:disable-next-line: variable-name
export function resetPassword(token: string, new_password: string, confirm_password: string) {
  return apiInstance.post('users/password/reset', { new_password, confirm_password, token }).then(response => {
    return response.data;
  });
}

const clearCookies = () => {
  document.cookie.split(';').forEach(function(c) {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
};

export function logout(): Promise<void> {
  return apiInstance
    .post('users/logout')
    .then(() => {
      clearCookies();
    })
    .finally(() => {
      setAuthToken(null, null);
      localStorage.removeItem('session');
    });
}

export function get2FA(): Promise<TwoFaApi> {
  return apiAccountsInstance
    .get<getTwoFaResponse>('2fa')
    .then(response => {
      return response.data['two_fa'];
    })
    .catch(e => {
      if (e.response?.status === 404) {
        return null;
      }
      throw e;
    });
}

export function enable2FA(body: { type: string; password: string; typeValue: string }): Promise<TwoFaEnableResponse> {
  return apiAccountsInstance
    .post<TwoFaEnableResponse>('2fa/enable', body)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response?.status === 401) {
        throw new UnauthorizedError();
      }
      throw err;
    });
}

export function disable2FA(password: string): Promise<string> {
  return apiAccountsInstance
    .post<{ secret: string }>('2fa/disable', { password })
    .then(response => {
      return response.data.secret;
    })
    .catch(err => {
      if (err.response?.status === 401) {
        throw new UnauthorizedError();
      }
      throw err;
    });
}

export function verify2FA(body: { secret: string; code: string; type: string }): Promise<TwoFaLoginResponse> {
  return apiAccountsInstance
    .post<TwoFaLoginResponse>('2fa/verify', body)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response?.status === 401) {
        throw new UnauthorizedError();
      }
      throw err;
    });
}
