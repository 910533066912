import { ISurveyResponseSnapshotIn } from 'core/stores/data/surveys/surveyResponse';

import { apiInstance, surveyApiInstance } from './axios';
import { convertSurveyResponse } from './transformers/surveyTransformer';

export function getSurvey(survey: id) {
  return apiInstance.get(`surveys/${survey}`).then((response: any) => {
    return response.data;
  });
}

export function postResponse(body: any) {
  return apiInstance.post(`surveys/responses`, body);
}

export function getSurveyResponses(): Promise<ISurveyResponseSnapshotIn[]> {
  return apiInstance
    .get('surveys/responses/list', {
      params: {
        with_related: true,
      },
    })
    .then(res => res.data.responses.map(convertSurveyResponse));
}

export function createSurveyPatient(body: any) {
  return surveyApiInstance.post('patients', body).then(response => {
    return response.data.patient;
  });
}

export function patchSurveyResponse(id, body): Promise<ISurveyResponseSnapshotIn> {
  return apiInstance.patch(`surveys/responses/${id}`, body).then(res => convertSurveyResponse(res.data.response));
}
