/* eslint-disable max-len */
/* tslint:disable */
export default {
  general: {
    save: 'Save',
    edit: 'Edit',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    submit: 'Submit',
    confirm: 'Confirm',
    done: 'Done',
    loading: 'Loading',
    print: 'Print',
    download: 'Download',
    update: 'Update',
    enable: 'Enable',
    disable: 'Disable',
    next: 'Next',
    back: 'Back',
    send: 'Send',
    email: 'Email',
    help: 'Help',
    day: 'Day',
    month: 'Month',
    year: 'Year',
  },
  months: {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',
  },
  input_labels: {
    email: 'Email',
    phone: 'Phone',
    password: 'Password',
    password_confirm: 'Confirm password',
    password_new: 'New password',
    password_old: 'Old password',
    simple: 'Simple',
    select_unit: 'Select Center',
    unit: 'Center',
    lastname: 'Last name',
    firstname: 'First name',
    birthday: 'Birthday',
    birthday_and_format: 'Birthday (YYYY-MM-DD)',
    language: 'Language',
    fulfillment: 'Fulfillment by Ectosense',
    address: 'Address',
    city: 'City',
    region: 'State/Province/Region',
    zip: 'ZIP/Postal Code',
    country: 'Country',
    offline: 'Offline test',
    nights: 'Nights',
    verification_code: 'Verification code',
  },
  validation_errors: {
    required_field: '{{field}} is required',
    invalid_email: 'Invalid email',
    invalid_password: 'Invalid password',
    invalid_phone_require_international: 'The provided phone number is invalid, please provide a number in international format.',
  },
  locales: {
    english: 'English',
    dutch: 'Dutch',
    french: 'French',
    portuguese: 'Portuguese',
    german: 'German',
    spanish: 'Spanish',
  },
  roles: {
    PHYSICIAN: 'healthcare professional',
    ADMIN: 'admin',
  },
  password: {
    requirements: {
      req_intro_header: 'Password requirements',
      req_intro: 'Passwords must',
      not_met: 'Password requirements not met',
      num_characters: 'Be at least {{amount}} characters',
      num_lowercase: {
        one: 'Contain at least {{amount}} lowercase character',
        other: 'Contain at least {{amount}} lowercase characters',
      },
      num_uppercase: {
        one: 'Contain at least {{amount}} uppercase character',
        other: 'Contain at least {{amount}} uppercase characters',
      },
      num_number: {
        one: 'Contain at least {{amount}} number',
        other: 'Contain at least {{amount}} number',
      },
    },
    error: {
      no_match: 'Passwords do not match',
    },
  },
  auth: {
    login: {
      title: 'Welcome to NightOwl',
      email_input_label: 'Sign in with your e-mail address',
      button: 'Sign in',
      messages: {
        incorrect: 'Sorry, you provided incorrect credentials. Please try again.',
        error: "Sorry, we couldn't sign you in. Please try again.",
      },
    },
    '2fa': {
      title: 'Two Factor Authentication',
      button: 'Submit',
      messages: {
        info_sms: 'A text message was sent to you with a verification code, please fill in the received code',
        info_email: 'An email was sent to you with a verification code, please fill in the received code',
        info_authenticator: 'Enter a code from your authenticator app',
        incorrect: 'The code you provided was incorrect, please try again',
        error: 'An error occured, please try again',
      },
    },
    reset_password: {
      title: 'Reset password',
      success_button: 'Success',
      action_button: 'Change my password',
    },
    forgot_password: {
      title: 'Forgot your password?',
      success_button: 'Check your mail',
      action_button: 'Reset my password',
      errors: {
        invalid_email: 'Please provide a valid email',
        unknown_email: 'No user was found for the given email address',
        unknown: 'Something went wrong, please try again',
      },
    },
    invite: {
      title: 'Welcome to NightOwl',
      main: {
        intro: 'Dear {{firstname}} {{lastname}},',
        body: 'You have been invited to join the {{unit}} center with the following roles',
        button: 'Accept invitation',
      },
      user_logged_in: {
        body: 'Another user is already logged in.',
        button: 'Log out other user',
      },
      back_to_login_button: 'Back to login',
    },
    activate: {
      password_label: 'Choose a password',
      confirm_password_label: 'Confirm password',
      button: 'Activate my account',
      consent: {
        body: 'By activating my account, I accept the ',
        url: 'NightOwl Informed Consent',
      },
      password_notification_trigger: 'This will trigger an email notifying you that your password has changed.',
    },
    toasts: {
      reset_email: 'Password reset email sent',
      password_change_error: 'Error while trying to change password',
    },
    footer: {
      no_account_yet: 'Create your account',
      forgot_password: 'Forgot password?',
      back_to_login: 'Back to login',
    },
    errors: {
      invitation_not_found: "Sorry, we couldn't find your invitation. Maybe it has expired or it was already accepted.",
      unknown: 'Sorry, something went wrong. Please try again later.',
    },
  },
  dashboard: {
    title: 'Results',
    subtitle: 'All test results for your patients',
    no_tests: 'There are no tests to display',
    all_units: 'All Centers',
    header: {
      search_placeholder: 'Search...',
      new_test: 'New Test',
    },
    labels: {
      new: 'NEW',
      awaiting: 'AWAITING',
      error: 'ERROR',
      report: 'REPORT',
      summary: 'SUMMARY',
    },
    actions: {
      mail_report: 'Mail report to patient',
      delete: 'Delete test',
      request_feedback: 'Request Feedback',
      download_edf: 'Download EDF',
      toast_edf_null: 'No EDF found',
    },
    table_header: {
      refresh_popup: 'Last updated {{date}}',
    },
    columns: {
      patient: 'Patient',
      uploaded_nights: 'Nights Uploaded',
      recording_date: 'Recording Date',
      creation_date: 'Creation Date',
      status: 'Status',
      actions: 'Actions',
    },
    filter_states: {
      all: 'All',
      in_progress: 'In progress',
      completed: 'Completed',
      new: 'New',
      error: 'Error',
    },
    modals: {
      comment: {
        title: 'Comments',
        toast_error: 'Error while adding comment',
        toast_success: 'Comment added',
        toast_error_update: 'Error while updating comment',
        toast_success_update: 'Comment updated',
      },
      mail_report: {
        title: 'Mail report',
        body: 'The report will be send to the following email address. If needed, you can change this here.',
        toast_error: 'Error while sending report',
        toast_success: 'Report sent',
      },
      create: {
        patient_title: 'Patient information',
        test_title: 'About the test',
        simple_popup: 'Let your patient fill in the required information',
        fulfillment_popup: 'Let Ectosense handle the delivery of the NightOwl testkit',
        offline_popup: 'Offline tests are limited to 3 nights, the patient does not require a smartphone to complete the test',
        suspended_unit: "New tests can't be created for suspended centers, contact your admin",
        button_ack: 'Create Test',
        no_edit_confirm: {
          one: 'Are you sure you want to start a test for 1 night?',
          other: 'Are you sure you want to start a test for {{nights}} nights?',
        },
        toasts: {
          email_sent: 'Successfully sent email',
          email_sending: 'Sending email',
          success: 'Sucessfully created test',
          missing_info: 'Missing information',
          error: "Couldn't create test",
        },
      },
      delete: {
        title: 'Delete Test',
        body: 'Are you sure you want to delete this test from {{info}}?',
        toast_success: 'Test successfully removed',
        toast_error: 'Error while removing test',
      },
      feedback: {
        title: 'Request Feedback',
        toast_success: 'Feedback sent',
        toast_error: 'Error while sending feedback',
      },
      qr_modal: {
        header: 'Activation code: ',
        toast_success: 'Email sent',
        print_instructions_button: 'Print Instructions',
        print_qr_button: 'Print QR code',
        mail_button: {
          done: 'Email sent!',
          not_set: 'No email set',
          send: 'Send email',
        },
      },
      report_viewer: {
        title: 'Report viewer',
      },
      edit: {
        title: 'Patient information',
        toast: {
          error: "Couldn't update test",
          success: 'Test updated',
        },
      },
    },
    load_more: 'Load more',
  },
  unit: {
    physicians: 'Healthcare Professionals',
    admins: 'Administrators',
    statistics_undefined: 'There was an error retrieving the center statistics',
    buttons: {
      edit: 'Edit Center',
      invite: 'Invite Member',
      retry: 'Retry',
    },
    statistics: {
      tests: 'Tests',
      new_reports: 'New Reports',
      credits: 'Credits',
      physicians: 'Healthcare Professionals',
    },
    columns: {
      name: 'Name',
      joined: 'Joined',
      status: 'Status',
    },
    suspended: {
      body_wo_name: "This center is suspended. New tests can't be started until the center is re-activated.",
      body_with_name: "Center {{name}} is suspended. New tests can't be started until the center is re-activated.",
    },
    user: {
      unknown: 'Unknown User',
      actions: {
        disable: 'disable',
        reactivate: 'reactivate',
        resend: 'resend',
      },
      status: {
        invited: 'invited',
        active: 'active',
        disabled: 'disabled',
        undefined: 'unknown',
      },
      toasts: {
        change_error: 'Error while changing status',
        resend_error: 'Error while resending invite',
        resend_success: 'Successfully resend invite',
      },
    },
    modals: {
      invite: {
        title: 'Invite',
        button_invite: 'Invite',
      },
      stats: {
        title: 'Statistics',
        from_label: 'Tests created between',
        to_label: 'and (including)',
        error: 'Something went wrong downloading your statistics',
      },
    },
  },
  unit_edit: {
    details: {
      title: 'Center Details',
      name: 'Center Name',
      btn_edit: 'Edit Details',
    },
    settings: {
      title: 'Center Settings',
      simple: 'New tests default to simple configuration',
      nights: 'Default number of nights for new tests',
      two_fa_enforced: 'Enforce Two Factor authentication for enrolled users',
      btn_edit: 'Edit Settings',
    },
    modals: {
      details: {
        title: 'Edit Center Details',
        name_label: 'Center name',
        toast_error: 'Error while updating the center details',
        toast_success: 'Succesfully updated the center details',
      },
      settings: {
        title: 'Edit Center Settings',
        simple: 'New tests default to simple configuration',
        nights: 'Default number of nights for new tests',
        two_fa_enforced: 'Enforce Two Factor authentication for enrolled users',
        toast_error: 'Error while updating the center settings',
        toast_success: 'Succesfully updated the center settings',
      },
    },
  },
  units: {
    title: 'Centers',
    subtitle: 'You are administrating following centers',
    button_new: 'New Center',
    unit_card: {
      suspended: 'suspended',
      new_reports: 'New Reports',
      credits: 'Credits',
      physician: {
        one: '{{count}} Healthcare Professional',
        other: '{{count}} Healthcare Professionals',
      },
    },
    create_modal: {
      s1: {
        title: 'New center details',
        labels: {
          name: 'Center name',
          balance: 'Credits',
          verified: 'Verified',
        },
      },
      s2: {
        title: 'First user for {{name}}',
        button: 'Create Center',
      },
    },
  },
  account: {
    title: 'Account',
    subtitle: '',
    log_out: 'Log out',
    modals: {
      edit_password: {
        toast_error: "Couldn't update password",
        toast_success: 'Password updated',
        unauth_header: 'Unauthorized',
        unauth_content: 'The given old password does not match the current password',
        reasons: {
          outdated: {
            header: 'Password rotation policy',
            content: 'Our password rotation policy requires you to update your password every 60 days',
          },
        },
      },
      edit_user: {
        warn_firstname: 'First name cannot be empty',
        warn_lastname: 'Last name cannot be empty',
        warn_email: 'Email is not valid',
        toast_error: "Couln't update profile",
        toast_success: 'Profile updated',
      },
      enable_two_fa: {
        title: 'Two Factor Authentication',
        subtitle: 'Choose your desired method',
        alert: {
          Enforced2FA: 'Two Factor authentication is required for one of the centers you are enrolled in',
        },
        authenticator: {
          h1: 'Scan this QR code with your authenticator app',
          p1:
            "Scan the image below with the two-factor authentication app on your phone. If you can't scan a QR code, please use a different method.",
          h2: 'Enter the 6-digit code from the application',
          p2: 'After scanning the barcode image, the app will display a 6-digit code that you must enter here:',
        },
        email: {
          p_email: 'Provide the email address on which you wish to receive two factor verification codes',
          p_verification: 'Please enter the verification code that has been sent to the provided email address',
        },
        sms: {
          p_sms: 'Provide the phone number (international format) on which you wish to receive two factor verification codes.',
          p_verification: 'Please enter the verification code that has been sent to the provided phone number',
        },
      },
      disable_two_fa: {
        title: 'Two Factor Authentication',
        subtitle: 'Please confirm that you want to disable the following two factor authentication method',
        verify: {
          instruction_sms: 'Please enter the verification code that has been sent to the registered phone number',
          instruction_email: 'Please enter the verification code that has been sent to the registered email address',
          instruction_authenticator: 'Enter a code from your authenticator app',
        },
      },
    },
    enrollments: {
      title: 'My enrollments',
      unit_col: 'Center',
      roles_col: 'Roles',
    },
    profile: {
      title: 'My profile',
      name: 'Name',
      edit_profile: 'Edit profile',
    },
    security: {
      title: 'Security',
      password: {
        title: 'Password',
        change_btn: 'Change password',
      },
      two_fa: {
        title: 'Two Factor Authentication',
        subtitle:
          'Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.',
        methods: {
          authenticator: 'Authenticator app',
          sms: 'SMS',
          email: 'Email',
        },
        verify_pw: 'Please verify your password before proceeding',
      },
    },
  },
  surveys: {
    title: 'Surveys',
    all_filter_option: 'All surveys',
    responses: {
      title: 'Surveys',
      subtitle: 'Responses',
    },
    columns: {
      patient: 'Patient',
      survey: 'Survey',
      response_date: 'Response Date',
      status: 'Status',
      actions: 'Actions',
    },
  },
  survey_assessment: {
    finish: {
      title: 'Thank you',
      subtitle: 'The survey was completed successfully. Your physician will evaluate the answers and undertake the appropriate actions.',
      button: 'Continue',
    },
    questions: {
      satisfaction: 'Hi {{name}}, how satisfied do you feel about your current sleep?',
      sleep_description: 'How would you describe your sleep (pick most applicable)?',
      sleep_hours: 'On average, how many hours of sleep do you get each night?',
      sleep_related_pain: 'How often have you had trouble sleeping because of pain?',
      feel_sleepy: 'Even after sleeping through the night, do you feel sleepy during the day?',
      teeth_grinding: 'Do you wake up with an aching jaw, or ever been told that you grind your teeth during sleep?',
      difficult_falling_asleep: 'Do you find it difficult to fall asleep?  How severe is this problem for you?',
      difficult_staying_asleep: 'Do you find it difficult to stay asleep throughout the night? How severe is this problem for you?',
      waking_early: 'Do you have issues waking up too early and struggle to fall back asleep? How severe is this problem for you?',
      negative_impact:
        'To what extent does sleep negatively impact your quality of life? (e.g. daytime fatigue, mood, driving performance, work performance,  concentration or memory).',
      sleep_worried: 'How worried are you about your current sleep?',
      snore_self: 'Now let’s look at your night time breathing. Have you been told you snore?',
      snore_position: 'If you can recall, which position do you usually snore in while sleeping?',
      dry_mouth: 'Do you wake up with a dry mouth?',
      snore_partner: 'Do you sleep next to someone who snores?',
      gasping_breath: 'Do you ever wake gasping for breath?',
      hold_breath: 'Have you ever been told you hold your breath while sleeping?',
      headaches: 'Do you notice that you wake up with headaches in the morning?',
      heartburn: 'Do you experience heartburn or acid reflux, or take medication to treat it?',
      blood_pressure: 'Do you have high blood pressure or are taking medicine to treat it?',
      restless_legs: 'Do you sometimes feel that you have to move your legs to make them feel comfortable?',
      disorder_medication: 'Do you suffer from depression or a mood disorder and are currently taking medication for it?',
      copd: 'Have you ever been diagnosed with COPD, Heart Failure and or use Opioids to treat chronic pain?',
      bmi:
        'Your BMI and age can help us understand your risk factor for certain sleep disorders. Let’s calculate your BMI. Please enter your height and weight?',
      // eureka: 'Was there a "Eureka/aha!" moment or incident that motivated you into improving your sleep health?',
      motivation: 'What has been your motivation for wanting to improve your sleep?',
      gender: 'Lastly, what best describes your gender?',
    },
    answers: {
      yes: 'Yes',
      no: 'No',
      not_sure: 'Not sure',
      very_satisfied: 'Very satisfied',
      satisfied: 'Satisfied',
      moderately_satisfied: 'Moderately satisfied',
      dissatisfied: 'Dissatisfied',
      very_dissatisfied: 'Very dissatisfied',
      deep: 'Deep',
      great: 'Great',
      terrible: 'Terrible',
      less_5_hours: 'Less than 5 hours',
      '5_to_7_hours': '5 to 7 hours',
      '7_to_9_hours': '7 to 9 hours',
      more_9_hours: 'More than 9 hours',
      never: 'Never',
      less_once_week: 'Less than once a week',
      once_twice_week: 'Once or twice a week',
      three_more_week: 'Three or more times a week',
      no_problem: 'No problem',
      few_issues: 'Few issues',
      moderately: 'Moderately',
      severe: 'Severe',
      very_severe: 'Very severe',
      none: 'None',
      little: 'A little',
      somewhat: 'Somewhat',
      alot: 'A lot',
      not_at_all: 'Not at all',
      on_back: 'On my back',
      on_side: 'On my side',
      any_position: 'In any position',
      no_recall: "Can't recall",
      loved_one: "A loved one's suggestion",
      doctor: "Doctor's recommendation",
      energy: 'Low energy and fatigue',
      memory: 'Memory and performance issues',
      general: 'General health concerns',
      other: 'Other',
      male: 'Male',
      female: 'Female',
      light: 'Light',
      fragmented: 'Fragmented',
      interrupted: 'Could be better',
    },
    sleep_health: {
      sleep_health1: {
        light: 'Light sleeper',
        interrupted: 'Interrupted sleeper',
        fragmented: 'Fragmented sleeper',
      },
      sleep_health2: {
        yes: 'Heartburn/Acid reflux',
      },
      sleep_health3: {
        yes: 'Restless legs',
      },
    },
    physio_signs: {
      snoring: 'Snoring',
      hold_breath: 'Holds breath',
      gasping: 'Gasping for breath',
      sleepy: 'Daytime sleepiness',
      dry_mouth: 'Dry mouth/Sore throat',
      heartburn: 'Heartburn/reflux',
      blood_pressure: 'High blood pressure',
      headaches: 'Headaches',
    },
    snoring_type: {
      positional: 'Positional snoring',
      mouth: 'Mouth snoring',
      nose: 'Nose snoring',
      partner: 'Partner snoring',
    },
    result: {
      patient: {
        title: 'Patient',
        name: 'Name',
        birthday: 'Birthday',
      },
      evaluation: {
        title: 'Evaluation',
      },
      indicator: 'Risk indicator:',
      your_indicators: 'Your indicators:',
      your_physio_indicators: 'Your physiological indicators:',
      recommendations: 'Care Recommendations',
      high_risk: 'high risk',
      moderate_risk: 'moderate risk',
      low_risk: 'low risk',
      at_risk: 'at risk',
      at_risk_with_pain: 'at risk of insomnia with pain',
      apnea: {
        title: 'Sleep Apnea',
        subtitle: 'Your symptoms and BMI suggests that you may be at {{severity}} of Sleep apnea.',
        question: 'What is Sleep apnea?',
        recommendations: {
          risk:
            'Your sleep assessment results indicate you may be at risk of Sleep Apnea. Consider taking a home sleep test to determine if you require treatment or talk to your doctor. Waking up feeling refreshed and energised will help you enjoy the things you love.',
          low_risk:
            "Your sleep assessment results indicate you may be at low risk of Sleep Apnea. If you're unsure about your symptoms you should consider taking a home sleep test to determine if you require treatment. Alternatively, you may want to talk to your doctor.",
          copd:
            'Your sleep assessment results indicate that due to existing medical conditions you will need to speak to your doctor about your symptoms and possible treatments options to improve your sleep.',
        },
        definition:
          'Sleep apnea is a sleep disorder that causes your body to stop breathing while you sleep. Air stops flowing to your lungs for 10 seconds or longer which can happen repeatedly during the night. Left undiagnosed and untreated, it can have long-term effects on your health. A home sleep study will help determine if you have a sleep disorder.',
        physio_signs: {
          snoring: 'Snoring',
          hold_breath: 'Holds breath',
          gasping: 'Gasping for breath',
          sleepy: 'Daytime sleepiness',
          dry_mouth: 'Dry mouth/Sore throat',
          heartburn: 'Heartburn/reflux',
          blood_pressure: 'High blood pressure',
          headaches: 'Headaches',
        },
      },
      insomnia: {
        title: 'Insomnia',
        risk_title: '{{risk}} of insomnia',
        risk_pain_title: '{{risk}} of insomnia with pain',
        subtitle: 'Your assessment results suggest you may be at {{severity}}.',
        question: 'What is insomnia?',
        recommendations: {
          none: 'Looks like insomnia may not be a problem for you.',
          normal: 'Your sleep assessment results indicate you may be at risk of Insomnia. You may want to talk to your doctor.',
          pain:
            'Your sleep assessment results indicate you may be at risk of insomnia due to pain. You should speak to your doctor who may refer you to a sleep psychologist. You deserve to feel your best with the energy to live each day to its full potential.',
        },
        definition:
          'Insomnia is the name given to regularly having a hard time falling asleep, staying asleep or getting back to sleep if you wake in the night. Insomnia can make you feel drowsy during the day, and effect memory, concentration and learning.',
      },
      sleep_health: {
        subtitle: 'Your assessment results suggest you may benefit from an active approach to improve your sleep health',
        question: 'What is Sleep Health',
        definition:
          'Your body and mind depend on a good night’s sleep for your physical and mental health as well as your quality of life. While we sleep many important functions take place that helps the body to repair itself. Sleep also supports brain function and improves memory and mood',
        recommendation:
          'A good night’s sleep helps you feel good and be more alert, you feel more energetic and can concentrate better. Getting enough sleep is one of the most important things you can do for your health and well-being.',
      },
      snoring: {
        subtitle: 'Your assessment results suggest you may be a {{severity}}.',
        positional: {
          title: 'Positional snoring',
          severity: 'Positional snorer',
          recommendation: 'Changing your sleeping position may be key to easing your snoring. You may want to talk to your doctor.',
          question: 'What is positional snoring?',
          definition:
            'Positional snoring is when you only snore in a certain position. Most people find they snore more when sleeping on their back. When you sleep on your back, gravity helps the relaxed muscles in your mouth and tongue to fall back and restrict your airway. That’s why some people snore less when they sleep on their side.',
        },
        mouth: {
          title: 'Mouth snoring',
          severity: 'Mouth snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is mouth snoring?',
          definition:
            'Mouth snorers also suffer from blocked nasal passages and therefore breathe through their mouth when they sleep. This causes the soft tissues inside their mouth to vibrate and produce a low rumbling sound.',
        },
        nose: {
          title: 'Nose snoring',
          severity: 'Nose snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is nose snoring?',
          defintion:
            'Nose snoring occurs when something is blocking the nasal passages. It can be caused by things like a deviated septum, allergies, colds and flu.',
        },
        partner: {
          title: 'Partner snoring',
          subtitle: 'Your assessment results suggest that you may have a problem with a Partner Snoring',
          severity: 'Partner snores',
          recommendation:
            'If you’re worried about the effect that your partner’s snoring is having on your sleep there are a few things you can try. For example, you can download a snoring app to help your partner understand the severity of his or her snoring',
          question: 'What is snoring?',
          definition:
            'Snoring can be a sign that you’re not breathing properly when you sleep. As you fall asleep the muscles in your mouth, tongue and throat relax. Snoring occurs when these muscles relax too much and they partially block your airway and vibrate.',
        },
      },
    },
  },
  qrPrint: {
    step: 'Step',
    patientGeneric: 'Dear Patient,',
    patientName: 'Dear {{name}},',
    titles: {
      install: 'INSTALL THE SMARTPHONE APP',
      perform: 'PERFORM THE TEST',
      return: 'RETURN THE TEST',
    },
    intro:
      'You have been scheduled to perform a NightOwl® sleep test. This test will check whether you suffer from sleep apnea by measuring signals during your sleep. To do so, you will need to attach a small sensor to the tip of your index finger. This sensor shines light onto your finger and records the reflected light from your skin. It connects wirelessly to your smartphone via Bluetooth. You may be asked to sleep with this sensor for more than one night.',
    introSteps:
      'Preparing for your test is straightforward and will only take a few minutes of your time as we walk you through the steps below.',
    step1: 'On your smartphone, open the App Store app if you have an iPhone or open the Play Store app if you have an Android phone.',
    step2: 'In the app you just opened, search for and download the NightOwl Companion app, which looks like this:',
    step3: "Open the NightOwl Companion app and press the button displaying 'I have an activation code'.",
    step4: 'Either scan the QR code with the NightOwl Companion app or copy the letter code below.',
    step5:
      'When you are ready to go to bed, open the NightOwl® app on your smartphone. Click the ‘Continue’ button and follow the instructions in the app. Sweet dreams!',
    step6:
      'When you wake up, finish the recording by pressing the ‘wake up’ button in the app. Your recorded data will now be uploaded over the internet.',
    step7: 'When the upload process is completed, proceed to charge the sensor according to the instruction video shown in the app.',
    step_return:
      'When you have completed all test nights, return the device to your healthcare professional according the instructions he or she provided you.',
    footer: '© COPYRIGHT 2020 | ECTOSENSE • ALL RIGHTS RESERVED',
  },
};
