import { flow, getEnv, getRoot, Instance, types } from 'mobx-state-tree';

import { convertAuthUser } from 'api/transformers/userTransformer';
import { setUser } from 'core/analytics';
import {
  ACCOUNT_MODALS,
  PASSWORD_CHANGE_MODAL_REASONS,
  SHOW_STATS_DOWNLOAD,
  SHOW_SURVEY_UNITS,
  TWO_FACTOR_MODAL_REASONS,
} from 'core/constants';
import { setLanguage } from 'core/i18n';
import { validateSession } from 'core/util/session';

import { IStore } from '../index';

import { AuthUser } from './authUser';

export const Auth = types
  .model('Auth', {
    user: types.maybeNull(AuthUser),
    initialized: false,
    busy: false,
    error: false,
    sessionExpired: false,
    redirectUrl: '',
    twoFactorEnforced: false,
  })
  .views(self => ({
    get authenticated(): boolean {
      return self.user !== null;
    },
  }))
  .views(self => ({
    get isAdmin(): boolean {
      return self.authenticated && self.user?.isAdmin;
    },
    get isPhysician(): boolean {
      return self.authenticated && self.user?.isPhysician;
    },
    get showSurveys(): boolean {
      return self.authenticated && Boolean(self.user?.enrollments.find(e => SHOW_SURVEY_UNITS.includes(e.unit.id)));
    },
    get showStatsDownload(): boolean {
      return Boolean(self.user?.enrollments.find(e => SHOW_STATS_DOWNLOAD.includes(e.unit.id)));
    },
    get enforceTwoFactor(): boolean {
      return self.twoFactorEnforced && self.user && self.user.twoFa == null;
    },
  }))
  .actions(self => {
    const logout = flow(function* logout() {
      const appstore = getRoot<IStore>(self);
      appstore.clearData();
      const { api } = getEnv(self);
      api.users.logout();
      self.user = null;
      yield Promise.resolve();
    });

    const initialize = flow(function* initialize() {
      const { api } = getEnv(self);
      const user = yield api.users.me();
      try {
        if (user) {
          validateSession(user);
          self.user = AuthUser.create(convertAuthUser(user));
          setUser(self.user.id, {
            firstname: user.firstname,
            lastname: user.lastname,
            created_at: user.createdAt,
          });
          setLanguage(self.user.language);
        }
      } catch (e) {
        if (e.name === 'SessionExpiredError') {
          yield logout();
          self.sessionExpired = true;
        }
      } finally {
        self.initialized = true;
      }
    });

    const forgotPassword = flow(function* forgotPassword(email: string) {
      const { api } = getEnv(self);
      self.busy = true;
      try {
        yield api.users.forgotPassword(email);
      } finally {
        self.busy = false;
      }
    });

    const resetPassword = flow(function* resetPassword(token: string, password: string, passwordConfirmation: string) {
      const { api } = getEnv(self);
      self.busy = true;
      try {
        yield api.users.resetPassword(token, password, passwordConfirmation);
      } finally {
        self.busy = false;
      }
    });

    const login = flow(function* login(username: string, password: string, service: string) {
      const { api } = getEnv(self);
      self.busy = true;
      try {
        const loginData = yield api.users.login(username, password, service);
        if (loginData.type === 'LOGIN') {
          self.sessionExpired = false;
          self.user = AuthUser.create(convertAuthUser(loginData.user));
          setLanguage(self.user.language);
          setUser(self.user.id);
          if (loginData.needs_reset) {
            self.redirectUrl = `/profile?modal=${ACCOUNT_MODALS.CHANGE_PASSWORD}&reason=${PASSWORD_CHANGE_MODAL_REASONS.OUTDATED}`;
          } else if (loginData.enforced2FA) {
            self.twoFactorEnforced = true;
            self.redirectUrl = `/profile?modal=${ACCOUNT_MODALS.ENABLE_2FA}&reason=${TWO_FACTOR_MODAL_REASONS.ENFORCED}`;
          }
        }

        self.error = false;
        return loginData;
      } catch (error) {
        self.error = true;
        throw error;
      } finally {
        self.busy = false;
      }
    });

    const submit2fa = flow(function* submit2fa(secret: string, code: string, type: string) {
      const { api } = getEnv(self);
      self.busy = true;
      try {
        const loginData = yield api.users.submit2fa(secret, code, type);
        self.sessionExpired = false;
        self.user = AuthUser.create(convertAuthUser(loginData.user));
        setLanguage(self.user.language);
        setUser(self.user.id);
        if (loginData.needs_reset) {
          self.redirectUrl = `/profile?modal=${ACCOUNT_MODALS.CHANGE_PASSWORD}&reason=${PASSWORD_CHANGE_MODAL_REASONS.OUTDATED}`;
        }
        self.error = false;
      } catch (error) {
        self.error = true;
        throw error;
      } finally {
        self.busy = false;
      }
    });

    const redirectUrlHandled = flow(function* redirectUrlHandled() {
      self.redirectUrl = '';
      yield Promise.resolve();
    });

    return {
      initialize,
      login,
      submit2fa,
      logout,
      forgotPassword,
      resetPassword,
      redirectUrlHandled,
      afterCreate() {
        return initialize();
      },
    };
  });

export interface IAuth extends Instance<typeof Auth> {}
