import React from 'react';

import LogoMarkColored from 'svgs/N_logoMark_gekleurd.svg';
import './styles.scss';

export const Spinner: React.FC = () => {
  return (
    <div className="spinner">
      <img src={LogoMarkColored} className="spinner-icon" alt="logo" />
    </div>
  );
};
