import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IAuth } from 'core/stores/auth';

import './styles.scss';

interface InjectedProps {
  auth: IAuth;
}

type Props = InjectedProps & RouteComponentProps;

class Logout extends Component<Props, {}> {
  componentDidMount(): void {
    this.logout();
  }

  logout = async () => {
    const {
      auth: { logout },
    } = this.props;
    try {
      await logout();
      // window.location.replace('/login');
    } catch (err) {
      throw err;
    }
  };

  render(): JSX.Element {
    return <div>logging out...</div>;
  }
}

export default inject<InjectedProps, Props>(({ root }) => ({
  auth: root.auth,
}))(observer(Logout));
