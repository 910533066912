import { flow, getEnv, Instance, types } from 'mobx-state-tree';

import { UnitStatistic } from './unitStatistics';

export const Statistics = types
  .model('statistics', {
    unitStatistics: types.map(UnitStatistic),
    initialized: false,
    initializing: false,
    fetches: types.map(types.model({
      id: types.identifier,
      fetching: types.boolean,
    })),
    errors: types.map(types.model({
      id: types.identifier,
      error: types.string,
    })),
  })
  .actions(self => ({
    fetchStatistics: flow(function* fetchStatistics(unit: id) {
      try {
        const { api } = getEnv(self);
        self.errors.put({ id: unit, error: '' });
        self.fetches.put({ id: unit, fetching: true });
        const statistics = yield api.units.getUnitStatistics(unit);
        self.unitStatistics.put(statistics);
        self.fetches.put({ id: unit, fetching: false });
      } catch (err) {
        self.fetches.put({ id: unit, fetching: false });
        self.errors.put({ id: unit, error: "Couldn't fetch unit statistics" });
      }
    }),
  }));

export interface IStatisticsStore extends Instance<typeof Statistics> {}
